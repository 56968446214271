import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Stack, Skeleton, Typography } from '@mui/material';
import { getBlogList } from 'api/blogs';
import { useAuth } from 'hooks/useAuthContext';

const likeIcon = `${process.env.REACT_APP_HOMEPAGE}assets/like-icon.png`;

const SuperchargeCareer = () => {
    const { user, updateContext } = useAuth();

    const [courseBlogs, setCourseBlogs] = useState([]);
    const [courseBlogCount, setCourseBlogCount] = useState(0);

    const [eventBlogs, setEventBlogs] = useState([]);
    const [eventBlogCount, setEventBlogCount] = useState(0);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        try {
            const response = await getBlogList({ userType: user?.userType || 'user' });
            if (response?.success && response?.data) {
                setCourseBlogs(response?.data?.CourseBlogs || []);
                setCourseBlogCount(response?.data?.CourseBlogsCount || 0);
                setEventBlogs(response?.data?.EventBlogs || []);
                setEventBlogCount(response?.data?.EventBlogsCount || 0);
            }
        } catch (error) {
            console.error('Error fetching jobs:', error);
        } finally {
            setLoading(false);
        }
    }

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box className="supercharge-your-career">
            <Box className="supercharge-inner-container">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Box className="career-card">
                            <Box className="card-head">
                                <Typography className="title">Interesting finance articles, blogs, videos from all around</Typography>
                                <Typography className="info-text">Keep yourself abreast of the latest thoughts in the world of finance</Typography>
                            </Box>

                            <Box className="card-body">
                                <Stack spacing={3}>
                                    {courseBlogs?.map((blog, index) => (
                                        <Box key={index} onClick={() => window.open(blog?.url, '_blank')}>
                                            <Stack direction="row" spacing={2}>
                                                <Box>
                                                    <Box
                                                        sx={{
                                                            width: '72px',
                                                            height: '72px',
                                                            borderRadius: '12px',
                                                            backgroundColor: '#D9D9D9',
                                                        }}
                                                    >
                                                        <img alt="name" src={blog?.image_url} />
                                                    </Box>
                                                </Box>

                                                <Box>
                                                    <Typography className="category-title">
                                                        {blog?.title}
                                                    </Typography>
                                                    <Typography className="category-info">
                                                        {blog?.short_description}
                                                    </Typography>

                                                    <Box mt={1} className="category-type">
                                                        <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                                            <Typography variant="p">{blog?.content_type}</Typography>
                                                            <Typography variant="body1">•</Typography>
                                                            <Typography variant="p">{blog?.duration}</Typography>
                                                            <Typography variant="body1">•</Typography>
                                                            <Typography variant="p" className="likes">
                                                                <img alt="name" width="12px" height="12px" style={{ marginTop: '-2px' }} src={likeIcon} />
                                                            </Typography>
                                                            <Typography variant="p">{blog?.impressions}</Typography>
                                                        </Stack>
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    ))}
                                </Stack>

                                {/* <Box sx={{ textAlign: 'center', width: '100%' }}>
                                    <Button
                                        className="view-all-btn"
                                        endIcon={
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M5.96667 2.7193L10.3133 7.06596C10.8267 7.5793 10.8267 8.4193 10.3133 8.93263L5.96667 13.2793" stroke="#1575C6" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        }>
                                        View all (43)
                                    </Button>
                                </Box> */}
                            </Box>
                        </Box>
                    </Grid>

                    {/* <Grid item xs={12}>
                        <Box className="career-card">
                            <Box className="card-head">
                                <Typography className="title">Events Around You</Typography>
                                <Typography className="info-text">Keep yourself abreast of the latest thoughts in the world of finance</Typography>
                            </Box>

                            <Box className="card-body">
                                <Stack spacing={3}>
                                    {eventBlogs?.map((event) => (
                                        <Box onClick={() => window.open(event?.url, '_blank')}>
                                            <Stack direction="row" spacing={2}>
                                                <Box>
                                                    <Box
                                                        sx={{
                                                            width: '72px',
                                                            height: '72px',
                                                            // borderRadius: '12px',
                                                            backgroundColor: '#D9D9D9',
                                                        }}
                                                    >
                                                        <img alt="name" src={event?.image_url} />
                                                    </Box>
                                                </Box>

                                                <Box>
                                                    <Typography className="category-title">{event?.title}</Typography>
                                                    <Typography className="category-info">{event?.short_description}</Typography>

                                                    <Box mt={1} className="category-type">
                                                        <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                                            <Typography variant="p">{event?.content_type}</Typography>
                                                            <Typography variant="body1">•</Typography>
                                                            <Typography variant="p">{event?.duration}</Typography>
                                                            <Typography variant="body1">•</Typography>
                                                            <Typography variant="p" className="likes">
                                                                <img alt="name" width="12px" height="12px" style={{ marginTop: '-2px' }} src={likeIcon} />
                                                            </Typography>
                                                            <Typography variant="p">{event?.impressions}</Typography>
                                                        </Stack>
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    ))}
                                </Stack>

                                <Box sx={{ textAlign: 'center', width: '100%' }}>
                                    <Button
                                        className="view-all-btn"
                                        endIcon={
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M5.96667 2.7193L10.3133 7.06596C10.8267 7.5793 10.8267 8.4193 10.3133 8.93263L5.96667 13.2793" stroke="#1575C6" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        }>
                                        View all (12)
                                    </Button>
                                </Box> 
                            </Box>
                        </Box>
                    </Grid> */}
                </Grid>
            </Box>
        </Box>
    )
}

export default SuperchargeCareer
