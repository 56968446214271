import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginlink } from 'api/login'
import { SplashScreen } from 'components/loading-screen';

const RedirectPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = window.location.pathname.split('/').pop();

                if (token) {
                    const response = await loginlink({ token });
                    if (response?.success && response.data) {
                        navigate('/reset-password', { state: { user: response.data } });
                    } else {
                        navigate('/');
                    }
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error processing login link:', error);
                navigate('/');
            }
        };

        fetchData();
    }, []);


    return (<SplashScreen />);
};

export default RedirectPage;
