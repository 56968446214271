import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
// @mui
import { Container } from '@mui/system';
import { Button, Chip, InputLabel, Stack, IconButton, InputAdornment, Box, TextField, Grid, Typography, Select, MenuItem, FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import AddIcon from '@mui/icons-material/Add';
// components
import FormProvider from 'hooks/form/form-provider';
import { useSnackbar } from 'components/snackbar';
import { useAuth } from 'hooks/useAuthContext';
// Validation
import { yupResolver } from '@hookform/resolvers/yup';
import { NewJobSchema, NewCompanyJobSchema } from 'models/NewJobSchema';
// APIs
import { createJob, jobUpdated, getCompanyDetails, jobDetails } from 'api/job';
// ----------------------------------------------------------------------
const dayjs = require('dayjs');
import CircularProgress from '@mui/material/CircularProgress';
//skills
import TypeAhead from 'components/TypeAhead';
import DynamicTextField from 'components/DynamicTextField';
import { endpoints } from 'utils/axios';
import QualificationOptions from 'components/QulificationOptions';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
export default function NewJob() {
  /*----------------SnackBar Defination--------------------------------*/
  const { enqueueSnackbar } = useSnackbar();
  /*----------------SnackBar Defination--------------------------------*/

  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  // const { id } = useParams();
  const id = location.state;
  const isEditMode = !!id;
  const [jobUniqueId, setJobUniqueId] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [createdBy, setCreatedBy] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedQualifications, setSelectedQualifications] = useState([]);
  const [allCertifications, setAllCertifications] = useState([]);
  const [keyQuestions, setKeyQuestions] = useState(['']);

  const defaultValues = {
    title: '',
    company: '',
    function: '',
    role: '',
    description: '',
    job_overview: '',
    qualifications: [],
    certifications: [],
    key_skills: [],
    experience: '',
    work_mode: '',
    job_type: '',
    company_overview: '',
    location: '',
    selectCompany: null,
    compensation: '',
    industry_sector: '',
    start_date: null,
    reporting_to: '',
    direct_reports: null,
    question: [],
    salary: '',
    status: 'active',
    notice_period: '',
    company_id: '',
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver((user?.user_type === 'company' || user?.user_type === 'companyadmin' || user?.user_type === 'recruiter') ? NewCompanyJobSchema : NewJobSchema)
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    getValues,
    reset,
    formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
  } = methods;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isEditMode) {
          await fetchJobDetails(id);
        } else {
          await createNewJob();
        }
      } catch (error) {
        enqueueSnackbar('Failed to fetch data', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchData();

  }, []);

  const createNewJob = async () => {
    try {
      const response = await createJob({ created_by: 'Admin Name', status: 'New' });
      if (response?.success && response?.data) {
        setJobUniqueId(response.data?._id);
        setJobId(response.data?.jobid)
        setCreatedBy(user?.name);
        setUpdatedAt(response.data?.updatedAt);
        if (user?.user_type === 'company' || user?.user_type === 'companyadmin' || user?.user_type === 'recruiter') {
          const companyId = user?.id;
          // setValue('selectCompany', { name: user?.name, id: companyId });
          const companyResponse = await getCompanyDetails({ id: companyId });
          if (companyResponse?.success && companyResponse?.data) {
            const companyData = companyResponse.data;
            setValue('company', companyData.full_name);
            setValue('company_id', companyData._id);
            setValue('industry_sector', companyData.industry_sector);
            setValue('company_overview', companyData.description || '');
          } else {
            enqueueSnackbar(companyResponse.message, { variant: 'error' });
          }
        }
      } else {
        enqueueSnackbar('Failed to create job', { variant: 'error' });
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchJobDetails = async (id) => {
    try {
      const response = await jobDetails({ id });
      if (response?.success && response?.data) {
        const { _id, jobid, created_by, updatedAt, company_id, company, question, certifications } = response.data;
        setJobUniqueId(_id);
        setJobId(jobid);
        setCreatedBy(created_by);
        setUpdatedAt(updatedAt);
        reset(response.data);
        setValue('certifications', '');
        // if (company_id) setValue('selectCompany', { name: company, id: company_id });
        if (question && Array.isArray(question)) {
          setKeyQuestions([...question, '']);
          setAllCertifications(certifications || []);
          setValue('question', question);
        }
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    } catch (error) {
      throw error;
    }
  };

  const onSubmit = handleSubmit(async (formData) => {
    formData.qualifications = selectedQualifications;
    formData.certifications = allCertifications;
    const response = await jobUpdated({ formData, job_id: jobUniqueId });
    if (response?.success === true && response.data) {
      enqueueSnackbar(response.message || "created job details successfully.", { variant: 'success' });
      navigate('/home');
    } else if (response?.success === false) {
      enqueueSnackbar('Failed to create job', { variant: 'error' });
      console.error('Failed to create job:', response.message);
    }
  });

  const removeQualification = (index) => {
    const updatedQualifications = [...selectedQualifications];
    updatedQualifications.splice(index, 1);
    setSelectedQualifications(updatedQualifications);
  };
  const removeCertification = (index) => {
    const updatedCertification = [...allCertifications];
    updatedCertification.splice(index, 1);
    setAllCertifications(updatedCertification);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...keyQuestions];
    newQuestions[index] = value;

    if (value.trim() === '') {
      newQuestions.splice(index, 1);
    } else if (index === newQuestions.length - 1 && value.trim() !== '') {
      newQuestions.push('');
    }

    setKeyQuestions(newQuestions);

    const filteredQuestions = newQuestions.filter(question => question.trim() !== '');
    setValue("question", filteredQuestions);
    trigger('question');
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Container style={{ marginTop: '80px' }}>
      <FormProvider methods={methods} onSubmit={onSubmit} sx={{ padding: '20px' }}>
        <Stack spacing={2} sx={{ my: 2, mb: 2 }}>
          <Typography variant="normal" gutterBottom style={{ textAlign: 'right' }}>
            {' '}
            Job ID: {jobId}{' '}
          </Typography>
          <Typography variant="normal" gutterBottom style={{ textAlign: 'right' }}>
            {' '}
            Created by: {createdBy}
          </Typography>
          <Typography variant="normal" gutterBottom style={{ textAlign: 'right' }}>
            {' '}
            Posting Date: {dayjs(updatedAt).format('DD/MM/YYYY')}
          </Typography>
        </Stack>
        {/* create new job form */}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            {/* job title */}
            <FormControl fullWidth>
              <TextField
                size='small'
                {...register('title')}
                error={!!errors.title}
                helperText={errors.title?.message}
                label="Title"
                variant="outlined"
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={7}>
            {/* company */}
            <FormControl fullWidth >
              {user?.user_type == 'admin' || user?.user_type == 'superadmin' ? (
                <Controller
                  control={control}
                  name="company"
                  render={({ field }) => (
                    isEditMode ? (
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        label="Company Name"
                        InputProps={{ readOnly: true }}
                        value={field?.value || ''}
                      />
                    ) : (
                      <TypeAhead
                        {...field}
                        value={field.value}
                        api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.registeredCompany}`}
                        multiple={false}
                        fullWidth
                        label="Select Company"
                        placeholder="Select Company"
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            variant="outlined" />}
                        onChange={(e, value) => {
                          setValue('company_id', value?.id || '');
                          setValue('industry_sector', value?.industry_sector || '');
                          setValue('company_overview', value?.description || '');
                          field.onChange(value.name)
                        }}
                      />
                    )
                  )}
                />
              ) : (
                ''
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormControl fullWidth >
              <Controller
                name="industry_sector"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size='small'
                    error={!!errors.industry_sector}
                    helperText={errors.industry_sector?.message}
                    label="Industry Sector"
                    variant="outlined"
                    InputProps={{ readOnly: true }}
                    fullWidth
                  />
                )} />
            </FormControl>
          </Grid>
          {/* job_overview */}
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth >
              <TextField
                size='small'
                {...register('job_overview')}
                error={!!errors.job_overview}
                helperText={errors.job_overview?.message}
                label="Job Overview"
                variant="outlined"
                fullWidth
                multiline
              />
            </FormControl>
          </Grid>
          {/* description */}
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth >
              <TextField
                size='small'
                {...register('description')}
                error={!!errors.description}
                helperText={errors.description?.message}
                label="Job Description"
                variant="outlined"
                fullWidth
                multiline
              />
            </FormControl>
          </Grid>
          {/* function */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth >
              <Controller
                control={control}
                name="function"
                render={({ field }) => (
                  <TypeAhead
                    {...field}
                    api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.function}`}
                    multiple={false}
                    fullWidth
                    label="Select function"
                    displayTextKey="function"
                    placeholder="Select function"
                    onChange={(e, value) => field.onChange(value)}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth >
              <Controller
                control={control}
                name="role"
                render={({ field }) => (
                  <TypeAhead
                    {...field}
                    api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.role}`}
                    multiple={false}
                    fullWidth
                    label="Select role"
                    displayTextKey="job_role"
                    placeholder="Select role"
                    onChange={(e, value) => field.onChange(value)}
                  />
                )}
              />
            </FormControl>
          </Grid>
          {/* qualifications */}
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth >
              <Controller
                control={control}
                name="qualifications"
                render={({ field }) => (
                  <>
                    <QualificationOptions
                      onAdd={(data) => {
                        setSelectedQualifications([...selectedQualifications, data]);
                        setValue('qualifications', [...(defaultValues?.qualifications || []), data]);
                        trigger('qualifications');
                      }}
                      error={!!errors.qualifications}
                      helperText={errors.qualifications?.message}
                    />
                    <Stack direction="row" spacing={1} mt={1}>
                      {selectedQualifications.map((qualification, index) => (
                        <Chip
                          key={index}
                          label={qualification.values.join(', ')}
                          onDelete={() => removeQualification(index)}
                        />
                      ))}
                    </Stack>
                  </>
                )}
              />
            </FormControl>
          </Grid>
          {/* keyskills */}
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth >
              <Controller
                control={control}
                name="key_skills"
                render={({ field }) => (
                  <TypeAhead
                    {...field}
                    api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.skills}`}
                    multiple={true}
                    fullWidth
                    label="Select skills"
                    placeholder="Select skills"
                    onChange={(e, value) => field.onChange(value)}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* experience */}
            <FormControl fullWidth error={!!errors.experience}>
              <InputLabel size='small' id="experience">Experience</InputLabel>
              <Controller
                name="experience"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    size='small'
                    label="Experience"
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="0-5">0-5 Year</MenuItem>
                    <MenuItem value="1-5">1-5 Years</MenuItem>
                    <MenuItem value="2-6">2-6 Years</MenuItem>
                    <MenuItem value="3-8">3-8 Years</MenuItem>
                    <MenuItem value="5-10">5-10 Years</MenuItem>
                    <MenuItem value="10-40">10+ Years</MenuItem>
                  </Select>
                )}
              />
              {errors.experience && (
                <FormHelperText error>{errors.experience?.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* work mode */}
            <FormControl fullWidth error={!!errors.work_mode}>
              <InputLabel size='small' id="work_mode">Work Mode</InputLabel>
              <Controller
                name="work_mode"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    size='small'
                    label="Work Mode"
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="Office Environment">Office Environment</MenuItem>
                    <MenuItem value="Work From Home">Work From Home</MenuItem>
                    <MenuItem value="Hybrid">Hybrid</MenuItem>
                  </Select>
                )}
              />
              {errors.work_mode && (
                <FormHelperText error>{errors.work_mode?.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.job_type}>
              <InputLabel size='small' id="job_type">Job Type</InputLabel>
              <Controller
                name="job_type"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    size='small'
                    label="Job Type"
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="Full Time">Full Time</MenuItem>
                    <MenuItem value="Part Time">Part Time</MenuItem>
                    <MenuItem value="Self-Employed">Self-Employed</MenuItem>
                    <MenuItem value="Trainee">Trainee</MenuItem>
                    <MenuItem value="Freelancer">Freelancer</MenuItem>
                    <MenuItem value="Internship">Internship</MenuItem>
                  </Select>
                )}
              />
              {errors.job_type && (
                <FormHelperText error>{errors.job_type?.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* location */}
            <FormControl fullWidth >
              <Controller
                control={control}
                name="location"
                render={({ field }) => (
                  <TypeAhead
                    {...field}
                    api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.city}`}
                    multiple={false}
                    fullWidth
                    label="Select location"
                    placeholder="Select location"
                    onChange={(e, value) => field.onChange(value)}
                  />
                )}
              />
            </FormControl>
          </Grid>
          {/* certifications */}
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth >
              <Controller
                name={`certifications`}
                control={control}
                render={({ field }) => (
                  <>
                    <TextField
                      size='small'
                      {...field}
                      variant="outlined"
                      label={`Certification`}
                      fullWidth
                      error={!!errors.certifications}
                      helperText={errors.certifications?.message}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              if (field.value && !Array.isArray(field.value)) {
                                setAllCertifications((prev) => [...prev, field.value]);
                                setValue('certifications', '');
                              }
                            }}
                            edge="end"
                          >
                            <AddIcon />
                          </IconButton>
                        ),
                      }}
                    />
                    <Stack direction="row" spacing={1} mt={1}>
                      {allCertifications.map((qualification, index) => (
                        <Chip key={index} label={qualification} onDelete={() => removeCertification(index)} />
                      ))}
                    </Stack>
                  </>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth >
              <Controller
                name="company_overview"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size='small'
                    error={!!errors.company_overview}
                    helperText={errors.company_overview?.message}
                    label="Company Overview"
                    variant="outlined"
                    fullWidth
                    multiline
                  />
                )} />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <Box className="accomplishments" mt={3} mb={2}>
                <Typography className="title" variant="body2" gutterBottom>
                  Questions for Candidate Assessment
                </Typography>
                <Typography className="info-txt" variant="body2" gutterBottom>
                  Well structured questions are helpful for selecting candidates who fit your needs.
                </Typography>
                {keyQuestions.map((question, index) => (
                  <Box key={index} mt={2}>
                    <FormControl fullWidth>
                      <DynamicTextField
                        label={`Question ${index + 1} for candidate`}
                        rowsMax={10}
                        value={question}
                        error={index === 0 && !!errors.question}
                        helperText={index === 0 && errors.question?.message}
                        onChange={(value) => handleQuestionChange(index, value)}
                      />
                    </FormControl>
                  </Box>
                ))}
              </Box>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* start date */}
            <FormControl fullWidth>
              <Controller
                control={control}
                name="start_date"
                defaultValue={defaultValues?.start_date}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    value={dayjs(field?.value)}
                    autoOk={true}
                    format="DD/MM/YYYY"
                    label="Start Date"
                    disablePast
                    sx={{ width: '100%' }}
                    closeOnSelect={true}
                    slotProps={{
                      textField: {
                        size: 'small',
                        error: !!errors?.start_date,
                        helperText: errors?.start_date?.message,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* notice period */}
            <FormControl fullWidth error={!!errors.notice_period}>
              <InputLabel size='small' id="notice_period">Notice Period (preference)</InputLabel>
              <Controller
                name="notice_period"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    size='small'
                    label="Notice Period (preference)"
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="15 days">15 days</MenuItem>
                    <MenuItem value="1 month">1 month</MenuItem>
                    <MenuItem value="2 months">2 months</MenuItem>
                    <MenuItem value="6 months">6 months</MenuItem>
                  </Select>
                )}
              // rules={{ required: 'Notice period is required' }}
              />
              {errors.notice_period && (
                <FormHelperText error>{errors.notice_period.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* reporting to */}
            <FormControl fullWidth >
              <Controller
                control={control}
                name="reporting_to"
                render={({ field }) => (
                  <TypeAhead
                    {...field}
                    api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.role}`}
                    multiple={false}
                    fullWidth
                    displayTextKey="job_role"
                    label="Select reporting to"
                    placeholder="Select reporting to"
                    onChange={(e, value) => field.onChange(value)}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* direct report */}
            <FormControl fullWidth >
              <TextField
                size='small'
                {...register('direct_reports')}
                error={!!errors.direct_reports}
                helperText={errors.direct_reports?.message}
                label="Direct Reports"
                variant="outlined"
                fullWidth
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.compensation}>
              <InputLabel size='small' id="compensation">Compensation Structure</InputLabel>
              <Controller
                name="compensation"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    size='small'
                    label="Compensation Structure"
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="All Fixed">All Fixed</MenuItem>
                    <MenuItem value="Fixed + Variable">Fixed + Variable</MenuItem>
                    <MenuItem value="Fixed + Variable">Fixed + Variable + Other Perks</MenuItem>
                  </Select>
                )}
              />
              {errors.compensation && (
                <FormHelperText error>{errors.compensation?.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* salary */}
            <FormControl fullWidth >
              <TextField
                size='small'
                {...register('salary')}
                error={!!errors.salary}
                helperText={errors.salary?.message}
                label="Salary"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                }}
              />
            </FormControl>
          </Grid>

          {/* </Stack> */}
          <Grid container item xs={12} sm={12} sx={{ mt: '1rem', justifyContent: 'center' }} >
            <Button className="next-btn" disabled={isSubmitting} size="medium" variant="contained" type="submit" >
              Submit
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Container >
  );
}