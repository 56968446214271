import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  IconButton,
  InputAdornment,
  SvgIcon,
  Typography,
  TextField,
  FormControl,
  Stack,
} from '@mui/material';
import { updateContact } from 'api/users';
import OneTimePassword from '../login/OneTimePassword';
import { useSnackbar } from 'components/snackbar';
import { useAuth } from 'hooks/useAuthContext';

const UpdateEmail = ({ onCancel, onCancelWithEmail }) => {
  const { user, updateContext } = useAuth();
  const [email, setEmail] = React.useState(user?.email || '');
  const [showOtpInput, setShowOtpInput] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userId, setUserId] = React.useState(null);
  const [error, setError] = React.useState('');
  const [isRemoving, setIsRemoving] = React.useState(false);

  const handleRemove = () => {
    setIsRemoving(true);
    handleSubmit();
  };


  const handleOpenOtpDialog = () => {
    setOpen(true);
  };

  const handleClose = (message) => {
    if (message === 'email') {
      onCancelWithEmail(email);
    }
    setOpen(false);
  };

  const handleSubmit = async () => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      setIsRemoving(false);
      return;
    }
    setError('');
    if (!open) {
      if(user?.email === email && user?.is_email_verified) {
        enqueueSnackbar("Email id already verifyed", { variant: 'error' });
        setIsRemoving(false)
        return 
      }
      const response = await updateContact({ emailId: email });
      if (response?.success === true && response.data?.id) {
        setUserId(response.data.id);
        enqueueSnackbar(`OTP has been sent.`, { variant: 'success' });
        handleOpenOtpDialog();
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    }
    setIsRemoving(false)
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(value)) {
      setError('Please enter a valid email address.');
    } else {
      setError(''); 
    }
  };

  return (
    <Box>
      <Card className="otp-card-wrapper" variant="outlined">
        <CardContent>
          <IconButton className="close-dialog-btn" aria-label="delete" color="primary" onClick={onCancel}>
            <SvgIcon sx={{ width: '20px', height: '20px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_456_20993)">
                  <path
                    d="M7 7L17 17M17 7L7 17L17 7Z"
                    stroke="#111827"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_456_20993">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </SvgIcon>
          </IconButton>

          <Box mb={2.5} sx={{ textAlign: 'left' }}>
            <Typography mb={1} className="title" component="h6">
              Update Email
            </Typography>
            <Typography sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
              This email would be used for primary communication by your recruiters
            </Typography>
          </Box>

          <Box mb={4}>
            <FormControl size="small" fullWidth
              sx={{
                display: 'inline-flex',
                flexDirection: 'column',
                position: 'relative',
                minWidth: '0px',
                padding: '0px',
                margin: '0px',
                border: '0px',
                verticalAlign: 'top',
                width: '100%',
                height: '60px',
              }}
            >
              <TextField
                name="email"
                size="small"
                label="Email ID"
                variant="outlined"
                placeholder="Enter your Email ID"
                fullWidth
                value={email}
                onChange={handleEmailChange}
                error={!!error}
                helperText={error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon sx={{ width: '16px', height: '16px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
                          <path
                            d="M14 -0.000244141H2.00001C1.09796 -0.000244141 0.363647 0.734069 0.363647 1.63612V10.3634C0.363647 11.2654 1.09796 11.9998 2.00001 11.9998H14C14.9021 11.9998 15.6364 11.2654 15.6364 10.3634V1.63612C15.6364 0.734069 14.9021 -0.000244141 14 -0.000244141ZM13.6366 1.09066L8.32798 5.0724C8.13502 5.21694 7.86572 5.21694 7.67276 5.0724L2.36343 1.09066H13.6366ZM14 10.9088H2.00001C1.69864 10.9088 1.45456 10.6641 1.45456 10.3634V1.77244L7.01819 5.94512C7.30659 6.16194 7.65364 6.27035 8.00001 6.27035C8.34638 6.27035 8.69343 6.16195 8.98183 5.94512L14.5455 1.77244V10.3634C14.5455 10.6641 14.3014 10.9088 14 10.9088Z"
                            fill="#6B7280"
                          />
                        </svg>
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Box>

          <Stack direction="row" alignItems={'center'} spacing={3}>
            <Button fullWidth className="otp-btn" variant="outlined" size="medium" onClick={onCancel}>
              Cancel
            </Button>
            <Button fullWidth className="otp-btn" variant="contained" size="medium" onClick={handleRemove} disabled={isRemoving}>
              {isRemoving ? 'Sending...' : 'Verify'}
            </Button>
          </Stack>
        </CardContent>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="otp-dialog-wrapper"
      >
        <OneTimePassword userId={userId} onCancelOTP={handleClose} email={email} isRegister={false} isEmailVerified ={true} />
      </Dialog>
    </Box>
  );
};

export default UpdateEmail;
