import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { SnackbarProvider } from './components/snackbar';
import AuthProvider from 'hooks/useAuthContext';
import ProgressBar from 'components/progress-bar';
// import { createPDF } from 'utils/createPDF';
import { useEffect } from 'react';
// import ResumeBuilder from 'components/ResumeBuilder';
// import RealTimePDF from 'components/RealTimePDF';
// import RealTimePDF from 'components/SmoothPDFLoader';


export default function App() {
  useEffect(() => {
    // createPDF();
  })
  return (
    // <RealTimePDF />
    // <ResumeBuilder />
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <HelmetProvider>
        <AuthProvider>
          <BrowserRouter>
            <ThemeProvider>
              <SnackbarProvider>
                <ProgressBar />
                <ScrollToTop />
                <StyledChart />
                <Router />
              </SnackbarProvider>
            </ThemeProvider>
          </BrowserRouter>
        </AuthProvider>
      </HelmetProvider>
    </LocalizationProvider>
  );
}
