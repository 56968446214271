import React, { useState, useEffect } from 'react';
import { getTimeDifferenceString } from 'utils/Utils';
import { Box, Button, Icon, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
const backIcon = `${process.env.REACT_APP_HOMEPAGE}assets/arrow_back.svg`;
import MyResumeDetails from './MyResumeDetails';
import { getResumesData } from 'api/users';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'components/snackbar';
import { SplashScreen } from 'components/loading-screen';

const MyResumes = ({ jobData, tabForRedirectBack, redirectToExternalJob }) => {
  const [selectedCard, setSelectedCard] = useState('');
  const [resumeData, setResumeData] = useState([]);
  const [selectedResume, setSelectedResume] = useState(null);
  // const [createNewResume, setCreateNewResume] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await getResumesData();
      if (response?.success && response.data && response.data.length > 0) {
        setSelectedCard(response.data[0]._id)
        const updatedResume = {
          ...response.data[0],
          updatedAtDate: getTimeDifferenceString(response.data[0]?.updatedAt, "Updated")
        };
        setResumeData([updatedResume, ...response.data.slice(1)]);
        setSelectedResume(updatedResume);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      enqueueSnackbar('Failed to fetch data. Please try again later.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const handleCardClick = (resume) => {
    setSelectedCard(resume._id);
    resume.updatedAtDate = getTimeDifferenceString(resume?.updatedAt, "Updated");
    setSelectedResume(resume);
  };
  const handleResumeDataChange = async () => {
    // setSelectedResume(null);
    // setSelectedCard(resumeData.original_id);
    await fetchData();
  };

  const bankInIcon = (
    <Icon className="extLoginIcon">
      <img alt="edit" width="24px" height="24px" src={backIcon} />
    </Icon>
  );

  const handleNavigation = () => {
    navigate('/create-update-resume', { state: { totalResume: resumeData.length, createNewResume: true } });
  };

  const handleRedirectToExternalJob = (applicationData, tab) => {
    if (applicationData && tab) {
      redirectToExternalJob(applicationData, tab);
    }
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" sx={{ position: 'relative', overflow: 'hidden', width: '100%', height: 'calc(100vh - 110px)' }} alignItems="center">
        <SplashScreen />
      </Grid>
    );
  }

  return (
    <Box sx={{
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      height: 'calc(100vh - 100px)'
    }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box className="resume-type-container">
            <Box className="resume-type-header" mb={2}>
              <Typography className="title">Select Resume</Typography>
            </Box>
            <Box className="resume-type-content" sx={{ textAlign: 'center' }}>

              <Box sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: 'auto'
              }}>
                {resumeData?.map((resume, index) => (
                  <Card
                    className={`resume-type-card master-resume-card ${selectedCard === resume._id ? ' selected-card' : ''
                      }`}
                    variant="outlined"
                    onClick={() => handleCardClick(resume)}
                    sx={{ marginBottom: '1rem' }}
                    key={resume?._id || index}
                  >
                    <CardContent>
                      {resume.IsMostRelevent && (
                        <Typography variant="p" className="suggestion-tag">
                          Most Relevant For This Job
                        </Typography>
                      )}
                      <Box>
                        <Typography className="card-title">{resume.title}</Typography>
                        <Typography className="last-updated">{resume?.updatedAt ? getTimeDifferenceString(resume?.updatedAt, "Updated") : 'N/A'}</Typography>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
                <Button variant="outlined" size="large" disabled={resumeData.length >= 5} sx={{ border: 'none!important' }} className="create-new-resume-btn" onClick={handleNavigation} >
                  + Create New Resume
                </Button>
                <Typography sx={{ marginTop: '1rem' }} className="total-resume-text">
                  {resumeData.length < 5 ? `${5 - resumeData.length} more resumes can be created` : "Maximum 5 resumes can be added please delete any of the above resume to add new."}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={8}>
          {resumeData && <MyResumeDetails
            data={selectedResume}
            totalResume={resumeData?.length}
            onDataChange={handleResumeDataChange}
            jobData={jobData}
            redirectedToExternalJob={handleRedirectToExternalJob}
          />}
        </Grid>
      </Grid >
    </Box>
  );
};

export default MyResumes;
