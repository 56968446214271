import React, { useState, useEffect } from 'react';
import { Grid, Paper, Chip, Card, CardContent, Typography, Box } from '@mui/material';
import { getFeedbackList } from 'api/feedback';
import { useSnackbar } from 'components/snackbar';
import CircularProgress from '@mui/material/CircularProgress';
const dayjs = require('dayjs');

const FeedbackList = () => {
  const [feedbackList, setFeedbackList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchFeedbackList();
  }, []);

  const fetchFeedbackList = async () => {
    try {
      const response = await getFeedbackList();
      if (response?.success) {
        setFeedbackList(response.data);
      } else {
        console.error('Failed to fetch feedback list:', response.message);
        enqueueSnackbar('Failed to fetch feedback list', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error fetching feedback list:', error);
      enqueueSnackbar('Error fetching feedback list', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  if (feedbackList?.length === 0) {
    return (
      <Typography variant="body1" align="center" style={{ marginTop: '20px' }}>
        No feedback available.
      </Typography>
    );
  }

  return (
    <Box style={{ marginTop: '80px', marginLeft: '300px', marginRight: '30px' }}>
      <Grid container spacing={3}>
        {feedbackList?.map((feedback) => (
          <Grid item key={feedback?._id} xs={12} sm={6} md={4}>
            <Paper elevation={3}>
              <Card>
                <CardContent style={{ minHeight: '200px' }}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={8}>
                      <Typography variant="h6" gutterBottom>
                        Job Title: {feedback?.job_id?.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                      <Typography variant="body1">
                        {feedback?.application ? (
                          <Chip label="Application" color="success" />
                        ) : (
                          <Chip label="Candidate" color="primary" />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography variant="body1">Feedback: {feedback?.feedback}</Typography>
                  <Typography variant="body1">Created At: {dayjs(feedback?.createdAt).format('DD/MM/YYYY')}</Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FeedbackList;
