import { Box, Card, IconButton, CardContent, Stack, Typography, Button, Dialog } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { useState, useEffect, useRef } from 'react';
import CertificationDetails from './AddCertification';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import dayjs from 'dayjs';
import RemoveCertificate from './RemoveCertificateDialog';
import { useSnackbar } from 'components/snackbar';
import { deleteCertificateHistory } from 'api/users';

const CertificationSection = ({ certificateData, onCancel, expandedSection, handleExpand, onCertificateDataChange, scrollToChild }) => {
  const [showEducation, setShowEducation] = useState(false);
  const [educations, setEducations] = useState([]);
  const [uploaded, setUploaded] = useState(false);
  const [actionType, setActionType] = useState('');
  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState('');
  const [title, setTitle] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCard, setSelectedCard] = useState(null);
  const certificationIcon = `${process.env.REACT_APP_HOMEPAGE}assets/certification-img.png`;
  const [idForScroll, setIdForScroll] = useState(null);
  const [scroll, setScroll] = useState(false);

  const certificationRef = useRef(null);
  const certificateSectionRef = useRef(null);

  useEffect(() => {
    const newWork = certificateData?.filter(data => 
      !educations?.some(history => history._id === data._id)
    ); 
    if (certificateData && certificateData?.length > 0) setIdForScroll(newWork[0]?._id || null);    
    setEducations(certificateData);
  }, [certificateData, uploaded]);

  useEffect(() => {
    if (idForScroll && scroll) {
      scrollToChild(idForScroll);
    }
  }, [idForScroll]);

  const scrollToEducation = () => {
    certificateSectionRef.current.scrollTo({
      top: certificationRef.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const handleCardClick = (item, index) => {
    handleExpand('certificate', index);
    setSelectedCard(item);
    setShowEducation(false);
  };

  const handleDeleteCertificate = async (certificateId, title, event) => {
    event.stopPropagation();
    if (!certificateId) {
      localStorage.removeItem("certificate");
      setEducations([...educations?.filter(edu => edu?._id)]);
      setSelectedCard(null);
    } else {
      setActionType('certificate');
      setTitle(title);
      setItemId(certificateId);
      setOpen(true);
    }
  };

  const handleDataChange = async (isChanged) => {
    setShowEducation(false);
    if (isChanged) {
      await onCertificateDataChange();
      setScroll(true);
    }
      // scrollToEducation();
  };

  const handleClose = () => {
    setOpen(false);
    setItemId('');
  };

  const handleDeleteAction = async () => {
    if (actionType === 'certificate') {
      const response = await deleteCertificateHistory({ certificateId: itemId });
      if (response && response?.success) {
        setEducations(response?.data || []);
        onCertificateDataChange();
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    }
    handleClose();
  };

  const handleAddNewEdu = () => {
    setShowEducation(true);
    handleExpand("certificate", educations.length);
    setSelectedCard({});
  }

  return (
    <Box className="candidate-basic-det-wrapper" >
      <Box mb={3}>
        <Typography className="step-heading" variant="h6" gutterBottom>
          Your Certification (Optional)
        </Typography>
        <Typography className="step-info" variant="body2" gutterBottom>
          Putting up relevant certifications help recruiters understand you better
        </Typography>
      </Box>
      <Box mb={3} ref={certificateSectionRef}>
        {educations?.length !== 0 &&
          educations?.map((item, index) => (
            <React.Fragment key={item._id || index}>
              {expandedSection.section !== 'certificate' || expandedSection.index !== index ? (
                <Card id={idForScroll === item?._id ? idForScroll : null} className={`info-list-item ${item?.status !== "active" ? "incomplete-details" : ""}`} variant="outlined" key={item._id} sx={{ marginTop: 1 }} onClick={() => handleCardClick(item, index)} >
                  <CardContent>
                    <Stack direction="row" justifyContent={'space-between'} spacing={2}>
                      <Box>
                        <Stack direction="row" alignItems={'center'} spacing={1.5}>
                          <Typography className="title-wrapper" variant="h6">
                            {item.certificate_received} at {item.education_institute_name}
                          </Typography>
                          {item?.status !== "active" && <Box className="incomplete-tag">Incomplete</Box>}
                        </Stack>
                        <Stack direction="row" alignItems={'center'} mt={0.5} spacing={2}>
                          <Box
                            component="img"
                            alt="certification"
                            sx={{ marginRight: '5px!important' }}
                            src={certificationIcon}
                          />
                          <Typography className="info-wrapper" variant="body2" gutterBottom>
                            {item.education_to ? `${dayjs(item.education_to).format('YYYY')}` : ''}
                          </Typography>
                        </Stack>
                      </Box>
                      <Stack direction="row" alignItems={'center'} spacing={2}>
                        <IconButton
                          className="delete-sec-btn"
                          aria-label="delete"
                          onClick={(event) => handleDeleteCertificate(item._id, item.certificate_received, event)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M11.0948 2.37734C11.0948 2.22733 11.0353 2.08301 10.9289 1.97715C10.8231 1.87074 10.6787 1.81131 10.5287 1.81131H8.70671L8.44689 0.771503C8.33369 0.317545 7.92557 -0.000564375 7.45803 7.54076e-07H4.54298C4.07543 -0.000565244 3.66732 0.317545 3.55412 0.771503L3.29431 1.81131H1.47228C1.15983 1.81131 0.90625 2.06489 0.90625 2.37734C0.90625 2.68978 1.15983 2.94337 1.47228 2.94337H1.50171C1.46096 3.10186 1.44737 3.26658 1.46096 3.42959L2.14473 10.2668C2.24096 11.2523 3.0702 12.0027 4.06023 12H7.94092C8.93091 12.0028 9.7602 11.2523 9.85642 10.2668L10.5402 3.42901C10.5538 3.266 10.5396 3.10185 10.4994 2.94335H10.5289C10.6789 2.94335 10.8232 2.88392 10.9291 2.77751C11.0355 2.67166 11.0948 2.52733 11.0948 2.37734ZM4.63071 1.13206H7.3697L7.53951 1.8113H4.46087L4.63071 1.13206ZM9.32707 3.05494C9.3916 3.1257 9.4233 3.22135 9.41311 3.31702L8.72933 10.1542C8.69028 10.5601 8.34839 10.8691 7.94085 10.868H4.06015C3.65261 10.8691 3.31072 10.5601 3.27167 10.1542L2.58789 3.3176C2.57827 3.22194 2.6094 3.12684 2.67393 3.05552C2.73846 2.9842 2.82959 2.94345 2.92582 2.94345H9.07529C9.17151 2.94288 9.26255 2.98362 9.32707 3.05494ZM5.09484 4.52832C4.94483 4.52832 4.80051 4.58775 4.69465 4.69416C4.58824 4.80001 4.52881 4.94435 4.52881 5.09434V7.81131C4.52881 8.12375 4.78239 8.37734 5.09484 8.37734C5.40728 8.37734 5.66086 8.12375 5.66086 7.81131V5.09434C5.66086 4.94434 5.60143 4.80001 5.49502 4.69416C5.38917 4.58774 5.24483 4.52832 5.09484 4.52832ZM6.50599 4.69416C6.61185 4.58775 6.75617 4.52832 6.90618 4.52832C7.05617 4.52832 7.20051 4.58774 7.30636 4.69416C7.41277 4.80001 7.4722 4.94434 7.4722 5.09434V7.81131C7.4722 8.12375 7.21862 8.37734 6.90618 8.37734C6.59373 8.37734 6.34015 8.12375 6.34015 7.81131V5.09434C6.34015 4.94435 6.39958 4.80001 6.50599 4.69416Z"
                              fill="#FF4949"
                            />
                          </svg>
                        </IconButton>

                        <KeyboardArrowDownOutlinedIcon />
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              ) : (
                <CertificationDetails
                  key={item._id}
                  certificateData={educations.find((_, idx) => idx === expandedSection.index)}
                  id={item._id}
                  onCancel={() => {
                    setShowEducation(false);
                    setSelectedCard(null);
                    onCancel()
                  }}
                  onCertificateDataChange={handleDataChange}
                />
              )}
            </React.Fragment>
          ))}
      </Box>
      {
        (showEducation && expandedSection.section === 'certificate' &&
          (<CertificationDetails
            onCertificateDataChange={handleDataChange}
            onCancel={() => {
              setShowEducation(false);
              setSelectedCard(null);
              onCancel()
            }} />
          ))}
      <Box mt={2}>
        {(!selectedCard || selectedCard?.status || expandedSection?.section !== 'certificate') &&
          <Button
            className="add-btn"
            startIcon={<AddIcon />}
            disabled={educations?.some(edu => !edu?._id)}
            onClick={handleAddNewEdu}>
            Add Certification
          </Button>}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="otp-dialog-wrapper"
      >
        <RemoveCertificate onClose={handleClose} onRemove={handleDeleteAction} title={title} />
      </Dialog>
    </Box>
  );
};

export default CertificationSection;
