import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography, Link
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSnackbar } from 'components/snackbar';
import { useAuth } from 'hooks/useAuthContext';
import { getAllAdminsAndCompanies, deleteUser } from 'api/addadminandcompany'
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import AddUsersComponent from 'components/AddUsers';
import EditIcon from '@mui/icons-material/Edit';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const UserManageComponent = () => {
    const { user } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [isFetched, setIsFetched] = useState(false);
    const [isClick, setIsClick] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [itemId, setItemId] = useState('');

    const handleClose = () => {
        setOpen(false);
        setItemId('');
    };
    const handleClosePasswordModal = () => {
        setIsOpen(false);
    };
    const [admins, setAdmins] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [recruiter, setRecruiter] = useState([]);


    const handleDeleteUser = async () => {
        const response = await deleteUser({ id: itemId });
        if (response && response?.success) {
            if (response?.data && response.data?.user_type === 'admin') {
                const existingIndex = admins.findIndex(item => item._id === response.data?._id);
                if (existingIndex !== -1) {
                    const updatedAdmins = [...admins];
                    updatedAdmins.splice(existingIndex, 1);
                    setAdmins([...updatedAdmins]);
                }
            } else if (response?.data && response.data?.user_type === 'company') {
                const existingIndex = companies.findIndex(item => item._id === response.data?._id);
                if (existingIndex !== -1) {
                    const updatedCompany = [...companies];
                    updatedCompany.splice(existingIndex, 1);
                    setCompanies([...updatedCompany]);
                }
            } else {
                const existingIndex = recruiter.findIndex(item => item._id === response.data?._id);
                if (existingIndex !== -1) {
                    const updatedRecruiter = [...recruiter];
                    updatedRecruiter.splice(existingIndex, 1);
                    setRecruiter([...updatedRecruiter]);
                }
            }
            setOpen(false);
            setItemId('');
            enqueueSnackbar(response.message, { variant: 'success' });
        } else {
            enqueueSnackbar(response.message, { variant: 'error' });
            setItemId('');
        }
    };

    const handleEditUser = async (data) => {
        setSelectedItem(data)
        setIsClick(true);
        setIsEditOpen(true)
    }

    const handleSaveData = (newData) => {
        setIsClick(false);
        if (newData) {
            if (newData?.user_type === 'admin') {
                if (selectedItem && selectedItem?.full_name) {
                    const existingIndex = admins.findIndex(item => item._id === newData._id);
                    if (existingIndex !== -1) {
                        const updatedAdmins = [...admins];
                        updatedAdmins.splice(existingIndex, 1);
                        setAdmins([newData, ...updatedAdmins]);
                    }
                } else {
                    setAdmins([newData, ...admins]);
                }
            } else if (newData?.user_type === 'company') {
                if (selectedItem && selectedItem?.full_name) {
                    const existingIndex = companies.findIndex(item => item._id === newData._id);
                    if (existingIndex !== -1) {
                        const updatedCompany = [...companies];
                        updatedCompany.splice(existingIndex, 1);
                        setCompanies([newData, ...updatedCompany]);
                    }
                } else {
                    setCompanies([newData, ...companies]);
                }
            } else {
                if (selectedItem && selectedItem?.full_name) {
                    const existingIndex = recruiter.findIndex(item => item._id === newData._id);
                    if (existingIndex !== -1) {
                        const updatedRecruiter = [...recruiter];
                        updatedRecruiter.splice(existingIndex, 1);
                        setRecruiter([newData, ...updatedRecruiter]);
                    }
                } else {
                    setRecruiter([newData, ...recruiter]);
                }
            }
        }
        setIsEditOpen(false);
        setSelectedItem({});
    };

    useEffect(() => {
        const fetchjobs = async () => {
            try {
                const response = await getAllAdminsAndCompanies({ id: user?.id, userType: user?.user_type });
                if (response && response?.success && response?.data) {
                    if (response?.data?.admin) {
                        setAdmins(response?.data?.admin);
                    }
                    if (response?.data?.company) {
                        setCompanies(response?.data?.company);
                    }
                    if (response?.data?.recruiter) {
                        setRecruiter(response?.data?.recruiter);
                    }
                }
                setIsClick(false)
                setIsFetched(true);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        };
        fetchjobs();
    }, []);

    return (
        <Box style={{ marginTop: '80px', marginLeft: '250px' }}>
            <Container>
                {isFetched ? <>
                    <Typography variant="h5" component="div" sx={{ marginBottom: '1rem' }}>
                        Manage Admins and Companies
                    </Typography>
                    <Box sx={{ margin: '2rem 0' }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                            <Typography variant="h5" component="div" sx={{ marginBottom: '1rem' }}>
                                Admin List
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <Link variant="contained" onClick={() => setIsClick(true)}>
                                    <AddCircleIcon /> <span>Add User</span>
                                </Link>
                            </Stack>
                        </Stack>
                        {isEditOpen || isClick ? <AddUsersComponent onSaveData={handleSaveData} initialData={selectedItem} userOption={[
                            { label: 'admin' },
                            { label: 'company' },
                            { label: 'recruiter' }
                        ]} /> : ''}
                        <Paper elevation={3}>
                            <div style={{ height: 'auto', width: '100%' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Phone Number</TableCell>
                                                <TableCell>Email Id</TableCell>
                                                <TableCell>Password</TableCell>
                                                <TableCell>Edit</TableCell>
                                                <TableCell>Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {admins.length === 0 ? (
                                                <TableRow>
                                                    <TableCell colSpan={8}>No company admin added yet.</TableCell>
                                                </TableRow>
                                            ) : (
                                                admins.map((item, index) => (
                                                    <TableRow key={item._id || index}>
                                                        <TableCell>{item.full_name || 'N/A'}</TableCell>
                                                        <TableCell>{item.phone_number || 'N/A'}</TableCell>
                                                        <TableCell>{item.email || 'N/A'}</TableCell>
                                                        <TableCell style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.generated_password || item?.password}</TableCell>
                                                        <TableCell><EditIcon onClick={() => handleEditUser(item)} style={{ cursor: 'pointer' }} /></TableCell>
                                                        <TableCell key={item._id}>
                                                            <LoadingButton
                                                                fullWidth size="medium"
                                                                variant="contained"
                                                                textAlign={'right'}
                                                                endIcon={<DeleteIcon />}
                                                                onClick={() => {
                                                                    // handleDeleteUser(item._id);
                                                                    setItemId(item._id)
                                                                    setOpen(true);
                                                                }
                                                                }
                                                            >
                                                            </LoadingButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Paper>
                    </Box>
                    <Box sx={{ margin: '2rem 0' }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                            <Typography variant="h5" component="div" sx={{ marginBottom: '1rem' }}>
                                Recruiter User List
                            </Typography>
                        </Stack>
                        <Paper elevation={3}>
                            <div style={{ height: 'auto', width: '100%' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Phone Number</TableCell>
                                                <TableCell>Email Id</TableCell>
                                                <TableCell>Password</TableCell>
                                                <TableCell>Edit</TableCell>
                                                <TableCell>Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {recruiter.length === 0 ? (
                                                <TableRow>
                                                    <TableCell colSpan={8}>No recruiter user added yet.</TableCell>
                                                </TableRow>
                                            ) : (
                                                recruiter.map((item, index) => (
                                                    <TableRow key={item._id || index}>
                                                        <TableCell>{item?.full_name || 'N/A'}</TableCell>
                                                        <TableCell>{item?.phone_number || 'N/A'}</TableCell>
                                                        <TableCell>{item?.email || 'N/A'}</TableCell>
                                                        <TableCell>{item?.generated_password || item?.password}</TableCell>
                                                        <TableCell><EditIcon onClick={() => handleEditUser(item)} style={{ cursor: 'pointer' }} /></TableCell>
                                                        <TableCell key={item._id}>
                                                            <LoadingButton
                                                                fullWidth size="medium"
                                                                variant="contained"
                                                                textAlign={'right'}
                                                                endIcon={<DeleteIcon />}
                                                                onClick={() => {
                                                                    // handleDeleteUser(item._id)}
                                                                    setItemId(item._id)
                                                                    setOpen(true);
                                                                }}
                                                            >
                                                            </LoadingButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Paper>
                    </Box>
                    <Box sx={{ margin: '2rem 0' }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                            <Typography variant="h5" component="div" sx={{ marginBottom: '1rem' }}>
                                Company User List
                            </Typography>
                            {/* <Stack direction="row" spacing={2}>
                                <Link variant="contained" onClick={() => navigate('/add-work-history-form')}>
                                    <AddCircleIcon /> <span>Add Company</span>
                                </Link>
                            </Stack> */}
                        </Stack>
                        <Paper elevation={3}>
                            <div style={{ height: 'auto', width: '100%' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Phone Number</TableCell>
                                                <TableCell>Email Id</TableCell>
                                                <TableCell>Password</TableCell>
                                                <TableCell>Edit</TableCell>
                                                <TableCell>Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {companies.length === 0 ? (
                                                <TableRow>
                                                    <TableCell colSpan={8}>No company user added yet.</TableCell>
                                                </TableRow>
                                            ) : (
                                                companies.map((item, index) => (
                                                    <TableRow key={item._id || index}>
                                                        <TableCell>{item?.full_name || 'N/A'}</TableCell>
                                                        <TableCell>{item?.phone_number || 'N/A'}</TableCell>
                                                        <TableCell>{item?.email || 'N/A'}</TableCell>
                                                        <TableCell>{item?.generated_password || item?.password}</TableCell>
                                                        <TableCell><EditIcon onClick={() => handleEditUser(item)} style={{ cursor: 'pointer' }} /></TableCell>
                                                        <TableCell key={item._id}>
                                                            <LoadingButton
                                                                fullWidth size="medium"
                                                                variant="contained"
                                                                textAlign={'right'}
                                                                endIcon={<DeleteIcon />}
                                                                onClick={() => {
                                                                    // handleDeleteUser(item._id)}
                                                                    setItemId(item._id)
                                                                    setOpen(true);
                                                                }}
                                                            >
                                                            </LoadingButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Paper>
                    </Box>
                </> : null}
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Delete User</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Would you like to delete this user.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleDeleteUser} autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog isOpen={isOpen} onClose={handleClosePasswordModal}>
                    <DialogTitle>Display password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please copy this password this will not show again!
                            {/* {displayPassword} */}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePasswordModal}>Cancel</Button>
                        <Button onClick={handleClosePasswordModal} autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Box>
    );
};

export default UserManageComponent;
