import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
// @mui
import { styled } from '@mui/system';
import {
  FormHelperText,
  Icon,
  Checkbox,
  Dialog,
  FormControlLabel,
  SvgIcon,
  OutlinedInput,
  FormControl,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  InputLabel,
  Divider,
  Typography,
  Button,
  Link,
} from '@mui/material';
// components
import FormProvider from 'hooks/form/form-provider';
import { useSnackbar } from 'components/snackbar';
// import { useAuth } from 'hooks/useAuthContext';
// Validation
import ForgotPasswordDialog from '../../../components/login/ForgotPasswordDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginSchema } from 'models/LoginSchema';

// import { Link } from 'react-router-dom';
// APIs
import { useAuth } from 'hooks/useAuthContext';
// import PhoneInput from 'components/country-code-picker';

// ----------------------------------------------------------------------
const DisabledLink = styled(Link)(({ theme, isDisabled }) => ({
  '&:disabled': {
    color: theme.palette.text.disabled,
    pointerEvents: 'none',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.text.disabled,
    },
  },
}));

export default function LoginForm({ jobId, setOpenRegister, onLogin }) {
  // const { setLocalStorage } = useAuth();
  const { loading, login, user } = useAuth();

  /*----------------SnackBar Defination--------------------------------*/
  const { enqueueSnackbar } = useSnackbar();
  /*----------------SnackBar Defination--------------------------------*/

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [autoSaveDialogOpen, setAutoSetDialogOpen] = useState(false);
  const [isPhoneNumber, setIsPhoneNumber] = useState(false);

  const autoSaveJobAppDialog = () => {
    setAutoSetDialogOpen((prev) => !prev);
  };

  const defaultValues = {
    emailOrPhone: '',
    password: '',
  };

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(LoginSchema),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isDirty, isValid, dirtyFields, isSubmitting }, watch
  } = methods;

  const onSubmit = async (formData) => {
    let response;
    if (formData?.emailOrPhone?.includes('@')) {
      response = await login({ email: formData?.emailOrPhone, password: formData?.password });
    } else {
      response = await login({ phone_number: formData?.emailOrPhone, password: formData?.password });
    }
    if (response?.success) {
      localStorage.setItem('prevPath', '/login');
      if (jobId) onLogin();
    } else {
      enqueueSnackbar(response?.message, { variant: 'error' });
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setValue('emailOrPhone', value, { shouldValidate: true });
    setIsPhoneNumber(/^[0-9]+$/.test(value));
  };

  const linkedinIcon = (
    <Icon className="extLoginIcon">
      <img alt="edit" width="24px" height="22px" src={`${process.env.REACT_APP_HOMEPAGE}assets/linkedIn.png`} />
    </Icon>
  );

  const googleIconPng = (
    <Icon className="extLoginIcon">
      <img alt="edit" width="24px" height="22px" src={`${process.env.REACT_APP_HOMEPAGE}assets/google.png`} />
    </Icon>
  );

  const handleRegisterOpen = () => {
    if (jobId){ 
      setOpenRegister(true);
    } else {
      navigate('/register');
    }
  }

  return (
    <Box className="login-page-wrapper">
      <Box className="login-content-wrapper">
        <Box>
          <Box
            mb={5}
            component="img"
            alt="Talent Junction"
            width="196px"
            height="61px"
            src={`${process.env.REACT_APP_HOMEPAGE}assets/TJ-Logo-LightBG-withTagline 1.svg`}
          />
        </Box>
        <Box>
          <Box>
            <Stack spacing={1} mb={3}>
              <Typography className="title">Login</Typography>
              <Typography className="info-text">Please login using registered WhatsApp number or Email Id</Typography>
            </Stack>
          </Box>
          <FormProvider sx={{ height: '60px' }} methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2.5}>
              <FormControl size="small" fullWidth
                className="password-field"
                sx={{
                  display: 'inline-flex',
                  flexDirection: 'column',
                  position: 'relative',
                  minWidth: '0px',
                  padding: '0px',
                  margin: '0px',
                  border: '0px',
                  verticalAlign: 'top',
                  width: '100%',
                  height: '67px',
                }}>

                {isPhoneNumber && (
                  <InputAdornment position="start">
                    <Box className="iti-flag-container" sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box className="iti-flag" sx={{ marginRight: '4px' }}></Box>
                      <Box className="selected-dial-code">+91</Box>
                      <Divider orientation="vertical" flexItem sx={{ marginLeft: '8px', marginRight: '8px' }} />
                    </Box>
                  </InputAdornment>
                )}

                <InputLabel style={{ lineHeight: '25px' }} htmlFor="outlined-adornment-email">Email Id / WhatsApp Number</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email"
                  type='text'
                  name="emailOrPhone"
                  tabIndex={2}
                  {...register('emailOrPhone')}
                  error={!!errors.emailOrPhone}
                  label='Email Id / WhatsApp Number'
                  onChange={handleInputChange}
                  inputProps={{
                    style: {
                      paddingLeft: isPhoneNumber ? '80px' : '20px',
                    },
                  }}
                />
                <FormHelperText style={{ color: errors.emailOrPhone ? '#FF4842' : 'inherit' }}>{errors.emailOrPhone?.message}</FormHelperText>
              </FormControl>
              <FormControl size="small" fullWidth
                className="password-field"
                sx={{
                  display: 'inline-flex',
                  flexDirection: 'column',
                  position: 'relative',
                  minWidth: '0px',
                  padding: '0px',
                  margin: '0px',
                  border: '0px',
                  verticalAlign: 'top',
                  width: '100%',
                  height: '67px',
                }}>
                <InputLabel style={{ lineHeight: '25px' }} htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  tabIndex={2}
                  {...register('password')}
                  error={!!errors.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        tabIndex={-1}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <SvgIcon sx={{ width: '16px', height: '16px' }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8 0.0604248C11.3236 0.0604248 14.1512 2.44413 15.5411 3.86595C16.153 4.48987 16.153 5.50774 15.5411 6.13366C14.1512 7.55549 11.3236 9.93919 8 9.93919C4.67641 9.93919 1.84877 7.55549 0.458942 6.13366C-0.152981 5.50974 -0.152981 4.49187 0.458942 3.86595C1.84877 2.44413 4.67641 0.0604248 8 0.0604248ZM8.002 9.07529C11.0076 9.07529 13.6293 6.85357 14.9231 5.52974C15.2091 5.23778 15.2091 4.76184 14.9231 4.46987C13.6293 3.14604 11.0076 0.924317 8.002 0.924317C4.99637 0.924317 2.3747 3.14604 1.08086 4.46987C0.7949 4.76384 0.7949 5.23778 1.08086 5.52974C2.3747 6.85357 4.99637 9.07529 8.002 9.07529ZM7.99995 1.42029C6.02619 1.42029 4.42039 3.02609 4.42039 4.99984C4.42039 6.97359 6.02619 8.57939 7.99995 8.57939C9.9737 8.57939 11.5795 6.97359 11.5795 4.99984C11.5795 3.02609 9.9737 1.42029 7.99995 1.42029ZM7.99995 7.7135C6.50413 7.7135 5.28629 6.49565 5.28629 4.99984C5.28629 3.50403 6.50413 2.28618 7.99995 2.28618C9.49576 2.28618 10.7136 3.50403 10.7136 4.99984C10.7136 6.49565 9.49576 7.7135 7.99995 7.7135ZM7.99994 3.36404C7.09605 3.36404 6.36414 4.09595 6.36414 4.99983C6.36414 5.90372 7.09605 6.63563 7.99994 6.63563C8.90383 6.63563 9.63573 5.90372 9.63573 4.99983C9.63573 4.09595 8.90383 3.36404 7.99994 3.36404Z" fill="#6B7280" />
                          </svg>
                        </SvgIcon>
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Password'
                />
                <FormHelperText style={{ color: errors.password ? '#FF4842' : 'inherit' }}>{errors.password?.message}</FormHelperText>
              </FormControl>
              <Stack style={{ marginTop: '5px' }} direction="row" alignItems={'center'} justifyContent={'space-between'}>
                <Box>
                  <FormControl className="remember-me-chk" size="small">
                    <FormControlLabel control={<Checkbox defaultChecked />} label="Remember Me" />
                  </FormControl>
                </Box>
                <Box>
                  <Link
                    className="fogot-pass-link"
                    underline="none"
                    onClick={autoSaveJobAppDialog}
                    style={{ cursor: 'pointer' }}
                  >
                    Forgot Password?
                  </Link>
                </Box>
              </Stack>
              <Button type="submit" variant="contained" size="large" className="login-btn" disabled={isSubmitting}>
                Login
              </Button>

              <Divider sx={{ fontSize: '0.8rem', fontWeight: 600 }}>OR</Divider>

              <Stack direction="row" alignItems={'center'} spacing={3}>
                <Button
                  variant="outlined"
                  size="large"
                  className="ext-login-btn"
                  fullWidth
                  startIcon={linkedinIcon}
                  onClick={(e) => {
                    window.open(`${process.env.REACT_APP_END_POINT}/auth/linkedin`, '_self');
                  }}
                >
                  Login with LinkedIn
                </Button>

                <Button
                  variant="outlined"
                  size="large"
                  className="ext-login-btn"
                  fullWidth
                  startIcon={googleIconPng}
                  onClick={(e) => {
                    window.open(`${process.env.REACT_APP_END_POINT}/auth/google`, '_self');
                  }}
                >
                  Login with Google
                </Button>
              </Stack>

              <Box>
                <Typography className="register-link" component="p">
                  Haven’t registered?&nbsp;
                  <Link onClick={() => handleRegisterOpen()} sx={{ cursor: 'pointer' }} underline="none">
                    Register Here
                  </Link>
                </Typography>
              </Box>
            </Stack>
          </FormProvider>
          {/* auto save job app dialog */}
          <Dialog
            open={autoSaveDialogOpen}
            onClose={autoSaveJobAppDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="forgot-password-dialog"
          >
            <ForgotPasswordDialog onChange={autoSaveJobAppDialog} />
          </Dialog>
        </Box>
      </Box>
    </Box>
  );
}
