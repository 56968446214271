import React from 'react'
import { Box, Container, Typography, IconButton, SvgIcon, List, ListItem, ListItemText } from '@mui/material'
// import PageHeader from '../page-header/PageHeader'
// import PageFooter from '../page-footer/PageFooter'


const TermsConditions = ({ onClose }) => {
    return (
        <Box>

            {/* Page header component */}
            {/* <PageHeader /> */}

            <Box className="terms-conditions" sx={{ padding: '20px !important' }}>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton aria-label="delete" color="primary" onClick={onClose} sx={{
                        position: 'absolute',
                        top: '0',
                        right: '0',
                    }}>
                        <SvgIcon sx={{ width: '20px', height: '20px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clipPath="url(#clip0_456_20993)">
                                    <path
                                        d="M7 7L17 17M17 7L7 17L17 7Z"
                                        stroke="#111827"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_456_20993">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </SvgIcon>
                    </IconButton>
                </Box>
                <Box className="content-wrapper">
                    <Container maxWidth="xl">
                        <Box mb={4}>
                            <Typography variant="h5" sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '24px', textAlign: 'left', color: '#111827' }}>Terms & Conditions</Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="h6" sx={{ fontSize: '14px !important' }}>1. Acceptance of Terms</Typography>
                            <Typography sx={{ fontSize: '14px !important', textAlign: 'left' }}>By using the website www.talentjn.com, or  “Talent Junction”, belonging the company Flintstone Solutions Private Limited, to create a profile, apply for job listings, or otherwise engage with our recruitment services, you agree to abide by these Terms & Conditions. These terms govern your use of our platform, and by using the platform, you agree to comply with all applicable laws and regulations.
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="h6" sx={{ fontSize: '14px !important' }}>2. Services Provided</Typography>
                            <Typography sx={{ fontSize: '14px !important', textAlign: 'left' }}>Talent Junction offers candidates a platform to create professional profiles, apply for job postings, and interact with potential employers in the finance sector.
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="h6" sx={{ fontSize: '14px !important' }}>3. Candidate Responsibilities</Typography>
                            <Typography sx={{ fontSize: '14px !important', textAlign: 'left' }}>
                                <ul style={{ paddingLeft: '20px', margin: 0 }}>
                                    <li>You must ensure that all information provided in your profile is accurate, truthful, and up-to-date.</li>
                                    <li>Misrepresentation of your skills, qualifications, or experience may lead to suspension or termination of your account.</li>
                                    <li>You agree to use the platform solely for lawful purposes, and to avoid engaging in any fraudulent or deceptive practices.</li>
                                </ul>
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="h6" sx={{ fontSize: '14px !important' }}>4. Privacy & Data Usage</Typography>
                            <Typography sx={{ fontSize: '14px !important', textAlign: 'left' }}>
                                <ul style={{ paddingLeft: '20px', margin: 0 }}>
                                    <li>Talent Junction respects your privacy and handles your personal information in accordance with our Privacy Policy.</li>
                                    <li>By applying for a job, you consent to your data being viewed by the recruitment companies.</li>
                                </ul>
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="h6" sx={{ fontSize: '14px !important' }}>5. Job Application Process</Typography>
                            <Typography sx={{ fontSize: '14px !important', textAlign: 'left' }}>
                                <ul style={{ paddingLeft: '20px', margin: 0 }}>
                                    <li>Talent Junction does not guarantee employment or any specific job offers from companies listed on the platform.</li>
                                    <li>Candidates are responsible for ensuring that they meet the requirements of any job posting before applying.</li>
                                    <li>Talent Junction is not liable for any miscommunication, missed opportunities, or disputes that may arise between you and potential employers.</li>
                                </ul>
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="h6" sx={{ fontSize: '14px !important' }}>6. Fees & Payments</Typography>
                            <Typography sx={{ fontSize: '14px !important', textAlign: 'left' }}>
                                <ul style={{ paddingLeft: '20px', margin: 0 }}>
                                    <li>The services offered on the Platform such as creating a profile and applying to job listings are free for candidates.</li>
                                </ul>
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="h6" sx={{ fontSize: '14px !important' }}>7. Intellectual Property</Typography>
                            <Typography sx={{ fontSize: '14px !important', textAlign: 'left' }}>
                                <ul style={{ paddingLeft: '20px', margin: 0 }}>
                                    <li>All content you create and upload to Talent Junction, including resumes, cover letters, and profile data, remains your property.</li>
                                    <li>By posting your materials, you grant Talent Junction a non-exclusive license to use, modify, and display this content for the purposes of recruitment.</li>
                                </ul>
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="h6" sx={{ fontSize: '14px !important' }}>8. Termination of Service</Typography>
                            <Typography sx={{ fontSize: '14px !important', textAlign: 'left' }}>
                                <ul style={{ paddingLeft: '20px', margin: 0 }}>
                                    <li>You may request the termination of your account at any time. Talent Junction also reserves the right to terminate or suspend your account for violations of these Terms & Conditions.</li>
                                </ul>
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="h6" sx={{ fontSize: '14px !important' }}>9. Disclaimer of Warranties</Typography>
                            <Typography sx={{ fontSize: '14px !important', textAlign: 'left' }}>
                                <ul style={{ paddingLeft: '20px', margin: 0 }}>
                                    <li>Talent Junction does not guarantee that you will receive job offers, interviews, or employment as a result of using the platform.</li>
                                    <li>The platform is provided "as is" and without any warranties, express or implied, regarding its performance or accuracy.</li>
                                </ul>
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="h6" sx={{ fontSize: '14px !important' }}>10. Limitation of Liability</Typography>
                            <Typography sx={{ fontSize: '14px !important', textAlign: 'left' }}><ul style={{ paddingLeft: '20px', margin: 0 }}>
                                <li>Talent Junction is not liable for any direct, indirect, incidental, or consequential damages that may result from your use of the platform or services.</li>
                                <li>Your sole remedy in the event of dissatisfaction with the platform is to discontinue its use.</li>
                            </ul>
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="h6" sx={{ fontSize: '14px !important' }}>11. Governing Law</Typography>
                            <Typography sx={{ fontSize: '14px !important', textAlign: 'left' }}>
                                These Terms & Conditions are governed by the laws of India, and any disputes arising from your use of the platform will be subject to the exclusive jurisdiction of the courts of New Delhi.
                            </Typography>
                        </Box>
                    </Container>
                </Box>
            </Box>

            {/* Page footer component */}
            {/* <PageFooter /> */}

        </Box>
    )
}

export default TermsConditions
