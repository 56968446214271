import { Avatar, Box, Button, Card, CardContent, Chip, Divider, Grid, List, ListItem, ListItemText, Stack, SvgIcon, CircularProgress, ToggleButton, AppBar, Toolbarup, Typography, ToggleButtonGroup, ListItemButton, ListItemIcon, Drawer, Dialog, DialogContent, IconButton, Menu, MenuItem, Skeleton } from '@mui/material'
import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
const JobNotMatchingIcon = `${process.env.REACT_APP_HOMEPAGE}assets/job-not-matching-icon.png`;
const ModSuitableIcon = `${process.env.REACT_APP_HOMEPAGE}assets/moderately-suitable-icon.png`;
const suitableIcon = `${process.env.REACT_APP_HOMEPAGE}assets/highly-suitable-icon.png`;
import AddCircleIcon from '@mui/icons-material/AddCircle';

import JopApplicationDrawer from './JopApplicationDrawer'
import UpdateReleventSkillsDrawer from './UpdateReleventSkillsDrawer'
import ChatBotDrawer from './ChatBotDrawer'
import { jobDetails } from 'api/job';
import { useAuth } from 'hooks/useAuthContext';
import { getTimeDifferenceString, changeNameFormat, formatNumber } from 'utils/Utils';
import { getApplicationApplyDetails } from 'api/application';
import { clusteredData } from 'data/city.js';
import StarIcon from '@mui/icons-material/Star';
import { saveJobApplication } from 'api/application';
import { useSnackbar } from 'components/snackbar';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ExternalJob from './ExternalJob';
import ExternalJobStatus from './ExternalJobStatus';
import UnsaveCard from './UnsaveCard';
import dayjs from 'dayjs';
import ResumeFullView from '../basic-profile/ResumeFullView';

const JobDescription = ({ jobId }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user, updateContext } = useAuth();
  const [userId, setUserId] = useState(user?.id || null);

  const [job, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [matchingSkills, setMatchingSkills] = useState([]);
  const [coreMatchSkills, setCoreMatchSkills] = useState([]);
  const [nonMatchingSkills, setNonMatchingSkills] = useState([]);
  const [industrySector, setIndustrySector] = useState('');
  const [requirementMatchItem] = useState(['Offered Compensation', 'Job Location', 'Job Format', 'Job Type', 'Job Shift', 'Notice Period']);
  const jobFullOverviewRef = useRef(null);
  const jobApplicationRef = useRef(null);
  const [openLogin, setOpenLogin] = useState(false);
  const jobOverviewRef = useRef(null);
  const jobDescRef = useRef(null);
  const [status, setStatus] = useState(null);
  const { jobApply, setJobApply } = useAuth();
  const [applyButton, setApplyButton] = useState(false);
  const [application, setApplication] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openSkillsDrawer, setOpenSkillsDrawer] = React.useState(false);
  const [viewResume, setViewResume] = useState(false);
  const [openChatBotDrawer, setOpenChatBotDrawer] = useState(false);
  const [alignment, setAlignment] = React.useState('job-overview');
  const [openExternalJob, setOpenExternalJob] = useState(false);
  const [openExternalJobStatus, setOpenExternalJobStatus] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(true);
  const [autoUnsaveDialogOpen, setAutoUnsaveDialogOpen] = useState(false);

  useEffect(() => {
    setAlignment('job-overview');
    setLoading(true);
    setApplyButton(false);
    loadDetails();
  }, [jobId]);

  useEffect(() => {
    setUserId(user?.id || null);
    setOpenChatBotDrawer(jobApply?.job?._id === jobId && jobApply?.openChatBot ? true : false);
  }, [user]);
  const loadDetails = async () => {
    try {
      const { jobData, jobStatus, jobApplication } = await fetchJobDetails();
      await fetchCandidateDetails(jobData, jobApplication);
      if (applyButton && user) handleNavigation(jobData, jobStatus, jobApplication);
    } catch (error) {
      console.error("Error fetching job or candidate info:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchJobDetails = async () => {
    try {
      const response = await jobDetails({ id: jobId, userId: user?.id });
      if (response?.success) {
        const { job: jobData, jobIdInCandidate: jobApplication } = response.data || {};
        const jobStatus = jobApplication?.status;
        setJobs(jobData || []);
        setApplication(jobApplication || null);
        setStatus(jobStatus || null);
        return { jobData, jobStatus, jobApplication };
      } else {
        console.error("Failed to fetch job info:", response?.message);
      }
    } catch (error) {
      console.error("Error fetching job details:", error);
    }
    return {};
  };

  const fetchCandidateDetails = async (jobData, jobApplication) => {
    if (user?.id && user?.stages === 4) {
      try {
        const response = await getApplicationApplyDetails({ id: jobId });
        if (response?.success && response.data) {
          const data = response.data;
          setCandidateDetails(data);
          if (jobApplication && jobApplication?.status !== 'saved') {
            setIndustrySector(isIndustrySectorMatched(jobApplication?.requirements_matched?.work_history, job?.industry_sector));
            setMatchingSkills(jobApplication?.requirements_matched?.matching_skills || []);
            setCoreMatchSkills(jobApplication?.requirements_matched?.core_skills || []);
          } else {
            setIndustrySector(isIndustrySectorMatched(data.workHistory, job?.industry_sector));
            setMatchingSkills(
              handleMatchingSkills(
                data.workHistory || [],
                data.certificate || [],
                data.educations || [],
                jobData?.key_skills || [],
                jobData?.core_skills || []
              )
            );
          }
        }
      } catch (error) {
        console.error("Error fetching candidate details:", error);
      }
    }
  };

  // overview description toggle
  const handleToggleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      switch (newAlignment) {
        case 'job-overview':
          jobFullOverviewRef.current.scrollTo({
            top: jobOverviewRef.current.offsetTop,
            block: 'start',
            behavior: 'smooth',
          });
          break;
        case 'job-desc':
          jobFullOverviewRef.current.scrollTo({
            top: jobDescRef.current.offsetTop,
            block: 'start',
            behavior: 'smooth',
          });
          break;
        case 'job-application':
          jobFullOverviewRef.current.scrollTo({
            top: jobApplicationRef.current.offsetTop,
            block: 'start',
            behavior: 'smooth',
          });
          break;
        default:
          break;
      }
    }
  };

  // update job application drawer
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // update relevent skills drawer
  const toggleSkillsDrawer = (newOpenSkills) => () => {
    setOpenSkillsDrawer(newOpenSkills);
  };

  // chat bot drawer
  const toggleChatBotDrawer = (newOpenSkills) => () => {
    setOpenChatBotDrawer(newOpenSkills);
  };

  const handleSelectedResume = (resume, answers) => {
    toggleChatBotDrawer(false);
    loadDetails();
  };

  const requirementsMatch = (job, candidate, title) => {
    let match = 0;

    if (title === 'Qualification') match = compareQualifications(job, candidate);
    else if (title === 'Experience') match = candidate;
    else if (title === 'Skills') match = job === candidate ? 4 : candidate >= 1 ? 1 : 0;

    return match >= 4 ? <CheckIcon /> : match < 1 ? <CrossIcon /> : <WarningIcon />;
  };

  const CheckIcon = () => (
    <Stack>
      <Box className="match-icon">
        <SvgIcon>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
              fill="white"
            />
          </svg>
        </SvgIcon>
      </Box>
    </Stack>
  );

  const CrossIcon = () => (
    <Stack>
      <Box className="error-match-icon">
        <SvgIcon>
          <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
            <path
              d="M0.134092 5.23008L2.36417 3L0.134092 0.769922C0.0918098 0.728272 0.0581919 0.678662 0.0351778 0.623955C0.0121637 0.569247 0.000208773 0.510524 2.71012e-06 0.451173C-0.000203353 0.391823 0.0113435 0.333018 0.0339772 0.278152C0.0566109 0.223286 0.0898834 0.173444 0.131876 0.131501C0.173868 0.0895583 0.223749 0.0563443 0.278642 0.0337753C0.333535 0.0112063 0.392353 -0.000271149 0.451703 4.86231e-06C0.511054 0.000280873 0.569763 0.0123049 0.624443 0.0353835C0.679123 0.0584621 0.728693 0.0921385 0.770294 0.13447L2.99962 2.36455L5.2297 0.13447C5.2713 0.0921385 5.32087 0.0584621 5.37555 0.0353835C5.43023 0.0123049 5.48894 0.000280873 5.54829 4.86231e-06C5.60764 -0.000271149 5.66646 0.0112063 5.72135 0.0337753C5.77624 0.0563443 5.82613 0.0895583 5.86812 0.131501C5.91011 0.173444 5.94338 0.223286 5.96602 0.278152C5.98865 0.333018 6.0002 0.391823 5.99999 0.451173C5.99978 0.510524 5.98783 0.569247 5.96482 0.623955C5.9418 0.678662 5.90818 0.728272 5.8659 0.769922L3.63507 3L5.86515 5.23008C5.90743 5.27173 5.94105 5.32134 5.96407 5.37605C5.98708 5.43075 5.99904 5.48948 5.99924 5.54883C5.99945 5.60818 5.9879 5.66698 5.96527 5.72185C5.94263 5.77671 5.90936 5.82656 5.86737 5.8685C5.82538 5.91044 5.7755 5.94366 5.7206 5.96622C5.66571 5.98879 5.60689 6.00027 5.54754 6C5.48819 5.99972 5.42948 5.9877 5.3748 5.96462C5.32012 5.94154 5.27055 5.90786 5.22895 5.86553L2.99962 3.63545L0.769545 5.86553C0.685044 5.94877 0.571075 5.99524 0.452464 5.99483C0.333852 5.99442 0.220209 5.94715 0.136288 5.86333C0.0523672 5.77951 0.00496864 5.66592 0.00441704 5.54731C0.00386544 5.4287 0.0502055 5.31468 0.133343 5.23008H0.134092Z"
              fill="white"
            />
          </svg>
        </SvgIcon>
      </Box>
    </Stack>
  );

  const WarningIcon = () => (
    <Stack>
      <Box className="warning-icon">
        <SvgIcon>
          <svg width="3" height="10" viewBox="0 0 3 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.46307 0.272727L2.35227 6.49006H1.17188L1.06108 0.272727H2.46307ZM1.7642 9.08097C1.52841 9.08097 1.3267 8.99858 1.15909 8.83381C0.991477 8.66619 0.909091 8.46449 0.911932 8.22869C0.909091 7.99574 0.991477 7.79687 1.15909 7.6321C1.3267 7.46449 1.52841 7.38068 1.7642 7.38068C1.99432 7.38068 2.19318 7.46449 2.3608 7.6321C2.52841 7.79687 2.61364 7.99574 2.61648 8.22869C2.61364 8.38494 2.57244 8.52841 2.4929 8.65909C2.41619 8.78693 2.31392 8.8892 2.18608 8.96591C2.05824 9.04261 1.91761 9.08097 1.7642 9.08097Z" fill="white" />
          </svg>

        </SvgIcon>
      </Box>
    </Stack>
  );

  const compareQualifications = (jobQualifications, candidateQualifications) => {
    if (!jobQualifications || !candidateQualifications) return 0;
    const jobQualValues = [...new Set(jobQualifications.map(value => value.toLowerCase()))];
    const candidateQualValues = [...new Set(candidateQualifications.map((qual) => qual.toLowerCase()))];
    const matchedQualifications = [...new Set(candidateQualValues.filter((qual) => jobQualValues.includes(qual)))];
    if (matchedQualifications?.length === 0) {
      return 0;
    } else if (matchedQualifications?.length === jobQualifications?.length) {
      return 4;
    } else {
      return 2;
    }
  };

  const handleMatchingSkills = (workHistory, certificate, education, jobSkills, coreSkills) => {
    if (!workHistory || !Array.isArray(workHistory)) {
      console.error('Invalid work history provided.');
      return [];
    }
    if (!jobSkills || !Array.isArray(jobSkills)) {
      console.error('Invalid job skills provided.');
      return [];
    }
    let candidateSkills = [];
    workHistory?.forEach((history) => {
      if (history?.skills && Array.isArray(history?.skills)) {
        candidateSkills.push(...history.skills.map((skill) => skill.toLowerCase()));
      } else {
        console.warn('Invalid or missing skills property in work history entry:', history);
      }
    });
    certificate?.forEach((history) => {
      if (history?.skills && Array.isArray(history?.skills)) {
        candidateSkills.push(...history.skills.map((skill) => skill.toLowerCase()));
      } else {
        console.warn('Invalid or missing skills property in work history entry:', history);
      }
    });
    education?.forEach((history) => {
      if (history?.skills && Array.isArray(history?.skills)) {
        candidateSkills.push(...history.skills.map((skill) => skill.toLowerCase()));
      } else {
        console.warn('Invalid or missing skills property in work history entry:', history);
      }
    });
    const uniqueCandidateSkills = [...new Set(candidateSkills)];
    const matchingSkills = [];
    const nonMatchingSkills = [];

    jobSkills?.forEach((skill) => {
      const lowerCaseSkill = skill.toLowerCase();
      if (uniqueCandidateSkills?.includes(lowerCaseSkill)) {
        matchingSkills.push(skill);
      } else {
        nonMatchingSkills.push({ skill, is_core: coreSkills?.includes(skill) });
      }
    });
    let coreMatchSkill = [];

    coreSkills?.forEach((skill) => {
      const lowerCaseSkill = skill.toLowerCase();
      if (uniqueCandidateSkills?.includes(lowerCaseSkill)) {
        coreMatchSkill.push(skill);
      }
    });
    setCoreMatchSkills(coreMatchSkill);
    setNonMatchingSkills(nonMatchingSkills);

    return matchingSkills;
  };

  const isIndustrySectorMatched = (workHistory, targetIndustrySector) => {
    if (!workHistory || !targetIndustrySector) return false;
    return workHistory?.some(
      (work) => work?.organization_details?.industry_sector === targetIndustrySector
    ) || false;
  };

  const matchIcon = (job, candidate, title) => {
    const isMatchFunc = () => {
      if (title === 'Notice Period') {
        const matched = matchNoticePeriod(job, candidate);
        return matched;
      }
      if (title === 'Job Location') {
        const location = candidate?.join(', ');
        const matched = matchLocation(job, location);
        return candidate?.includes('Any') || job?.includes('Any') || matched;
      }
      if (job === candidate) return true;
      if (Array.isArray(candidate)) {
        return candidate?.includes('Any') || job?.includes('Any') || candidate?.includes(job);
      }
      if (!isNaN(job) && !isNaN(candidate)) {
        return Number(job) === Number(candidate);
      }
      return false;
    };
    if (isMatchFunc()) {
      return (
        <Box>
          <Stack direction="row" alignItems={'center'} spacing={0.5}>
            <Box className="match-icon">
              <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                    fill="white"
                  />
                </svg>
              </SvgIcon>
            </Box>
            <Typography className="pref-match-title">{title || 'NA'}</Typography>
          </Stack>
        </Box>
      );
    } else {
      return (
        <Box>
          <Stack direction="row" alignItems={'center'} spacing={0.5}>
            <Box className="error-match-icon">
              <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <path
                    d="M0.134092 5.23008L2.36417 3L0.134092 0.769922C0.0918098 0.728272 0.0581919 0.678662 0.0351778 0.623955C0.0121637 0.569247 0.000208773 0.510524 2.71012e-06 0.451173C-0.000203353 0.391823 0.0113435 0.333018 0.0339772 0.278152C0.0566109 0.223286 0.0898834 0.173444 0.131876 0.131501C0.173868 0.0895583 0.223749 0.0563443 0.278642 0.0337753C0.333535 0.0112063 0.392353 -0.000271149 0.451703 4.86231e-06C0.511054 0.000280873 0.569763 0.0123049 0.624443 0.0353835C0.679123 0.0584621 0.728693 0.0921385 0.770294 0.13447L2.99962 2.36455L5.2297 0.13447C5.2713 0.0921385 5.32087 0.0584621 5.37555 0.0353835C5.43023 0.0123049 5.48894 0.000280873 5.54829 4.86231e-06C5.60764 -0.000271149 5.66646 0.0112063 5.72135 0.0337753C5.77624 0.0563443 5.82613 0.0895583 5.86812 0.131501C5.91011 0.173444 5.94338 0.223286 5.96602 0.278152C5.98865 0.333018 6.0002 0.391823 5.99999 0.451173C5.99978 0.510524 5.98783 0.569247 5.96482 0.623955C5.9418 0.678662 5.90818 0.728272 5.8659 0.769922L3.63507 3L5.86515 5.23008C5.90743 5.27173 5.94105 5.32134 5.96407 5.37605C5.98708 5.43075 5.99904 5.48948 5.99924 5.54883C5.99945 5.60818 5.9879 5.66698 5.96527 5.72185C5.94263 5.77671 5.90936 5.82656 5.86737 5.8685C5.82538 5.91044 5.7755 5.94366 5.7206 5.96622C5.66571 5.98879 5.60689 6.00027 5.54754 6C5.48819 5.99972 5.42948 5.9877 5.3748 5.96462C5.32012 5.94154 5.27055 5.90786 5.22895 5.86553L2.99962 3.63545L0.769545 5.86553C0.685044 5.94877 0.571075 5.99524 0.452464 5.99483C0.333852 5.99442 0.220209 5.94715 0.136288 5.86333C0.0523672 5.77951 0.00496864 5.66592 0.00441704 5.54731C0.00386544 5.4287 0.0502055 5.31468 0.133343 5.23008H0.134092Z"
                    fill="white"
                  />
                </svg>
              </SvgIcon>
            </Box>
            <Typography className="pref-match-title">{title || 'NA'}</Typography>
          </Stack>
        </Box>
      );
    }
  };

  const matchNoticePeriod = (job, candidate) => {
    if (job === 'No preference') {
      return true;
    }
    if (job === '0 days (immediate)') {
      if (candidate === '0 days (immediate)') {
        return true
      }
      return false;
    } else if (candidate === '0 days (immediate)') {
      return true;
    } else if (job === '90+') {
      if (candidate === '90+') {
        return true
      }
      return false;
    } else if (candidate === '90+') {
      return false;
    } else {
      let jN = parseInt(job);
      let cN = parseInt(candidate);
      return (cN <= jN ? true : false);
    }
  };

  const matchLocation = (job, candidate) => {
    const locations = candidate?.split(', ');
    let matchFound = false;
    let matchedCluster = [];

    locations?.forEach((item) => {
      matchedCluster = [...matchedCluster, clusteredData.find(cluster => cluster.name === item)];
    });
    let matchedValueCluster = [];
    matchedCluster?.forEach((item) => {
      matchedValueCluster = [...matchedValueCluster, item?.value];
    });

    if (matchedCluster) {
      matchedCluster = matchedValueCluster?.join(', ');
      matchFound = matchedCluster?.includes(job);
      if (matchFound) return true;
    }
    matchFound = candidate?.includes(job);

    return matchFound;
  };

  const handleUpdatePreference = async (preference) => {
    await loadDetails();
  };

  const handleSkillsUpdate = async (skills) => {
    await loadDetails();
  };

  const handleNonMatchingSkills = async () => {
    await loadDetails();
  };

  const handleApplyJob = () => {
    if (!user?.id) setOpenLogin(true);
    else {
      if (user?.stages < 4) {
        handleSaveJob();
        navigate('/');
      } else {
        if (job?.external_link) setOpenExternalJob(true);
        else setOpenChatBotDrawer(true);
      }
    }
  }

  const handleCloseExternalJobDialog = () => {
    setOpenExternalJob(false);
  };
  const handleExternalJobStatus = () => {
    setOpenExternalJobStatus(true);
  };
  const handleCloseExternalJobStatusDialog = (msg) => {
    setOpenExternalJobStatus(false);
    if (msg === "applied") {
      loadDetails();
    }
  };

  const handleDownloadResume = () => {
    const dataForExternalJob = { job: job, candidateDetails: candidateDetails, matchingSkills: matchingSkills, tab: '2' };
    setJobApply({ job: dataForExternalJob, tab: '4', tabForRedirectBack: '2' });
    handleCloseExternalJobDialog();
    navigate('/');
  };

  const handleSaveJob = async () => {
    try {
      const response = await saveJobApplication({ jobId: job?._id, companyId: job?.company_id, type: 'saved' });
      if (response?.success && response?.data != null) {
        enqueueSnackbar(response?.message, { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to save the application. Please try again.', { variant: 'error' });
      }
    } catch (e) {
      console.error('Error saving for later:', e);
    }
  }

  const handleNavigation = (jobData, jobStatus, jobApplication) => {
    if (!user?.stages || user?.stages < 4) {
      handleSaveJob();
      navigate('/');
    } else {
      const isSavedOrNull = jobStatus === 'saved' || jobStatus === null;
      setJobApply({
        job: isSavedOrNull ? jobData : jobApplication,
        openChatBot: isSavedOrNull,
        resume: null,
        answer: null,
        tab: isSavedOrNull ? '2' : '3',
        activeTab: 'applied-jobs',
        filter: job?._id,
      });
    }
  }

  const recommendedChip = (score) => {
    if (score > 3) {
      return (
        <Chip
          className="chip-job-matching chip-suitable"
          avatar={<Avatar alt="Natacha" src={suitableIcon} />}
          label="Highly Suitable Job For You"
          size="small"
        />
      );
    } else if (score < 2) {
      return (
        <Chip
          className="chip-job-matching chip-not-matching"
          avatar={<Avatar alt="Natacha" src={JobNotMatchingIcon} />}
          label="Job Not Matching Your Profile"
          size="small"
        />
      );
    } else {
      return (
        <Chip
          className="chip-job-matching chip-mod-suitable"
          avatar={<Avatar alt="Natacha" src={ModSuitableIcon} />}
          label="Moderately Suitable For You"
          size="small"
        />
      );
    }
  };

  const handleSaveForLater = async () => {
    try {
      setDataSubmitted(false);
      const response = await saveJobApplication({ jobId: job?._id, companyId: job?.company_id, type: 'saved' });
      if (response?.success && response?.data != null) {
        enqueueSnackbar('Successfully saved for later.', { variant: 'success' });
        loadDetails();
      } else {
        enqueueSnackbar('Failed to save the application. Please try again.', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error saving for later:', error);
    } finally {
      setDataSubmitted(true);
    }
  };
  const handleOpenAutoUnsaveDialog = () => {
    setAutoUnsaveDialogOpen(true);
  };

  const handleCloseAutoUnsaveDialog = () => {
    setAutoUnsaveDialogOpen(false);
  };

  const handleUnsaveJob = async () => {
    try {
      const response = await saveJobApplication({ jobId: job?._id, companyId: job?.company_id, type: 'unsaved' });
      if (response?.success) {
        loadDetails();
        handleCloseAutoUnsaveDialog();
      } else {
        enqueueSnackbar('Failed to Unsave the application. Please try again.', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error unsaving for later:', error);
    }
  };

  const handleCloseResume = () => {
    setViewResume(false);
  };

  if (loading) {
    return (
      <Box className="job-full-details"><Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
        <CircularProgress size={24} />
      </Stack>
      </Box>
    );
  }

  return (
    <Box className="job-full-details" >
      {/* header block */}
      <Box className="job-full-details-header">
        <Box className="header-top">
          <Stack direction="row" justifyContent={'space-between'} spacing={2}>
            <Box>
              <Typography className="title">{job?.title || 'NA'}</Typography>
              <Stack direction="row" alignItems={'center'} spacing={1}>
                {job?.is_company_name_confidential ? <Typography className="company-name">Company Name - Confidential</Typography> : <Typography className="company-name">{job?.company || 'NA'}</Typography>}
                <Divider orientation="vertical" variant="middle" flexItem />
                <Typography className="city-name">{job?.location || 'N/A'}</Typography>
              </Stack>
            </Box>
            <Stack direction="row" spacing={3}>
              {status === 'saved' &&
                <Button sx={{ width: '150px' }}
                  variant="outlined"
                  size="large"
                  className="saved-btn"
                  onClick={handleOpenAutoUnsaveDialog}
                  startIcon={
                    <SvgIcon>
                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                          fill="white"
                        />
                      </svg>
                    </SvgIcon>
                  }
                >
                  Job Saved
                </Button>
              }
              {status === null &&
                <Button sx={{ width: '150px' }}
                  variant="outlined"
                  size="large"
                  className="save-btn"
                  startIcon={<AddCircleIcon fontSize="large" />}
                  onClick={handleSaveForLater}
                  disabled={!dataSubmitted}
                >
                  {dataSubmitted ? 'Save Job' : 'Saving...'}
                </Button>
              }
              {(status === 'saved' || status === null || status === 'in-progress') ? (
                <Button sx={{ width: '150px' }}
                  variant="contained"
                  size="large"
                  className={job?.external_link ? "external-apply-now-btn" : "apply-now-btn"}
                  endIcon={job?.external_link ? < SvgIcon >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5413 0.0581763C15.7159 0.130897 15.8613 0.276338 15.934 0.450867H15.9413C15.9776 0.538131 15.9995 0.632668 15.9995 0.727205V5.03953C15.9995 5.43949 15.6722 5.76673 15.2723 5.76673C14.8723 5.76673 14.5451 5.43949 14.5451 5.03953V2.47977L8.51653 8.50829C8.37109 8.64646 8.18929 8.71918 8.00021 8.71918C7.81114 8.71918 7.62934 8.65373 7.4839 8.50829C7.20029 8.22468 7.20029 7.76654 7.4839 7.48293L13.5124 1.45441H10.9527C10.5527 1.45441 10.2255 1.12717 10.2255 0.727205C10.2255 0.327242 10.5527 0 10.9527 0H15.265C15.3595 0 15.4541 0.0218161 15.5413 0.0581763ZM14.5375 8.0013C14.5375 7.60134 14.8648 7.27409 15.2648 7.27409C15.6647 7.27409 15.992 7.60134 15.992 8.0013V13.0554C15.992 14.677 14.6684 16.0005 13.0468 16.0005H2.94591C1.32424 16.0005 0.000732422 14.677 0.000732422 13.0554V2.9545C0.000732422 1.33283 1.32424 0.00932173 2.94591 0.00932173H7.99998C8.39994 0.00932173 8.72719 0.336564 8.72719 0.736526C8.72719 1.13649 8.39994 1.46373 7.99998 1.46373H2.94591C2.12417 1.46373 1.45514 2.13276 1.45514 2.9545V13.0554C1.45514 13.8771 2.12417 14.5461 2.94591 14.5461H13.0468C13.8685 14.5461 14.5375 13.8771 14.5375 13.0554V8.0013Z" fill="white" />
                    </svg>
                  </SvgIcon> : null}
                  onClick={handleApplyJob}
                >
                  Apply Now
                </Button>
              ) : (
                <Button sx={{ width: '150px' }}
                  variant="contained"
                  size="large"
                  className={"apply-now-btn"}
                  disabled
                >Applied</Button>
              )}
            </Stack>
          </Stack>
        </Box>
        <Box className="header-bottom my-internal-jobs">
          <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }} spacing={2}>
            <ToggleButtonGroup
              className="job-desc-toggle-btn"
              color="primary"
              value={alignment}
              exclusive
              onChange={handleToggleChange}
              aria-label="Platform"
            >
              <ToggleButton value="job-overview">Job Overview</ToggleButton>
              <ToggleButton value="job-desc">Job Responsibilities</ToggleButton>
              {application && application?.job_id?.question && application?.job_id?.question?.length > 0 && (application?.answers || application?.resume_data) && application?.status !== 'saved' && (
                <ToggleButton value="job-application">Job Application</ToggleButton>
              )}
            </ToggleButtonGroup>
          </Stack>
        </Box>
      </Box>

      {/* job overview block */}
      <Box ref={jobFullOverviewRef} className="job-full-overview" sx={{ height: 'calc(100vh - 32vh)!important' }}>
        <Box ref={jobOverviewRef} id="job-overview">
          <Box className="job-overview-wrapper" mb={4}>
            <Typography className="title">Job Overview</Typography>
            <Typography className="about-post-info" style={{ textAlign: 'justify', marginBottom: 15 }}>
              {job?.company_overview}
            </Typography>
            <Typography className="about-post-info" style={{ textAlign: 'justify' }}>
              {job?.job_overview}
            </Typography>
          </Box>

          <Card variant="outlined" className="requirements-match-card" sx={{ marginBottom: 3 }}>
            <CardContent>
              {(!application || application?.status === 'saved') && recommendedChip(job?.tj_score)}
              <Typography className="title">Requirements Match</Typography>
              <Typography className="info-text">
                Matched against your educational background & work experience
              </Typography>
              <Box className="requirements-match" mt={1.5}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Box>
                      <Stack direction="row" align spacing={0.5}>
                        {application && application?.status !== 'saved' ?
                          requirementsMatch(job?.qualifications, application?.requirements_matched?.qualifications || [], 'Qualification') :
                          requirementsMatch(job?.qualifications, candidateDetails?.qualification || [], 'Qualification')}
                        <Box>
                          <Typography className="req-title" sx={{ marginTop: '-2px' }}>Required Education</Typography>
                          <Typography className="req-text">
                            {job?.qualifications?.join(' | ')}
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Stack direction="row" spacing={0.5}>
                        {application && application?.status !== 'saved' ?
                          requirementsMatch(null || [], application?.candidate_score?.experience_level || [], 'Experience')
                          : requirementsMatch(null || [], candidateDetails?.rating?.experienceMatchRating || [], 'Experience')}
                        <Box>
                          <Typography className="req-title" sx={{ marginTop: '-2px' }}>Required Work Experience</Typography>
                          <Typography className="req-text">
                            {job?.experience ? `${job?.experience} Years` : 'N/A'}</Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Stack direction="row" spacing={0.5}>
                        {requirementsMatch(job.key_skills?.length, matchingSkills?.length, 'Skills')}
                        <Box>
                          <Typography className="req-title" sx={{ marginTop: '-2px' }}>Required Skills</Typography>
                          <Typography className="req-text">
                            {coreMatchSkills.length > 0 && (<>{coreMatchSkills.length || 0}/{job?.core_skills ? job?.core_skills.length : 0} Core<br /></>)}
                            {matchingSkills ? matchingSkills.length : 0}/{job?.key_skills ? job?.key_skills.length : 0} Total
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Stack direction="row" spacing={0.5}>
                        {industrySector ? (
                          <Stack>
                            <Box className="match-icon">
                              <SvgIcon>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="8"
                                  height="6"
                                  viewBox="0 0 8 6"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                                    fill="white"
                                  />
                                </svg>
                              </SvgIcon>
                            </Box>
                          </Stack>
                        ) : (
                          <Stack>
                            <Box className="error-match-icon">
                              <SvgIcon>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                  <path
                                    d="M0.134092 5.23008L2.36417 3L0.134092 0.769922C0.0918098 0.728272 0.0581919 0.678662 0.0351778 0.623955C0.0121637 0.569247 0.000208773 0.510524 2.71012e-06 0.451173C-0.000203353 0.391823 0.0113435 0.333018 0.0339772 0.278152C0.0566109 0.223286 0.0898834 0.173444 0.131876 0.131501C0.173868 0.0895583 0.223749 0.0563443 0.278642 0.0337753C0.333535 0.0112063 0.392353 -0.000271149 0.451703 4.86231e-06C0.511054 0.000280873 0.569763 0.0123049 0.624443 0.0353835C0.679123 0.0584621 0.728693 0.0921385 0.770294 0.13447L2.99962 2.36455L5.2297 0.13447C5.2713 0.0921385 5.32087 0.0584621 5.37555 0.0353835C5.43023 0.0123049 5.48894 0.000280873 5.54829 4.86231e-06C5.60764 -0.000271149 5.66646 0.0112063 5.72135 0.0337753C5.77624 0.0563443 5.82613 0.0895583 5.86812 0.131501C5.91011 0.173444 5.94338 0.223286 5.96602 0.278152C5.98865 0.333018 6.0002 0.391823 5.99999 0.451173C5.99978 0.510524 5.98783 0.569247 5.96482 0.623955C5.9418 0.678662 5.90818 0.728272 5.8659 0.769922L3.63507 3L5.86515 5.23008C5.90743 5.27173 5.94105 5.32134 5.96407 5.37605C5.98708 5.43075 5.99904 5.48948 5.99924 5.54883C5.99945 5.60818 5.9879 5.66698 5.96527 5.72185C5.94263 5.77671 5.90936 5.82656 5.86737 5.8685C5.82538 5.91044 5.7755 5.94366 5.7206 5.96622C5.66571 5.98879 5.60689 6.00027 5.54754 6C5.48819 5.99972 5.42948 5.9877 5.3748 5.96462C5.32012 5.94154 5.27055 5.90786 5.22895 5.86553L2.99962 3.63545L0.769545 5.86553C0.685044 5.94877 0.571075 5.99524 0.452464 5.99483C0.333852 5.99442 0.220209 5.94715 0.136288 5.86333C0.0523672 5.77951 0.00496864 5.66592 0.00441704 5.54731C0.00386544 5.4287 0.0502055 5.31468 0.133343 5.23008H0.134092Z"
                                    fill="white"
                                  />
                                </svg>
                              </SvgIcon>
                            </Box>
                          </Stack>
                        )}
                        <Box>
                          <Typography className="req-title" sx={{ marginTop: '-2px' }}>Industry Sector</Typography>
                          <Typography className="req-text">{job?.industry_sector || 'N/A'}</Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />

              <Box className="preferences-match">
                <Typography className="title">Preferences Match</Typography>
                <Typography className="pref-info-text" component="p">
                  Matched against your defined job preferences.{' '}
                  {(status === 'saved' || status === null) && <Link href="#" underline="none" onClick={toggleDrawer(true)}>click here to update preferences</Link>}
                </Typography>
                <Box mt={2}>
                  <Stack spacing={1.5}>
                    <Grid container>
                      <Grid item xs={3}>
                        {matchIcon(
                          job?.salary,
                          (status === 'saved' || status === null) ?
                            candidateDetails?.user?.candidate?.job_preferences?.expected_ctc :
                            application?.requirements_matched?.salary || [],
                          requirementMatchItem[0]
                        )}
                      </Grid>
                      <Grid item xs={9}>
                        <Box>
                          <Stack direction="row" alignItems={'normal'} spacing={0.7}>
                            {job?.is_compensation_confidential ? <Typography className="pref-match-info">Not Disclosed</Typography> : <>
                              <Typography className="pref-match-info">
                                ₹{formatNumber(job?.salary)}
                                {job?.compensation && `(${job?.compensation})`}
                              </Typography>
                              {(status === 'saved' || status === null) && (candidateDetails && Number(job?.salary) !== Number(candidateDetails?.user?.candidate?.job_preferences?.expected_ctc)) && (
                                <Typography className="pref-match-info notice-period-error">
                                  You've mentioned ₹{formatNumber(candidateDetails?.user?.candidate?.job_preferences?.expected_ctc)}
                                </Typography>
                              )}</>
                            }
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={3}>
                        {matchIcon(
                          job?.location,
                          (status === 'saved' || status === null) ?
                            candidateDetails?.user?.candidate?.job_preferences?.prefered_location || []
                            : application?.requirements_matched?.location || [],
                          requirementMatchItem[1]
                        )}
                      </Grid>
                      <Grid item xs={9}>
                        <Box>
                          <Stack direction="row" alignItems={'normal'} spacing={0.7}>
                            <Typography className="pref-match-info">{job?.location || 'NA'}</Typography>
                            {!candidateDetails || candidateDetails?.user?.candidate?.job_preferences?.prefered_location?.join(', ')?.includes('Any') || job?.location?.includes('Any') ||
                              (matchLocation(job?.location, candidateDetails?.user?.candidate?.job_preferences?.prefered_location?.join(', '))) ? (
                              ''
                            ) : (
                              <Typography className="pref-match-info notice-period-error">
                                {(status === 'saved' || status === null) && <>
                                  (You've mentioned{' '}
                                  {candidateDetails?.user?.candidate?.job_preferences?.prefered_location?.join(', ')})</>}
                              </Typography>
                            )}
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={3}>
                        {matchIcon(
                          job?.work_mode,
                          (status === 'saved' || status === null) ?
                            candidateDetails?.user?.candidate?.job_preferences?.work_format || []
                            : application?.requirements_matched?.job_format || [],
                          requirementMatchItem[2]
                        )}
                      </Grid>
                      <Grid item xs={9}>
                        <Box>
                          <Stack direction="row" alignItems={'normal'} spacing={0.7}>
                            <Typography className="pref-match-info">{job?.work_mode || 'NA'}</Typography>
                            {!candidateDetails || candidateDetails?.user?.candidate?.job_preferences?.work_format?.includes('Any') || job?.work_mode?.includes('Any') ||
                              candidateDetails?.user?.candidate?.job_preferences?.work_format?.includes(job?.work_mode) ? (
                              ''
                            ) : (
                              <Typography className="pref-match-info notice-period-error">
                                {(status === 'saved' || status === null) && <>
                                  (You've mentioned a {candidateDetails?.user?.candidate?.job_preferences?.work_format?.join(', ')})</>}
                              </Typography>
                            )}
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={3}>
                        {matchIcon(
                          job?.job_type,
                          (status === 'saved' || status === null) ?
                            candidateDetails?.user?.candidate?.job_preferences?.job_type || []
                            : application?.requirements_matched?.job_type,
                          requirementMatchItem[3]
                        )}
                      </Grid>
                      <Grid item xs={9}>
                        <Box>
                          <Stack direction="row" alignItems={'normal'} spacing={0.7}>
                            <Typography className="pref-match-info">{job?.job_type || 'NA'}</Typography>
                            {!candidateDetails || candidateDetails?.user?.candidate?.job_preferences?.job_type?.includes(job?.job_type) ? (
                              ''
                            ) : (
                              <Typography className="pref-match-info notice-period-error">
                                {(status === 'saved' || status === null) && <>
                                  (You've mentioned a{''}
                                  {candidateDetails?.user?.candidate?.job_preferences?.job_type?.join(', ')})</>}
                              </Typography>
                            )}
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={3}>
                        {matchIcon(
                          job?.job_shift,
                          (status === 'saved' || status === null) ?
                            candidateDetails?.user?.candidate?.job_preferences?.preferred_job_shifts || []
                            : application?.requirements_matched?.job_shift,
                          requirementMatchItem[4]
                        )}
                      </Grid>
                      <Grid item xs={9}>
                        <Box>
                          <Stack direction="row" alignItems={'normal'} spacing={0.7}>
                            <Typography className="pref-match-info">
                              {job?.job_shift ? `${job?.job_shift}` : 'NA'}
                            </Typography>
                            {!candidateDetails ||
                              candidateDetails?.user?.candidate?.job_preferences?.preferred_job_shifts?.includes('Any') ||
                              job?.job_shift?.includes('Any') ||
                              candidateDetails?.user?.candidate?.job_preferences?.preferred_job_shifts?.includes(job?.job_shift) ? (
                              ''
                            ) : (
                              <Typography className="pref-match-info notice-period-error">
                                {(status === 'saved' || status === null) && <>
                                  (You've mentioned{' '} {candidateDetails?.user?.candidate?.job_preferences?.preferred_job_shifts?.join(', ')} as your preferred shift)</>}
                              </Typography>
                            )}
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={3}>
                        {matchIcon(
                          job?.notice_period,
                          (status === 'saved' || status === null) ?
                            candidateDetails?.user?.candidate?.job_preferences?.notice_period || []
                            : application?.requirements_matched?.notice_period,
                          requirementMatchItem[5]
                        )}
                      </Grid>
                      <Grid item xs={9}>
                        <Box>
                          <Stack direction="row" alignItems={'normal'} spacing={0.7}>
                            <Typography className="pref-match-info">{job?.notice_period ? ((job?.notice_period === '0 days (immediate)' || job?.notice_period === 'No preference') ? `${job?.notice_period}` : `Upto ${job?.notice_period} days`) : 'NA'}</Typography>
                            {candidateDetails &&
                              !matchNoticePeriod(job?.notice_period, candidateDetails?.user?.candidate?.job_preferences?.notice_period) && (
                                <Typography className="pref-match-info notice-period-error">
                                  {(status === 'saved' || status === null) && <>
                                    (You've mentioned a {candidateDetails?.user?.candidate?.job_preferences?.notice_period} day notice)</>}
                                </Typography>
                              )}

                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
              </Box>
            </CardContent>
          </Card>

          <Card variant="outlined" className="skills-match-card" sx={{ marginBottom: 3 }}>
            <CardContent>
              <Box>
                <Stack direction="row" justifyContent={'space-between'} spacing={2}>
                  <Box>
                    <Typography className="title">Skills Match Analysis</Typography>
                  </Box>
                  <Box>
                  </Box>
                </Stack>
                <Typography className="info-text">
                  You posses {matchingSkills?.length || 0} out {job?.key_skills?.length || 0} required skills (You
                  skills can be updated within the 'My Profile' section). {''}
                  {(status === 'saved' || status === null) && <Link href="#" underline="none" className="update-skills-link" onClick={toggleSkillsDrawer(true)}>
                    click here to update skills
                  </Link>}
                </Typography>
              </Box>

              <Stack direction="row" flexWrap="wrap" sx={{ marginTop: '1rem', marginBottom: '1rem' }} >
                {job?.key_skills
                  ? job?.key_skills.map((skill, index) => (
                    <Chip
                      key={index}
                      icon={
                        matchingSkills?.includes(skill) ? (
                          <Box className="match-icon">
                            <SvgIcon>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="8"
                                height="6"
                                viewBox="0 0 8 6"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                                  fill="white"
                                />
                              </svg>
                            </SvgIcon>
                          </Box>
                        ) : (
                          <Box className="error-match-icon">
                            <SvgIcon>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="6"
                                viewBox="0 0 6 6"
                                fill="none"
                              >
                                <path
                                  d="M0.134092 5.23008L2.36417 3L0.134092 0.769922C0.0918098 0.728272 0.0581919 0.678662 0.0351778 0.623955C0.0121637 0.569247 0.000208773 0.510524 2.71012e-06 0.451173C-0.000203353 0.391823 0.0113435 0.333018 0.0339772 0.278152C0.0566109 0.223286 0.0898834 0.173444 0.131876 0.131501C0.173868 0.0895583 0.223749 0.0563443 0.278642 0.0337753C0.333535 0.0112063 0.392353 -0.000271149 0.451703 4.86231e-06C0.511054 0.000280873 0.569763 0.0123049 0.624443 0.0353835C0.679123 0.0584621 0.728693 0.0921385 0.770294 0.13447L2.99962 2.36455L5.2297 0.13447C5.2713 0.0921385 5.32087 0.0584621 5.37555 0.0353835C5.43023 0.0123049 5.48894 0.000280873 5.54829 4.86231e-06C5.60764 -0.000271149 5.66646 0.0112063 5.72135 0.0337753C5.77624 0.0563443 5.82613 0.0895583 5.86812 0.131501C5.91011 0.173444 5.94338 0.223286 5.96602 0.278152C5.98865 0.333018 6.0002 0.391823 5.99999 0.451173C5.99978 0.510524 5.98783 0.569247 5.96482 0.623955C5.9418 0.678662 5.90818 0.728272 5.8659 0.769922L3.63507 3L5.86515 5.23008C5.90743 5.27173 5.94105 5.32134 5.96407 5.37605C5.98708 5.43075 5.99904 5.48948 5.99924 5.54883C5.99945 5.60818 5.9879 5.66698 5.96527 5.72185C5.94263 5.77671 5.90936 5.82656 5.86737 5.8685C5.82538 5.91044 5.7755 5.94366 5.7206 5.96622C5.66571 5.98879 5.60689 6.00027 5.54754 6C5.48819 5.99972 5.42948 5.9877 5.3748 5.96462C5.32012 5.94154 5.27055 5.90786 5.22895 5.86553L2.99962 3.63545L0.769545 5.86553C0.685044 5.94877 0.571075 5.99524 0.452464 5.99483C0.333852 5.99442 0.220209 5.94715 0.136288 5.86333C0.0523672 5.77951 0.00496864 5.66592 0.00441704 5.54731C0.00386544 5.4287 0.0502055 5.31468 0.133343 5.23008H0.134092Z"
                                  fill="white"
                                />
                              </svg>
                            </SvgIcon>
                          </Box>
                        )
                      }
                      label={
                        job?.core_skills?.includes(skill) ? <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                          <span>{skill}</span>
                          <StarIcon className="star-icon" sx={{ color: '#faaf00', padding: '5px', marginLeft: 'auto' }} />
                        </Box> : skill
                      }
                      variant="outlined"
                      disabled={!matchingSkills?.includes(skill)}
                    />
                  ))
                  : 'No Skills'}
              </Stack>
            </CardContent>
          </Card>
        </Box>

        <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />
        <Box sx={{ height: 'calc(100vh - 70px)' }}>
          <Box ref={jobDescRef} id="job-desc" className="job-description">
            <Typography className="title">Job Responsibilities</Typography>
            <Typography className="info-text list-style" >
              <div dangerouslySetInnerHTML={{ __html: job?.description }} />
            </Typography>
          </Box>

          {/* questions */}
          {application?.job_id?.question && application?.job_id?.question?.length > 0 && (application?.answers || application?.resume_data) && application?.status !== 'saved' && (
            <Box>
              <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />
              <Box ref={jobApplicationRef} id="job-application" className="job-application">
                <Typography className="title">Job Application</Typography>
                {application?.job_id?.external_link ? (
                  application?.job_id?.question && application?.job_id?.question.length > 0 && (
                    <>
                      <Typography className="info-text">
                        Your response to the screening questions & resume you applied with
                      </Typography>

                      <List
                        className="job-application-list"
                        sx={{ marginTop: '1rem', paddingTop: 0, paddingLeft: '1.5rem', listStyle: 'decimal' }}
                      >
                        {application?.job_id?.question.map((qus, index) => (
                          <ListItem sx={{ display: 'list-item' }} disablePadding key={index}>
                            <Typography className="questions-text">{qus}</Typography>
                            {/* <Typography className="answer-text">{value}</Typography> */}
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )
                ) : (
                  application?.answers && Object.keys(application?.answers).length > 0 && (
                    <>
                      <Typography className="info-text">
                        Your response to the screening questions & resume you applied with
                      </Typography>

                      <List
                        className="job-application-list"
                        sx={{ marginTop: '1rem', paddingTop: 0, paddingLeft: '1.5rem', listStyle: 'decimal' }}
                      >
                        {Object.entries(application?.answers).map(([key, value]) => (
                          <ListItem sx={{ display: 'list-item' }} disablePadding key={key}>
                            <Typography className="questions-text">{key}</Typography>
                            <Typography className="answer-text">{value}</Typography>
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )
                )}
              </Box>
            </Box>
          )}
          {application && !(application?.job_id?.external_link) && (application?.status !== 'saved' && application?.status !== 'Not Applied') && (
            <Box className="applied-resume-date-info" >
              <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} spacing={2}>
                <Typography className="info-text">
                  You applied with {application?.resume_data?.title} for this job on{' '}
                  {dayjs(application?.applied_at).format('DD MMM, YYYY')}
                </Typography>
                <Link sx={{ cursor: 'pointer' }} onClick={() => setViewResume(true)}>View Resume</Link>
              </Stack>
            </Box>
          )}
        </Box>

      </Box>

      {/* apply now chatboat drawer */}
      <Drawer
        open={openChatBotDrawer}
        className="chat-bot-drawer-dialog"
        anchor={'right'}
        onClose={toggleChatBotDrawer(false)}
      >
        <ChatBotDrawer
          questions={job?.question}
          onClose={toggleChatBotDrawer(false)}
          onSelected={handleSelectedResume}
          tab={'2'}
          data={job}
          candidateDetails={candidateDetails}
          matchingSkills={matchingSkills}
        />
      </Drawer>
      {/* update job preferences drawer */}
      <Drawer open={open} anchor={'right'} onClose={toggleDrawer(false)}>
        <JopApplicationDrawer
          onClose={toggleDrawer(false)}
          data={candidateDetails?.user?.candidate?.job_preferences}
          onUpdatePreference={handleUpdatePreference}
        />
      </Drawer>
      {/* update relevent skills drawer */}
      <Drawer open={openSkillsDrawer} anchor={'right'} onClose={toggleSkillsDrawer(false)}>
        <UpdateReleventSkillsDrawer
          onClose={toggleSkillsDrawer(false)}
          workExperience={candidateDetails?.workHistory}
          education={candidateDetails?.educations}
          certificate={candidateDetails?.certificate}
          skillsUpdate={handleSkillsUpdate}
          nonMatchingSkills={nonMatchingSkills}
          matchingSkills={matchingSkills}
          setNonMatchingSkills={handleNonMatchingSkills}
        />
      </Drawer>
      {/* unsaved job */}
      <Dialog
        open={autoUnsaveDialogOpen}
        onClose={handleCloseAutoUnsaveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="action-dialog-card external-list-dialog"
      > <DialogContent>
          <UnsaveCard onClose={handleCloseAutoUnsaveDialog} onUnsave={handleUnsaveJob} title={job?.title} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openExternalJob}
        onClose={handleCloseExternalJobDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="action-dialog-card external-list-dialog"
      > <DialogContent>
          <ExternalJob onClose={handleCloseExternalJobDialog} externalJobStatus={handleExternalJobStatus} downloadResume={handleDownloadResume} candidateDetails={candidateDetails} matchingSkills={matchingSkills} job={job} tab={'2'} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openExternalJobStatus}
        onClose={handleCloseExternalJobStatusDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="action-dialog-card external-list-dialog"
      > <DialogContent>
          <ExternalJobStatus onClose={handleCloseExternalJobStatusDialog} job={job} tab={'2'} candidateDetails={candidateDetails} matchingSkills={matchingSkills} />
        </DialogContent>
      </Dialog>

      {/* resume preview */}
      {application && application?.resume_data ? (
      <Dialog
        open={viewResume}
        onClose={handleCloseResume}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="full-view-resume-dialog"
      >
        <ResumeFullView
          summary={application?.resume_data ? application?.resume_data?.summary : resume?.summary}
          educationData={application?.resume_data ? application?.resume_data?.educations : resume?.educations}
          certificateData={application?.resume_data ? application?.resume_data?.certificates : resume?.certificates}
          workExperienceData={application?.resume_data ? application?.resume_data?.work_history : resume?.work_history}
          customSection={application?.resume_data ? application?.resume_data?.customSection : resume?.customSection}
          financeTechologiesData={
            application?.resume_data ? application?.resume_data?.financeTechologiesData : resume?.financeTechologiesData
          }
        />
      </Dialog>
      ) : ''}
    </Box>
  )
}

export default JobDescription
