import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'components/snackbar';
import { Box, Button, Card, CardContent, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'


const ForgotPassword = ({ onChange }) => {
    const navigate = useNavigate();
    const [check, setCheck] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const onSubmit = () => {
        if (check === 'OTP') {
            navigate('/forgot-password', { state: { method: 'otp' } })
            onChange(true);
        } else if (check === 'RESET') {
            navigate('/forgot-password', { state: { method: 'link' } })
            onChange(true);
        } else {
            enqueueSnackbar("Please select atleast one option", { variant: 'error' })
        }
    }

    return (
        <Card variant="outlined">
            <CardContent>

                <Box mb={3}>
                    <Typography className="title">Forgot Password?</Typography>
                    <Typography className="info-text">Don't worry! You can login using either of the 2 options below</Typography>
                </Box>

                <Box sx={{ padding: "0 10px" }}>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="radio-buttons-group"
                        >
                            <FormControlLabel
                                value="OTP"
                                control={<Radio />}
                                label={<Box>
                                    <Typography className="option-title">One time login with OTP</Typography>
                                    <Typography className="option-text">OTP would be sent to your registered WhatsApp number and Email Id</Typography>
                                </Box>}
                                sx={{ marginBottom: "1rem" }}
                                // checked={template === 'Classic'}
                                onChange={(e) => setCheck(e.target.value)}
                            />
                            <FormControlLabel
                                value="RESET"
                                control={<Radio />}
                                label={<Box>
                                    <Typography className="option-title">Reset Password</Typography>
                                    <Typography className="option-text">Password reset link would be shared on your registered WhatsApp number and Email Id</Typography>
                                </Box>}
                                // checked={template === 'Classic'}
                                onChange={(e) => setCheck(e.target.value)}
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>

                <Box mt={3}>
                    <Stack direction="row" justifyContent={'center'} alignItems={'center'} spacing={2}>
                        <Button fullWidth className="cancel-btn" variant="outlined" size="medium" onClick={onChange}>Cancel</Button>
                        <Button fullWidth className="continue-btn" onClick={onSubmit} variant="contained" size="medium">Continue</Button>
                    </Stack>
                </Box>
            </CardContent>
        </Card>
    )
}

export default ForgotPassword
