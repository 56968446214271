import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import SuperchargeCareer from './SuperchargeCareer';

const EmptyStateIcon = `${process.env.REACT_APP_HOMEPAGE}assets/empty-state-icon.png`;

const EmptyState = ({ tab, onChangeTab }) => {
    const titles = {
        'job-market': 'Nothing Yet, But Stay Optimistic!',
        'saved-jobs': 'You Haven’t Saved Any Jobs',
        'applied-jobs': 'No Applications Yet',
    };

    const infoTexts = {
        'job-market': (
            <>Our database is growing every day—keep your profile up to <br />date, and we'll notify you when a job matches your skills.</>
        ),
        'saved-jobs': 'Keep track of roles that suit your career goals by saving jobs. Come back anytime to apply.',
        'applied-jobs': 'Ready to kickstart your career? Browse through available jobs and send in your applications.',
    };

    const adsTexts = {
        'job-market': 'In the meanwhile, do checkout these relevant courses and events around you.',
        'saved-jobs': 'While you explore jobs, do checkout these relevant courses and events around you.',
        'applied-jobs': 'While you explore jobs, do checkout these relevant courses and events around you.',
    };

    return (
        <Box className="emtpy-state-wrapper" sx={{ paddingTop: tab !== 'job-market' && '0 !important', paddingLeft: tab !== 'job-market' && '20px !important' }}>
            <Box className="scroll-content-wrapper">
                {/* Empty state card */}
                <Box className="empty-state-card" mb={3}>
                    <Stack direction="row" alignItems={'center'} spacing={4}>
                        <Box>
                            <img alt="edit" width="120px" height="120px" sx={{ maxWidth: '100%', height: 'auto' }} src={EmptyStateIcon} />
                        </Box>
                        <Box>
                            <Typography className="title">{titles[tab]}</Typography>
                            <Typography className="info-text">{infoTexts[tab]}</Typography>
                            {tab !== 'job-market' && (<Button variant="contained" className="browse-jobs-btn" onClick={() => onChangeTab()}>Browse Jobs</Button>)}
                        </Box>
                    </Stack>
                </Box>

                {/* Supercharge career */}
                <Box className="empty-state-ads-container" mb={5}>
                    <Box mb={3}>
                        <Typography className="title">{adsTexts[tab]}</Typography>
                    </Box>
                    <SuperchargeCareer />
                </Box>
            </Box>
        </Box>
    )
};
export default EmptyState;
