import { useSnackbar } from 'components/snackbar';
import {
  Grid,
  TableHead,
  TableContainer,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  CardContent,
  Card,
  Box,
  Button,
  Container,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { getCandidateInfo } from 'api/admin';
import CloseIcon from '@mui/icons-material/Close';
import { setApplicationStatus } from 'api/admin';
import { setFeedbackDetails } from 'api/admin';
import { useAuth } from 'hooks/useAuthContext';
import { useForm, Controller } from 'react-hook-form';
import { deleteFeedback } from 'api/admin';
import { getApplicationFeedback } from 'api/feedback';

const dayjs = require('dayjs');

const ApplicantProfile = ({ selectedApplicant, onGoBack }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { _id } = selectedApplicant;
  const [loading, setLoading] = useState(true);
  const [applicants, setapplicants] = useState([]);
  const [ratings, setRatings] = useState({})
  const [status, setStatus] = useState(selectedApplicant?.status || '');
  const [scores, setScores] = useState({
    pre_assessment_score: 0,
    questionnaire_score: 0,
    career_progression_score: 0,
    communication_score: 0,
    finance_understanding_score: 0,
    behavioral_score: 0,
  });
  // feedback for candidate
  const [candidateFeedback, setCandidateFeedback] = useState('');
  const [visibleToCompany, setForCompany] = useState(true);
  const [visibleToCandidate, setForCandidate] = useState(true);
  // feeback related to application
  const [applicationFeedback, setapplicationFeedback] = useState('');
  const [visibleToCompanyApplication, setForCompanyApplication] = useState(true);
  const [visibleToCandidateApplication, setForCandidateApplication] = useState(true);
  // feedbcak list
  const [feedbacks, setFeedbacks] = useState(null);
  const { user } = useAuth();
  const userId = user?.id;

  const candidateFeedbackData = {
    feedback_by: userId,
    for: selectedApplicant.user_id,
    job_id: selectedApplicant?.job_id,
    company_id: selectedApplicant?.company_id,
    application_status: status,
    feedback: candidateFeedback,
    visible_to_candidate: visibleToCandidate,
    visible_to_company: visibleToCompany,
    status: 'active',
  };
  const applicationFeedbackData = {
    feedback_by: userId,
    for: selectedApplicant.user_id,
    job_id: selectedApplicant?.job_id,
    company_id: selectedApplicant?.company_id,
    application: selectedApplicant._id,
    application_status: status,
    feedback: applicationFeedback,
    visible_to_candidate: visibleToCandidateApplication,
    visible_to_company: visibleToCompanyApplication,
    status: 'active',
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCandidateInfo({ _id, userId });
        const feedbackResponse = await getApplicationFeedback({ jobId: selectedApplicant.job_id, userId: selectedApplicant.user_id, })
        if (response?.success && response.data && feedbackResponse.success) {
          setapplicants(response?.data?.applicant);
          setRatings(response?.data);
          setFeedbacks(feedbackResponse?.data);
          const assessmentScore = response?.data?.applicant?.assessment_score;
          setScores(assessmentScore ? assessmentScore : {
            pre_assessment_score: 1,
            questionnaire_score: 1,
            career_progression_score: 1,
            communication_score: 1,
            finance_understanding_score: 1,
            behavioral_score: 1,
          });
        } else {
          console.error('Failed to fetch job info:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching job info:', error);
      }
      setLoading(false);
    };
    fetchData();
  }, [feedbacks]);

  if (!selectedApplicant) {
    return (
      <Box>
        <Typography variant="h6">No applicant selected</Typography>
      </Box>
    );
  }

  const handleGoBack = () => {
    if (typeof onGoBack === 'function') {
      onGoBack();
    }
  };
  const handleScore = (event, label) => {
    const selectedValue = event.target.value;
    setScores(prevScores => ({
      ...prevScores,
      [label]: selectedValue
    }));
    handleApplicationScore(selectedValue, label);
  };
  const handleApplicationScore = async (score, label) => {
    try {
      const data = { [label]: score };
      const response = await setApplicationStatus({ _id, data });
      if (response?.success === true && response.data) {
        enqueueSnackbar('Status update successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to update status', { variant: 'error' });
        console.error('Failed to update status:', response.message);
      }
    } catch (error) {
      console.error('Error updating status:', error);
      enqueueSnackbar('An error occurred while updating status', { variant: 'error' });
    }
  };
  const handleStatus = (event) => {
    setStatus(event.target.value);
    handleApplicationStatus(event.target.value);
  };
  const handleApplicationStatus = async (status) => {
    try {
      const response = await setApplicationStatus({ _id, status });
      if (response?.success === true && response.data) {
        enqueueSnackbar('Status update successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to update status', { variant: 'error' });
        console.error('Failed to update status:', response.message);
      }
    } catch (error) {
      console.error('Error updating status:', error);
      enqueueSnackbar('An error occurred while updating status', { variant: 'error' });
    }
  };

  // candidate feedback
  const handleCandidateFeedback = (event) => {
    setCandidateFeedback(event.target.value);
  };
  const handleCandidate = () => {
    setForCandidate(prevState => !prevState);
  };
  const handleCompany = () => {
    setForCompany(prevState => !prevState);
  };
  //feedback for candidate
  const handleSubmitCandidateFeedback = async () => {
    try {
      const response = await setFeedbackDetails(candidateFeedbackData);
      if (response?.success === true && response.data) {
        enqueueSnackbar('Feedback sent successfully', { variant: 'success' });
        setCandidateFeedback('');

      } else {
        enqueueSnackbar('Failed to send feedback', { variant: 'error' });
        console.error('Failed to send feedback:', response.message);
      }
    } catch (error) {
      console.error('Error sending feedback:', error);
      enqueueSnackbar('An error occurred while sending feedback', { variant: 'error' });
    }
  };

  // application feedback
  const handleApplicationFeedback = (event) => {
    setapplicationFeedback(event.target.value);
  };
  const handleCandidateApplication = () => {
    setForCandidateApplication(prevState => !prevState);
  };
  const handleCompanyApplication = () => {
    setForCompanyApplication(prevState => !prevState);
  };
  //feedback related to application
  const handleSubmitApplicationFeedback = async () => {
    try {
      const response = await setFeedbackDetails(applicationFeedbackData); // Use formDataWithIds here
      if (response?.success === true && response.data) {
        enqueueSnackbar('Feedback sent successfully', { variant: 'success' });
        setapplicationFeedback('');
      } else {
        enqueueSnackbar('Failed to send feedback', { variant: 'error' });
        console.error('Failed to send feedback:', response.message);
      }
    } catch (error) {
      console.error('Error sending feedback:', error);
      enqueueSnackbar('An error occurred while sending feedback', { variant: 'error' });
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  // delete feedback
  const handleDeleteFeedback = async (feedbackId) => {
    try {
      const response = await deleteFeedback({ feedbackId });
      if (response?.success === true && response.data) {
        enqueueSnackbar('Feedback deleted successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to delete feedback', { variant: 'error' });
        console.error('Failed to delete feedback:', response.message);
      }
    } catch (error) {
      console.error('Error deleting feedback:', error);
      enqueueSnackbar('An error occurred while deleting feedback', { variant: 'error' });
    }
  };

  const handleNavigation = () => {
    window.open(applicants.resume, '_blank');
  }

  return (
    <Box sx={{ mt: '-25px' }}>
      <Container>
        <Box sx={{ margin: '2rem 0', marginBottom: '1rem' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h5" component="div" sx={{ marginBottom: '1rem' }}>Applicant Profile</Typography>
            <IconButton onClick={handleGoBack}><CloseIcon /></IconButton>
          </Stack>
          <Paper>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
              <Typography>Name: {applicants?.user_id?.full_name || 'N/A'}</Typography>
              <Typography>Phone Number: {applicants?.user_id?.phone_number || 'N/A'}</Typography>
              <Typography>Email: {applicants?.user_id?.email || 'N/A'}</Typography>
              <Typography gutterBottom style={{ textAlign: 'right' }}>{ratings.aggregateScore} star</Typography>
            </Stack>
            <Box sx={{ marginBottom: '1rem' }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography variant="h6" gutterBottom style={{ textAlign: 'left' }}>
                    Candidate Relevance Score
                  </Typography>
                </Box>
                <Box>
                  <Typography gutterBottom style={{ textAlign: 'right' }}>
                    Aggregate Score: {ratings.aggregateScore}
                  </Typography>
                </Box>
              </Stack>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box>
                    <h3>JD Specific</h3>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Experience Level</TableCell>
                          <TableCell> Star: {ratings.rating.experienceMatchRating}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Skills Match</TableCell>
                          <TableCell> Star: {ratings.rating.skillMatchRating}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Industry fit</TableCell>
                          <TableCell>   </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Salary Match</TableCell>
                          <TableCell> Star: {ratings.rating.salaryMatchRating}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <h3>General Parameters</h3>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Job stability</TableCell>
                          <TableCell>Star: {ratings.rating.jobStabilityRating} </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Career Progression</TableCell>
                          <TableCell>{scores && scores.career_progression_score ? scores.career_progression_score : 'no data'} </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Communication</TableCell>
                          <TableCell>{scores && scores.communication_score ? scores.communication_score : 'no data'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Technical & Finacial skills</TableCell>
                          <TableCell>{scores && scores.finance_understanding_score ? scores.finance_understanding_score : 'no data'} </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Behavioral skills</TableCell>
                          <TableCell>{scores && scores.behavioral_score ? scores.behavioral_score : 'no data'}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* application status */}
            <Box sx={{ marginBottom: '1rem' }}>
              <Typography variant="h6" gutterBottom style={{ textAlign: 'left' }}> Application  </Typography>
            </Box>
            <Box sx={{ marginBottom: '1rem' }}>
              <FormControl fullWidth>
                <InputLabel id="status">Application Status</InputLabel>
                <Select labelId="status" id="status" value={status} onChange={handleStatus} label="Select an option">
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="shortlisted">shortlisted</MenuItem>
                  <MenuItem value="hold">hold</MenuItem>
                  <MenuItem value="rejected">rejected</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ marginBottom: '1rem' }}>
              <FormControl fullWidth>
                <InputLabel id="score">Pre-assessment score</InputLabel>
                <Select labelId="score" value={scores.pre_assessment_score || ''} onChange={(event) => handleScore(event, "pre_assessment_score")} label="Select Pre-assessment score">
                  <MenuItem value={1}><em>Below Average</em></MenuItem>
                  <MenuItem value={2}>Below Average</MenuItem>
                  <MenuItem value={3}>Above Average</MenuItem>
                  <MenuItem value={4}>Excellent</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ marginBottom: '1rem' }}>
              <FormControl fullWidth>
                <InputLabel id="score">Questionnaire score</InputLabel>
                <Select labelId="score" value={scores.questionnaire_score} onChange={(event) => handleScore(event, "questionnaire_score")} label="Select Questionnaire score">
                  <MenuItem value={4}><em>Above 80% accurate</em></MenuItem>
                  <MenuItem value={3}>65-80% accurate</MenuItem>
                  <MenuItem value={2}>50-65% accurate</MenuItem>
                  <MenuItem value={1}>Below 50% accurate</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ marginBottom: '1rem' }}>
              <FormControl fullWidth>
                <InputLabel id="score">Career progression</InputLabel>
                <Select labelId="score" value={scores.career_progression_score} onChange={(event) => handleScore(event, "career_progression_score")} label="Select Career progression">
                  <MenuItem value={1}><em>Low experience quality & designation, low salary, Extended gaps, etc.</em></MenuItem>
                  <MenuItem value={4}>Experience quality high, steady designation progress, salary at par or above peer group</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ marginBottom: '1rem' }}>
              <FormControl fullWidth>
                <InputLabel id="score">Communication</InputLabel>
                <Select labelId="score" value={scores.communication_score} onChange={(event) => handleScore(event, "communication_score")} label="Select Communication">
                  <MenuItem value={1}><em>Unable to speak clearly/ coherently</em></MenuItem>
                  <MenuItem value={4}>Ability to clearly explain difficult concepts</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ marginBottom: '1rem' }}>
              <FormControl fullWidth>
                <InputLabel id="score">Finance understanding</InputLabel>
                <Select labelId="score" value={scores.finance_understanding_score} onChange={(event) => handleScore(event, "finance_understanding_score")} label="Select Finance understanding">
                  <MenuItem value={1}><em>Low level of finance knowledge</em></MenuItem>
                  <MenuItem value={4}>General finance knowledge</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ marginBottom: '1rem' }}>
              <FormControl fullWidth>
                <InputLabel id="score">Behavioral skills</InputLabel>
                <Select labelId="score" value={scores.behavioral_score} onChange={(event) => handleScore(event, "behavioral_score")} label="Select Behavioral skills">
                  <MenuItem value={1}><em>Poor behavioral skills</em></MenuItem>
                  <MenuItem value={4}>Attributes: Learnability, teamwork, leadership, humility/ courteousness</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {/* For Candidate */}
            <Box sx={{ marginBottom: '1rem' }}>
              <Typography variant="h6" style={{ textAlign: 'left' }}> For Candidate </Typography>
              <TextField label="Feedback" value={candidateFeedback} onChange={handleCandidateFeedback} fullWidth margin="normal" />
              <FormControlLabel control={<Checkbox checked={visibleToCandidate} onChange={handleCandidate} />} label="Candidate" />
              {(user.user_type === 'admin' || user.user_type === 'superadmin') && (
                <FormControlLabel control={<Checkbox checked={visibleToCompany} onChange={handleCompany} />} label="Company" />
              )}
              <Button variant="contained" color="primary" onClick={handleSubmitCandidateFeedback}> Add </Button>
            </Box>
            {/* related to application */}
            <Box sx={{ marginBottom: '1rem' }}>
              <Typography variant="h6" style={{ textAlign: 'left' }}> Related to Application </Typography>
              <TextField label="Feedback" value={applicationFeedback} onChange={handleApplicationFeedback} fullWidth margin="normal" />
              <FormControlLabel control={<Checkbox checked={visibleToCandidateApplication} onChange={handleCandidateApplication} />} label="Candidate" />
              {(user.user_type === 'admin' || user.user_type === 'superadmin') && (
                <FormControlLabel control={<Checkbox checked={visibleToCompanyApplication} onChange={handleCompanyApplication} />} label="Company" />
              )}
              <Button variant="contained" color="primary" onClick={handleSubmitApplicationFeedback}> Add </Button>
            </Box>
            {/* feedback list */}
            <Paper elevation={3} sx={{ marginBottom: '1rem' }}>
              <div style={{ height: 'auto', width: '100%' }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>SN</TableCell>
                        <TableCell>Feedback</TableCell>
                        <TableCell>Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {feedbacks?.length === 0 ? (
                        <TableRow><TableCell colSpan={8}>No comment added yet.</TableCell></TableRow>) : (
                        feedbacks.map((feedback, index) => (
                          <TableRow key={feedback._id || index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{feedback.feedback}</TableCell>
                            <TableCell> {feedback.is_deletable ? (
                              <Button onClick={() => handleDeleteFeedback(feedback._id)} variant="contained" color="secondary">Delete</Button>
                            ) : (<Button disabled>Delete</Button>)} </TableCell>
                          </TableRow>
                        )))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Paper>
            {/* candidate profile details */}
            <Card>
              <CardContent>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                  <Typography variant="h6" gutterBottom>Profile</Typography>
                  {applicants?.resume?.length > 0 && <Button variant="contained" size="medium" onClick={handleNavigation}>Resume</Button>}
                </Stack>
                {applicants?.requirements_matched?.work_history.length === 0 || applicants?.requirements_matched?.education.length === 0 ? (
                  <Paper elevation={3} style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {applicants?.resume?.length > 0 && <Typography color="h6" sx={{ fontSize: '1rem', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem', marginLeft: '1rem', }}>
                      Click on Resume Button</Typography>}
                  </Paper>
                ) : (
                  <Paper elevation={3} style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    <Typography color="h6" sx={{ fontSize: '1rem', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem', marginLeft: '1rem', }}>
                      <strong>Work History</strong></Typography>
                    <Paper elevation={3} sx={{ marginBottom: '1rem' }}>
                      <div style={{ height: 'auto', width: '100%' }}>
                        <TableContainer sx={{ marginLeft: '1rem', }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Company Name</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Period of Work From</TableCell>
                                <TableCell>Period of Work To</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {applicants?.requirements_matched?.work_history.length === 0 ? (
                                <TableRow><TableCell colSpan={8}>No work history added yet.</TableCell></TableRow>
                              ) : (applicants?.requirements_matched?.work_history.map((item, index) => (
                                <TableRow key={item._id || index}>
                                  <TableCell>{item?.organization_name || 'N/A'}</TableCell>
                                  <TableCell>{item?.work_history_location || 'N/A'}</TableCell>
                                  <TableCell>{dayjs(item?.work_history_from).format('MMM, YYYY')}</TableCell>
                                  <TableCell>{dayjs(item?.work_history_to).format('MMM,YYYY')}</TableCell>
                                </TableRow>
                              )))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Typography color="h6" sx={{ fontSize: '1rem', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem', marginLeft: '1rem', }} >
                      <strong>Education History</strong></Typography>
                    <Paper elevation={3} sx={{ marginBottom: '1rem' }}>
                      <div style={{ height: 'auto', width: '100%' }}>
                        <TableContainer sx={{ marginLeft: '1rem', }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Institute Name</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Period of Start From</TableCell>
                                <TableCell>Period of End To</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {applicants?.requirements_matched?.education.length === 0 ? (
                                <TableRow><TableCell colSpan={8}>No work history added yet.</TableCell></TableRow>
                              ) : (applicants?.requirements_matched?.education.map((item, index) => (
                                <TableRow key={item._id || index}>
                                  <TableCell>{item?.education_institute_name || 'N/A'}</TableCell>
                                  <TableCell>{item?.collage_location || 'N/A'}</TableCell>
                                  <TableCell>{dayjs(item?.education_from).format('MMM, YYYY')}</TableCell>
                                  <TableCell>{dayjs(item?.education_to).format('MMM,YYYY')}</TableCell>
                                </TableRow>
                              )))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                  </Paper>
                )}
              </CardContent>
            </Card>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default ApplicantProfile;
