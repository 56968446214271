import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, RadioGroup, CircularProgress, Box, FormControlLabel, Radio, Grid, FormLabel } from '@mui/material';
import { addStaff } from 'api/company';
import { StaffSchema } from 'models/StaffSchema';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'components/snackbar';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCompanyStaffDetails, updateCompanyStaff } from 'api/company';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const CompanyStaffForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [companyStaffDetails, setCompanyStaffDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const containsExample = location.pathname.includes('add-staff');
  const isEditMode = !containsExample;

  const handleNavigation = (companyId) => {
    navigate(`/company-details/${companyId}`);
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(StaffSchema),
    defaultValues: {
      name: '',
      business_function: '',
      designation: '',
      email: '',
      phone_number: '',
      date_of_joining: '',
      is_tj_sourced: false,
    },
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isEditMode) {
          setLoading(false);
          return;
        }
        const response = await getCompanyStaffDetails({ id });
        if (response?.success && response.data != null) {
          setCompanyStaffDetails(response.data);
          enqueueSnackbar(response.message, { variant: 'success' });
        } else {
          enqueueSnackbar('Failed to fetch company data. Please try again later.', { variant: 'error' });
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
        enqueueSnackbar('An unexpected error occurred while fetching company data.', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id, enqueueSnackbar]);

  useEffect(() => {
    if (isEditMode && companyStaffDetails) {
      Object.keys(companyStaffDetails).forEach(key => {
        methods.setValue(key, companyStaffDetails[key]);
      });
    }
  }, [isEditMode, companyStaffDetails, methods]);


  const { handleSubmit, formState: { errors, isSubmitting } } = methods;

  const onSubmit = async (data) => {
    try {
      let response;
      if (isEditMode) {
        if (!companyStaffDetails) {
          enqueueSnackbar('Company staff details not available. Please try again later.', { variant: 'error' });
          return;
        }
        response = await updateCompanyStaff({ id, ...data });
        if (response?.success && response.data != null) {
          enqueueSnackbar(response.message, { variant: 'success' });
          handleNavigation(response.data.company);
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      } else {
        response = await addStaff({ id, ...data });
        if (response?.success === true && response.data) {
          enqueueSnackbar(response.message, { variant: 'success' });
          navigate('/companies');
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      }

    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar('An unexpected error occurred. Please try again later.', { variant: 'error' });
    }
  };


  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <FormProvider {...methods}>
       <Box sx={{ maxWidth: '900px',marginTop: '20px', marginLeft: 'auto', marginRight: 'auto' }}>
      <Typography variant="h5" align="center" gutterBottom>
        Staff Details
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Name"
          InputLabelProps={{ shrink: true }}
          {...methods.register('name')}
          fullWidth
          margin="normal"
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <TextField
          label="Business Function"
          InputLabelProps={{ shrink: true }}
          {...methods.register('business_function')}
          fullWidth
          margin="normal"
          error={!!errors.business_function}
          helperText={errors.business_function?.message}
        />
        <TextField
          label="Designation"
          InputLabelProps={{ shrink: true }}
          {...methods.register('designation')}
          fullWidth
          margin="normal"
          error={!!errors.designation}
          helperText={errors.designation?.message}
        />
        <TextField
          label="Email"
          {...methods.register('email')}
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="normal"
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <TextField
          label="Phone Number"
          {...methods.register('phone_number')}
          fullWidth
          type='number'
          InputLabelProps={{ shrink: true }}
          margin="normal"
          error={!!errors.phone_number}
          helperText={errors.phone_number?.message}
        />
        <Controller
          name="date_of_joining"
          control={methods.control}
          render={({ field }) => (
            <DatePicker
              {...field}
              value={dayjs(field?.value)}
              autoOk={true}
              label='Joining Date'
              closeOnSelect={true}
              format='DD/MM/YYYY'
              disableFuture
              InputLabelProps={{ shrink: true }}
              error={!!errors.date_of_joining}
              helperText={errors.date_of_joining?.message}
              onChange={(date) => handleStartDateChange(date)}
            />
          )}
        />
        <Grid item xs={12}>
          <FormLabel>TJ Sourced?</FormLabel>
        </Grid>
        <RadioGroup aria-label="TJ Sourced?" name="is_tj_sourced">
          <FormControlLabel value="true" control={<Radio />} label="Yes" />
          <FormControlLabel value="false" control={<Radio />} label="No" />
        </RadioGroup>
        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>
          {isSubmitting ? "Submitting.." : (isEditMode ? "Update" : "Save")}
        </Button>
      </form>
      </Box>
    </FormProvider>
  );
};

export default CompanyStaffForm;
