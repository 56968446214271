import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Box,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import exportFromJSON from 'export-from-json';
import CircularProgress from '@mui/material/CircularProgress';
import { getApplicantsList } from 'api/admin';

const AllApplicantsList = ({ setSelectedApplicant, jobDetails }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState([]);
  const [selectedApplications, setSelectedApplications] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const response = await getApplicantsList({ id });
        if (response?.success && response.data != null && response.data.applicant != null) {
          setApplications(response.data);
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      } catch (error) {
        console.error('Error fetching applications:', error);
      }
      setLoading(false);
    };
    fetchApplicants();
  }, []);

  const downloadSelectedApplicationsData = () => {
    const selectedApplicationsData = applications.applicant.filter(application => selectedApplications.includes(application.id));
    const applicationList = selectedApplicationsData.map(application => ({
      'Name': application?.candidate_profile?.full_name || 'N/A',
      'Email': application?.candidate_profile?.email || 'N/A',
      'Phone Number': application?.candidate_profile?.phone_number || 'N/A',
      '% match': (application?.requirements_matched?.matching_skills.length || 0) * 20,
      'Status': application?.status || 'N/A',
      'Education': (application?.requirements_matched?.qualifications || []).join(' , ') || 'N/A',
      'Years of Experience': application?.requirements_matched?.experience || 'N/A',
      'Salary': application?.requirements_matched?.salary || 'N/A',
      'Job ID': jobDetails.jobid || 'N/A',
      'Job Title': jobDetails.title || 'N/A',
      'Company Name': jobDetails.company || 'N/A',

    }));

    const fileName = `${jobDetails.title}_${jobDetails.jobid}_${Date.now()}_applicants`;
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data: applicationList, fileName, exportType });
  };

  const handleCheckboxChange = (event, applicationId) => {
    if (event.target.checked) {
      setSelectedApplications([...selectedApplications, applicationId]);
    } else {
      setSelectedApplications(selectedApplications.filter((id) => id !== applicationId));
    }
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedApplications(applications.applicant.map((application) => application.id));
    } else {
      setSelectedApplications([]);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box >
      <Container style={{ marginTop: '80px', marginLeft:'20px' }}>
        <Box sx={{ margin: '2rem 0' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h5" component="div" sx={{ marginBottom: '1rem' }}>
              Applicants - TJ Jobs
            </Typography>
            <Button variant="contained" onClick={downloadSelectedApplicationsData} disabled={selectedApplications.length === 0} >
              Download Applicant List
            </Button>
          </Stack>
          <Paper elevation={3}>
            <div style={{ height: 'auto', width: '100%' }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
                        All
                      </TableCell>
                      <TableCell>#</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>% match</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Education</TableCell>
                      <TableCell>Years of Experience</TableCell>
                      <TableCell>Salary</TableCell>
                    </TableRow>
                  </TableHead>
                  {/* applications.applicant listing */}
                  <TableBody>
                    {applications.applicant && applications.applicant.length > 0 ? (
                      applications.applicant.map((application, index) => (
                        <TableRow key={application?.user_id || index} onClick={() => setSelectedApplicant(application)} style={{ cursor: 'pointer' }} >
                          <TableCell onClick={(event) => event.stopPropagation()}>
                            <Checkbox checked={selectedApplications.includes(application.id)} onChange={(event) => handleCheckboxChange(event, application.id)} />
                          </TableCell>
                          <TableCell> {index + 1} </TableCell>
                          <TableCell> {application?.candidate_profile?.full_name || 'N/A'} </TableCell>
                          <TableCell>
                            {' '}
                            {application?.requirements_matched?.matching_skills.length * 20 || 'N/A'}{' '}
                          </TableCell>
                          <TableCell> {application?.status || 'N/A'} </TableCell>
                          <TableCell>
                            {' '}
                            {application?.requirements_matched?.qualifications?.join(' , ') || 'N/A'}
                          </TableCell>
                          <TableCell> {application?.requirements_matched?.experience || 'N/A'} </TableCell>
                          <TableCell> {application?.requirements_matched?.salary || 'N/A'} </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8}>No applications registered yet.</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default AllApplicantsList;
