
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { Box, Button, Chip, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import JobDescription from 'pages/job/JobDescription';
import JobsApplyApplication from './JobsApplyApplication';
import { useAuth } from 'hooks/useAuthContext';
import { jobDetails } from 'api/job';
import { getApplicationFeedback } from 'api/feedback';
const dayjs = require('dayjs');

const JobAndApplicationDetails = () => {
  const [loading, setLoading] = useState(true);
  const [jobs, setjobs] = useState([]);
  const { user } = useAuth();
  const userId = user?.id;

  const [color1, setColor1] = useState('success');
  const [color2, setColor2] = useState('primary');
  const [page, setPage] = useState(false);
  const { id } = useParams();
  const [feedbacks, setFeedbacks] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await jobDetails({ id: id, userId: userId });
        const feedbackResponse = await getApplicationFeedback({ jobId: id });
        if (response?.success && feedbackResponse.success) {
          setjobs(response.data);
          setFeedbacks(feedbackResponse.data);
        } else {
          console.error('Failed to fetch job info:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching job info:', error);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleClick1 = () => {
    setColor1('success');
    setColor2('primary');
    setPage(false);
  };

  const handleClick2 = () => {
    setColor1('primary');
    setColor2('success');
    setPage(true);
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box>
      <Box sx={{ marginBottom: '2rem' }}>
        <Box sx={{ marginBottom: '2rem' }}>
          <Grid container justifyContent="center">
            <Grid item>
              <Stack direction="row" spacing={2}>
                <Button variant="contained" color={color1} onClick={handleClick1}>
                  Job Details
                </Button>
                <Button variant="contained" color={color2} onClick={handleClick2}>
                  Application
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Container>{page ? <JobsApplyApplication /> : <JobDescription />}</Container>
          <Container sx={{ padding: '1rem' }}>
            <Box sx={{ padding: '1rem' }}>
              <Paper sx={{ p: 2 }}>
                    <Timeline>
                      {feedbacks.map((feedback, index) => (
                        <TimelineItem key={index}>
                          <TimelineSeparator>
                            {index === 0 ? <TimelineDot /> : <TimelineDot variant="outlined" />}
                            {index !== feedbacks.length - 1 && <TimelineConnector />}
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography>{dayjs(feedback.createdAt).format('DD/MM/YYYY')}</Typography>
                            <Chip label={feedback.application_status} color="primary" />
                            <Typography>{feedback.feedback}</Typography>
                            <Typography>{feedback.job_id.title}</Typography>
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                    </Timeline>
              </Paper>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default JobAndApplicationDetails;
