import * as yup from 'yup';
const CIN_REGEX = /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;

// Schema for validation
export const NewJobSchema = (alignment) => yup.object().shape({
    title: yup.string().required('Title is required'),
    function: yup.string().required('Function is required'),
    role: yup.string().required('Designation is required'),
    description: yup.string().required('Job description is required'),    
    qualifications: yup.array().of(yup.string()).min(1, "Please select atleast one qualification."),
    company: yup.string().required('Company name is required'),
    min: yup
      .number()
      .typeError('Min experience must be a number')
      .required('Min experience is required')
      .min(0, 'Min experience cannot be negative'),
    max: yup
      .number()
      .typeError('Max experience must be a number')
      .required('Max experience is required')
      .test('max-greater-than-min', 'Max experience must be greater than or equal to Min experience', function (value) {
        const { min } = this.parent;
        return value >= min;
      }),
    work_mode: yup.string().required('Please select option. Job format is required'),
    company_overview: yup.string().required('Please enter company overview'),
    job_overview: yup.string().required('Please enter a brief job summary'),
    job_type: yup.string().required('Please select option. Job type is required'),
    job_shift: yup.string().required('Please select option. Job shift is required'),
    industry_sector: yup.string().required('Please enter job belong which industry sector.'),
    compensation: yup.string().required('Please select compensation structure.'),
    notice_period: yup.string().required('Please select option. Notice Period is required'),
    location: yup.string().required('Job location is required'),
    direct_reports: yup.string().required('Number of people reporting is required').matches(/^[0-9]+$/, 'Direct Reports must be a number'),
    salary: yup.string().required('Salary is required.'), //.matches(/^[0-9]+$/, 'Salary must be a number'),
    // job_responsibilities: yup.string().required('Please enter job responsibilities'),
    external_link: alignment === 'tj-jobs' ? yup.string() : yup.string().url('Please enter job appliction link').required('Please enter job appliction link'),
    company_identification_number: yup.string().nullable(),
    company_website: yup.string() .nullable() // Allow null 
    .notRequired()
    .matches(/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{2,}\.)+[a-zA-Z]{2,}$/, { excludeEmptyString: true, message: 'Please enter a valid website URL.' } ),
    is_company_name_confidential: yup.boolean('Please select option.keep company name confidentail'),
    is_compensation_confidential: yup.boolean('Please select option.keep compensation confidentail'),
});

export const NewCompanyJobSchema = (alignment) => yup.object().shape({
    title: yup.string().required('Title is required'),
    company: yup.object().required('Company name is required'),
    function: yup.string().required('Function is required'),
    role: yup.string().required('Designation is required'),
    description: yup.string().required('Job description is required'),
    job_overview: yup.string().required('Please enter a brief job summary'),
    qualifications: yup.array().of(yup.string()).min(1, "Please select atleast one qualification."),
    min: yup
      .number()
      .required('Min experience is required')
      .min(0, 'Min experience cannot be negative'),
    max: yup
      .number()
      .required('Max experience is required')
      .test('max-greater-than-min', 'Max experience cannot be greater than or equal to Min experience', function (value) {
        const { min } = this.parent;
        return value >= min;
      }),
    work_mode: yup.string().required('Job format is required'),
    notice_period: yup.string().required('Notice Period is required'),
    location: yup.string().required('Job location is required'),
    direct_reports: yup.string().required('Number of people reporting is required').matches(/^[0-9]+$/, 'Direct Reports must be a number'),
    salary: yup.string().required('Salary is required').matches(/^[0-9]+$/, 'Salary must be a number'),
    job_shift: yup.string().required('Please select option. Job shift is required'),
    // job_responsibilities: yup.string().required('Please enter job responsibilities'),
    external_link: alignment === 'tj-jobs' ? yup.string() : yup.string().url('Invalid URL').required('Please enter job appliction link'),
    company_identification_number: yup.string().nullable(),
    company_website: yup.string() .nullable() // Allow null 
    .notRequired() // URL is optional 
    .matches( /^(https?:\/\/)?(www\.)?[^\s/$.?#].[^\s]*$/, { excludeEmptyString: true, message: 'Please enter a valid website URL.' } ),
    is_company_name_confidential: yup.boolean('Please select option.keep company name confidentail'),
    is_compensation_confidential: yup.boolean('Please select option.keep compensation confidentail'),
});
