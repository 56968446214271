import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import React from 'react'

const AutoSavingJobAppDialog = () => {

  return (
    <Card variant="outlined">
        <CardContent> 
            <Box sx={{textAlign: 'center'}}>
                <Typography className="title">Autosaving job application</Typography>
                <Typography className="info-text">While you update resume, we would save your job application under "My Jobs" section.</Typography>
            </Box>            
        </CardContent>                
        <CardActions>
            <Button fullWidth variant="contained" size="medium"></Button>
        </CardActions>
    </Card>
  )
}

export default AutoSavingJobAppDialog
