import * as yup from 'yup';

// Schema for validation
export const RegistrationSchema = yup.object().shape({
  full_name: yup
    .string()
    .min(2, 'Enter your name')
    .matches(/^[a-zA-Z\s]+$/, 'Full name can only contain letters and spaces')
    .required('Please enter your full name'),
  email: yup.string().email('Please enter valid email address.').required('Please enter your email id.'),
  password: yup
    .string()
    .min(7, 'Please enter a password between 7 to 12 characters')
    .max(12, 'Please enter a password between 7 to 12 characters'),
  phone_number: yup.string()
    .required('Please enter your phone number.').matches(/^[6-9]\d{9}$/, 'Phone number is not valid.'),
});

export const OTPSchema = yup.object().shape({
  email: yup.string().email('Please enter correct email id.').required('Please enter your email id.'),
});
