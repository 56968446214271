import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { getApplicationList } from 'api/application';
import { useAuth } from 'hooks/useAuthContext';

const dayjs = require('dayjs');

const ApplicationList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [applications, setapplications] = useState([]);
  const { user } = useAuth();
  const userId = user?.id;

  const handleNavigate = (status, job_id) => {
    if (status === 'saved') {
      navigate(`/review-details/${job_id}`)
    }
    else {
      navigate(`/job-application-description/${job_id}`)
    }
  };

  useEffect(() => {
    const fetchapplications = async () => {
      try {
        const response = await getApplicationList(userId);
        if (response?.success && response.data) {
          setapplications(response.data);
        }
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
      setLoading(false);
    };
    fetchapplications();
  }, []);

  return (
    <Box style={{ marginTop: '80px' }}>
      <Container >
        <Box sx={{ margin: '2rem 0' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h5" component="div" sx={{ marginBottom: '1rem' }}>
              Applications - TJ Jobs
            </Typography>
          </Stack>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Paper elevation={3}>
              <div style={{ height: 'auto', width: '100%' }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Job Id</TableCell>
                        <TableCell>Job</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Application Date</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Last Updated</TableCell>
                      </TableRow>
                    </TableHead>
                    {/* jobs listing */}
                    <TableBody>
                      {applications && applications?.length > 0 ? (
                        applications?.map((application, index) => (
                          <TableRow key={application?._id || index} style={{ cursor: 'pointer' }}
                            onClick={(e) => { handleNavigate(application?.status, application?.job_id?._id) }}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{application?.job_id?.jobid}</TableCell>
                            <TableCell>{application?.job_id?.title || 'N/A'}</TableCell>
                            <TableCell>{application?.job_id?.location || 'N/A'}</TableCell>
                            <TableCell>{application?.applied_at ? dayjs(application?.applied_at).format('DD/MM/YYYY') : 'N/A'}</TableCell>
                            <TableCell>{application?.status || 'N/A'}</TableCell>
                            <TableCell>{application?.updated_at ? dayjs(application?.updated_at).format('DD/MM/YYYY') : 'N/A'}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8}>No applications registered yet.</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Paper>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ApplicationList;
