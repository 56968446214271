import { useEffect, useState, useRef } from 'react';
import { Box, Button, Card, CardContent, FormControl, IconButton, InputLabel, OutlinedInput, Stack, SvgIcon, Typography } from '@mui/material'
import { MuiOtpInput } from 'mui-one-time-password-input'
import FormProvider from 'hooks/form/form-provider';
import { useForm, register, Controller } from 'react-hook-form';
import { verifyOTP } from 'api/login';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'components/snackbar'
import { resendOTP } from 'api/profile';
import { setContact } from 'api/users';
import { useAuth } from 'hooks/useAuthContext';
import { removeUser } from 'api/profile';

const OTPVerification = ({ userId, onCancelOTP, phoneNumber, email, isAccountDeteleted }) => {
    const otpIcon = `${process.env.REACT_APP_HOMEPAGE}assets/otp-icon-img.png`;
    const alertIcon = `${process.env.REACT_APP_HOMEPAGE}assets/icon-alert.svg`;
    const { loading, user, updateContext, logout } = useAuth();
    const [otp, setOtp] = useState('')
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [resendTimeout, setResendTimeout] = useState(60);
    const [attempts, setAttempts] = useState(0);

    const defaultValues = {
        otp: ''
    };

    const handleChange = (newValue) => {
        if (/^\d{0,6}$/.test(newValue)) {
            setOtp(newValue)
        }
    }

    const methods = useForm({
        defaultValues
    });

    const {
        control,
        register,
        handleSubmit,
        trigger,
        setValue,
        reset,
        formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
    } = methods;

    const onSubmit = handleSubmit(async () => {
        if (otp?.length !== 6) {
            return enqueueSnackbar('Please enter valid OTP', { variant: 'error' });
        }
        const formData = { otp, userId, email };
        const response = await verifyOTP(formData);
        if (response?.success === true && response.data) {
            if (response?.success === true && isAccountDeteleted) {
                const response = await removeUser({ email: user?.email, phone_number: user?.phone_number });
                if (response && response?.success) {
                    enqueueSnackbar("User has been successfully removed from Talent Junction platform", { variant: 'success' });
                    logout();
                } else {
                    enqueueSnackbar(response.message, { variant: 'error' });
                }
            }
        }
        if (response?.success === false) {
            enqueueSnackbar(response.message, { variant: 'error' });
            setOtp(false);
            if (attempts === 2) {
                enqueueSnackbar(response.message, { variant: 'error' });
                reset();
                setOtp(false);
                onCancelOTP();
            }
            setAttempts(attempts + 1);
        }
    });
    const maskPhoneNumber = (phoneNumber) => {
        const strPhoneNumber = phoneNumber.toString(); // Ensure it's a string
        if (strPhoneNumber.length !== 10) return phoneNumber; // Return original if not 10 digits

        return strPhoneNumber.slice(0, 3) + 'xxxxx' + strPhoneNumber.slice(-2);
    };

    const handleResend = async () => {
        const response = await resendOTP({ userId });
        if (response?.success === true) {
            enqueueSnackbar(`OTP has been sent.`, { variant: 'success' })
            setResendTimeout(45);
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setResendTimeout((prev) => Math.max(0, prev - 1));
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    return (
        <Card className="otp-card-wrapper" variant="outlined">
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <CardContent>
                    <IconButton className="close-dialog-btn" aria-label="delete" color="primary" onClick={onCancelOTP}>
                        <SvgIcon sx={{ width: '20px', height: '20px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clipPath="url(#clip0_456_20993)">
                                    <path d="M7 7L17 17M17 7L7 17L17 7Z" stroke="#111827" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_456_20993">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </SvgIcon>
                    </IconButton>
                    <Box className="otp-icon" mb={2.5}>
                        <Box
                            component="img"
                            alt="Talent Junction"
                            width="80px"
                            height="80px"
                            src={otpIcon}
                        />
                        <Box className="icon-layer"></Box>
                    </Box>

                    <Box mb={2.5} sx={{ textAlign: 'center' }}>
                        <Typography className="title" component="h6">
                            Delete Account Verification
                        </Typography>
                        <Stack direction="row" justifyContent={'center'} alignItems={'center'}>
                            <Typography className="otp-sent-txt" component="p">
                                {`Enter the 6-digit OTP sent to your WhatsApp number ${maskPhoneNumber(phoneNumber)}.`}<br />{`To confirm the permanent deletion of your Talent Junction account. Please note that this action is irreversible, and all your data on the platform will be permanently lost`}
                            </Typography>
                            &nbsp;
                            <Typography className="otp-sent-txt" component="p">
                                { }
                            </Typography>
                        </Stack>
                    </Box>
                    <Box mb={2}>
                        <MuiOtpInput value={otp} length={6} TextFieldsProps={{ placeholder: '-' }} onChange={handleChange} />
                    </Box>
                    <Box mb={2}>
                        <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
                            <Box>
                                <Typography className="resend-otp-txt" component="p">
                                    Resend OTP in: <Typography className="text-primary" component="span">{resendTimeout} secs</Typography>
                                </Typography>
                                {resendTimeout === 0 && <Button variant="outlined" size="small" disabled={resendTimeout > 0} onClick={handleResend} >
                                    Resend OTP
                                </Button>}
                            </Box>
                            <Box>
                                <Stack direction="row" alignItems={'center'} spacing={1}>
                                    <Box
                                        mb={5}
                                        component="img"
                                        alt="Talent Junction"
                                        src={alertIcon}
                                    />
                                    <Typography className="attempts-txt" component="p">
                                        {attempts}/3 attempts left
                                    </Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                    <Stack direction="row" alignItems={'center'} spacing={3}>
                        <Button fullWidth variant="outlined" size="medium" onClick={onCancelOTP}>
                            Cancel
                        </Button>
                        <Button fullWidth variant="contained" size="medium" type="submit" onClick={handleChange} disabled={isSubmitting}>
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </Button>
                    </Stack>
                </CardContent>
            </FormProvider>
        </Card>
    )
}

export default OTPVerification