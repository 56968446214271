const clusteredData = [
    {
        "name": "Ahmedabad Urban Agglomeration",
        "value": "Ahmedabad, Gandhinagar, Bopal, Naroda, Sarkhej"
    },
    {
        "name": "Bengaluru Urban Agglomeration",
        "value": "Bengaluru, Yelahanka, Whitefield, Kengeri, Hoskote"
    },
    {
        "name": "Bhopal Urban Agglomeration",
        "value": "Bhopal, Mandideep, Sehore"
    },
    {
        "name": "Chennai Metropolitan Area (CMA)",
        "value": "Chennai, Tambaram, Avadi, Kanchipuram, Sriperumbudur"
    },
    {
        "name": "Delhi NCR",
        "value": "Delhi, Gurgaon (Gurugram), Noida, Ghaziabad, Faridabad, Greater Noida"
    },
    {
        "name": "Hyderabad Metropolitan Region",
        "value": "Hyderabad, Secunderabad, Gachibowli, Kukatpally, Shamshabad, Medchal"
    },
    {
        "name": "Jaipur Metropolitan Area",
        "value": "Jaipur, Sanganer, Amer, Bassi"
    },
    {
        "name": "Kolkata Metropolitan Area (KMA)",
        "value": "Kolkata, Howrah, Bidhannagar, Rajarhat, Baranagar, South Dumdum"
    },
    {
        "name": "Lucknow-Kanpur Region",
        "value": "Lucknow, Kanpur, Unnao, Kakori, Barabanki"
    },
    {
        "name": "Metros",
        "value": "Delhi NCR, Mumbai, Kolkata, Chennai, Bengaluru, Hyderabad, Ahmedabad, Pune"
    },
    {
        "name": "Mumbai Metropolitan Region (MMR)",
        "value": "Mumbai, Navi Mumbai, Thane, Kalyan, Dombivli, Panvel, Vasai-Virar, Palghar"
    },
    {
        "name": "Patna Metropolitan Region",
        "value": "Patna, Hajipur, Danapur, Fatuha"
    },
    {
        "name": "Pune Metropolitan Region (PMR)",
        "value": "Pune, Pimpri-Chinchwad, Talegaon, Chakan, Lonavala, Hinjawadi"
    },
    {
        "name": "Tier 1 cities",
        "value": "Delhi NCR, Mumbai, Bengaluru, Hyderabad, Chennai, Kolkata, Pune, Ahmedabad, Surat, Jaipur"
    }
];

const cleanData = [
    {
        "name": "Adilabad",
        "value": "Adilabad"
    },
    {
        "name": "Agra",
        "value": "Agra"
    },
    {
        "name": "Ahmed Nagar",
        "value": "Ahmed Nagar"
    },
    {
        "name": "Ahmedabad",
        "value": "Ahmedabad"
    },
    {
        "name": "Aizawl",
        "value": "Aizawl"
    },
    {
        "name": "Ajmer",
        "value": "Ajmer"
    },
    {
        "name": "Akola",
        "value": "Akola"
    },
    {
        "name": "Alappuzha",
        "value": "Alappuzha"
    },
    {
        "name": "Aligarh",
        "value": "Aligarh"
    },
    {
        "name": "Alirajpur",
        "value": "Alirajpur"
    },
    {
        "name": "Allahabad",
        "value": "Allahabad"
    },
    {
        "name": "Almora",
        "value": "Almora"
    },
    {
        "name": "Alwar",
        "value": "Alwar"
    },
    {
        "name": "Ambala",
        "value": "Ambala"
    },
    {
        "name": "Ambedkar Nagar",
        "value": "Ambedkar Nagar"
    },
    {
        "name": "Amravati",
        "value": "Amravati"
    },
    {
        "name": "Amreli",
        "value": "Amreli"
    },
    {
        "name": "Amritsar",
        "value": "Amritsar"
    },
    {
        "name": "Anand",
        "value": "Anand"
    },
    {
        "name": "Ananthapur",
        "value": "Ananthapur"
    },
    {
        "name": "Ananthnag",
        "value": "Ananthnag"
    },
    {
        "name": "Angul",
        "value": "Angul"
    },
    {
        "name": "Anuppur",
        "value": "Anuppur"
    },
    {
        "name": "Araria",
        "value": "Araria"
    },
    {
        "name": "Ariyalur",
        "value": "Ariyalur"
    },
    {
        "name": "Arwal",
        "value": "Arwal"
    },
    {
        "name": "Ashok Nagar",
        "value": "Ashok Nagar"
    },
    {
        "name": "Auraiya",
        "value": "Auraiya"
    },
    {
        "name": "Aurangabad",
        "value": "Aurangabad"
    },
    {
        "name": "Aurangabad(BH)",
        "value": "Aurangabad(BH)"
    },
    {
        "name": "Azamgarh",
        "value": "Azamgarh"
    },
    {
        "name": "Bagalkot",
        "value": "Bagalkot"
    },
    {
        "name": "Bageshwar",
        "value": "Bageshwar"
    },
    {
        "name": "Bagpat",
        "value": "Bagpat"
    },
    {
        "name": "Bahraich",
        "value": "Bahraich"
    },
    {
        "name": "Balaghat",
        "value": "Balaghat"
    },
    {
        "name": "Balangir",
        "value": "Balangir"
    },
    {
        "name": "Baleswar",
        "value": "Baleswar"
    },
    {
        "name": "Ballia",
        "value": "Ballia"
    },
    {
        "name": "Balrampur",
        "value": "Balrampur"
    },
    {
        "name": "Banaskantha",
        "value": "Banaskantha"
    },
    {
        "name": "Banda",
        "value": "Banda"
    },
    {
        "name": "Bandipur",
        "value": "Bandipur"
    },
    {
        "name": "Bangalore",
        "value": "Bangalore"
    },
    {
        "name": "Bangalore Rural",
        "value": "Bangalore Rural"
    },
    {
        "name": "Banka",
        "value": "Banka"
    },
    {
        "name": "Bankura",
        "value": "Bankura"
    },
    {
        "name": "Banswara",
        "value": "Banswara"
    },
    {
        "name": "Barabanki",
        "value": "Barabanki"
    },
    {
        "name": "Baramulla",
        "value": "Baramulla"
    },
    {
        "name": "Baran",
        "value": "Baran"
    },
    {
        "name": "Bardhaman",
        "value": "Bardhaman"
    },
    {
        "name": "Bareilly",
        "value": "Bareilly"
    },
    {
        "name": "Bargarh",
        "value": "Bargarh"
    },
    {
        "name": "Barmer",
        "value": "Barmer"
    },
    {
        "name": "Barnala",
        "value": "Barnala"
    },
    {
        "name": "Barpeta",
        "value": "Barpeta"
    },
    {
        "name": "Barwani",
        "value": "Barwani"
    },
    {
        "name": "Bastar",
        "value": "Bastar"
    },
    {
        "name": "Basti",
        "value": "Basti"
    },
    {
        "name": "Bathinda",
        "value": "Bathinda"
    },
    {
        "name": "Beed",
        "value": "Beed"
    },
    {
        "name": "Begusarai",
        "value": "Begusarai"
    },
    {
        "name": "Belgaum",
        "value": "Belgaum"
    },
    {
        "name": "Bellary",
        "value": "Bellary"
    },
    {
        "name": "Betul",
        "value": "Betul"
    },
    {
        "name": "Bhadrak",
        "value": "Bhadrak"
    },
    {
        "name": "Bhagalpur",
        "value": "Bhagalpur"
    },
    {
        "name": "Bhandara",
        "value": "Bhandara"
    },
    {
        "name": "Bharatpur",
        "value": "Bharatpur"
    },
    {
        "name": "Bharuch",
        "value": "Bharuch"
    },
    {
        "name": "Bhavnagar",
        "value": "Bhavnagar"
    },
    {
        "name": "Bhilwara",
        "value": "Bhilwara"
    },
    {
        "name": "Bhind",
        "value": "Bhind"
    },
    {
        "name": "Bhiwani",
        "value": "Bhiwani"
    },
    {
        "name": "Bhojpur",
        "value": "Bhojpur"
    },
    {
        "name": "Bhopal",
        "value": "Bhopal"
    },
    {
        "name": "Bidar",
        "value": "Bidar"
    },
    {
        "name": "Bijapur(CGH)",
        "value": "Bijapur(CGH)"
    },
    {
        "name": "Bijapur(KAR)",
        "value": "Bijapur(KAR)"
    },
    {
        "name": "Bijnor",
        "value": "Bijnor"
    },
    {
        "name": "Bikaner",
        "value": "Bikaner"
    },
    {
        "name": "Bilaspur (HP)",
        "value": "Bilaspur (HP)"
    },
    {
        "name": "Bilaspur(CGH)",
        "value": "Bilaspur(CGH)"
    },
    {
        "name": "Birbhum",
        "value": "Birbhum"
    },
    {
        "name": "Bishnupur",
        "value": "Bishnupur"
    },
    {
        "name": "Bokaro",
        "value": "Bokaro"
    },
    {
        "name": "Bongaigaon",
        "value": "Bongaigaon"
    },
    {
        "name": "Boudh",
        "value": "Boudh"
    },
    {
        "name": "Budaun",
        "value": "Budaun"
    },
    {
        "name": "Budgam",
        "value": "Budgam"
    },
    {
        "name": "Bulandshahr",
        "value": "Bulandshahr"
    },
    {
        "name": "Buldhana",
        "value": "Buldhana"
    },
    {
        "name": "Bundi",
        "value": "Bundi"
    },
    {
        "name": "Burhanpur",
        "value": "Burhanpur"
    },
    {
        "name": "Buxar",
        "value": "Buxar"
    },
    {
        "name": "Cachar",
        "value": "Cachar"
    },
    {
        "name": "Chamba",
        "value": "Chamba"
    },
    {
        "name": "Chamoli",
        "value": "Chamoli"
    },
    {
        "name": "Champawat",
        "value": "Champawat"
    },
    {
        "name": "Champhai",
        "value": "Champhai"
    },
    {
        "name": "Chamrajnagar",
        "value": "Chamrajnagar"
    },
    {
        "name": "Chandauli",
        "value": "Chandauli"
    },
    {
        "name": "Chandel",
        "value": "Chandel"
    },
    {
        "name": "Chandigarh",
        "value": "Chandigarh"
    },
    {
        "name": "Chandrapur",
        "value": "Chandrapur"
    },
    {
        "name": "Changlang",
        "value": "Changlang"
    },
    {
        "name": "Chatra",
        "value": "Chatra"
    },
    {
        "name": "Chennai",
        "value": "Chennai"
    },
    {
        "name": "Chhatarpur",
        "value": "Chhatarpur"
    },
    {
        "name": "Chhindwara",
        "value": "Chhindwara"
    },
    {
        "name": "Chickmagalur",
        "value": "Chickmagalur"
    },
    {
        "name": "Chikkaballapur",
        "value": "Chikkaballapur"
    },
    {
        "name": "Chitradurga",
        "value": "Chitradurga"
    },
    {
        "name": "Chitrakoot",
        "value": "Chitrakoot"
    },
    {
        "name": "Chittoor",
        "value": "Chittoor"
    },
    {
        "name": "Chittorgarh",
        "value": "Chittorgarh"
    },
    {
        "name": "Churachandpur",
        "value": "Churachandpur"
    },
    {
        "name": "Churu",
        "value": "Churu"
    },
    {
        "name": "Coimbatore",
        "value": "Coimbatore"
    },
    {
        "name": "Cooch Behar",
        "value": "Cooch Behar"
    },
    {
        "name": "Cuddalore",
        "value": "Cuddalore"
    },
    {
        "name": "Cuddapah",
        "value": "Cuddapah"
    },
    {
        "name": "Cuttack",
        "value": "Cuttack"
    },
    {
        "name": "Dadra & Nagar Haveli",
        "value": "Dadra & Nagar Haveli"
    },
    {
        "name": "Dahod",
        "value": "Dahod"
    },
    {
        "name": "Dakshina Kannada",
        "value": "Dakshina Kannada"
    },
    {
        "name": "Daman",
        "value": "Daman"
    },
    {
        "name": "Damoh",
        "value": "Damoh"
    },
    {
        "name": "Dantewada",
        "value": "Dantewada"
    },
    {
        "name": "Darbhanga",
        "value": "Darbhanga"
    },
    {
        "name": "Darjiling",
        "value": "Darjiling"
    },
    {
        "name": "Darrang",
        "value": "Darrang"
    },
    {
        "name": "Datia",
        "value": "Datia"
    },
    {
        "name": "Dausa",
        "value": "Dausa"
    },
    {
        "name": "Davangere",
        "value": "Davangere"
    },
    {
        "name": "Debagarh",
        "value": "Debagarh"
    },
    {
        "name": "Dehradun",
        "value": "Dehradun"
    },
    {
        "name": "Deoghar",
        "value": "Deoghar"
    },
    {
        "name": "Deoria",
        "value": "Deoria"
    },
    {
        "name": "Dewas",
        "value": "Dewas"
    },
    {
        "name": "Dhalai",
        "value": "Dhalai"
    },
    {
        "name": "Dhamtari",
        "value": "Dhamtari"
    },
    {
        "name": "Dhanbad",
        "value": "Dhanbad"
    },
    {
        "name": "Dhar",
        "value": "Dhar"
    },
    {
        "name": "Dharmapuri",
        "value": "Dharmapuri"
    },
    {
        "name": "Dharwad",
        "value": "Dharwad"
    },
    {
        "name": "Dhemaji",
        "value": "Dhemaji"
    },
    {
        "name": "Dhenkanal",
        "value": "Dhenkanal"
    },
    {
        "name": "Dholpur",
        "value": "Dholpur"
    },
    {
        "name": "Dhubri",
        "value": "Dhubri"
    },
    {
        "name": "Dhule",
        "value": "Dhule"
    },
    {
        "name": "Dibang Valley",
        "value": "Dibang Valley"
    },
    {
        "name": "Dibrugarh",
        "value": "Dibrugarh"
    },
    {
        "name": "Dimapur",
        "value": "Dimapur"
    },
    {
        "name": "Dindigul",
        "value": "Dindigul"
    },
    {
        "name": "Dindori",
        "value": "Dindori"
    },
    {
        "name": "Diu",
        "value": "Diu"
    },
    {
        "name": "Doda",
        "value": "Doda"
    },
    {
        "name": "Dumka",
        "value": "Dumka"
    },
    {
        "name": "Dungarpur",
        "value": "Dungarpur"
    },
    {
        "name": "Durg",
        "value": "Durg"
    },
    {
        "name": "East Champaran",
        "value": "East Champaran"
    },
    {
        "name": "East Garo Hills",
        "value": "East Garo Hills"
    },
    {
        "name": "East Godavari",
        "value": "East Godavari"
    },
    {
        "name": "East Kameng",
        "value": "East Kameng"
    },
    {
        "name": "East Khasi Hills",
        "value": "East Khasi Hills"
    },
    {
        "name": "East Midnapore",
        "value": "East Midnapore"
    },
    {
        "name": "East Nimar",
        "value": "East Nimar"
    },
    {
        "name": "East Siang",
        "value": "East Siang"
    },
    {
        "name": "East Sikkim",
        "value": "East Sikkim"
    },
    {
        "name": "East Singhbhum",
        "value": "East Singhbhum"
    },
    {
        "name": "Ernakulam",
        "value": "Ernakulam"
    },
    {
        "name": "Erode",
        "value": "Erode"
    },
    {
        "name": "Etah",
        "value": "Etah"
    },
    {
        "name": "Etawah",
        "value": "Etawah"
    },
    {
        "name": "Faizabad",
        "value": "Faizabad"
    },
    {
        "name": "Faridabad",
        "value": "Faridabad"
    },
    {
        "name": "Faridkot",
        "value": "Faridkot"
    },
    {
        "name": "Farrukhabad",
        "value": "Farrukhabad"
    },
    {
        "name": "Fatehabad",
        "value": "Fatehabad"
    },
    {
        "name": "Fatehgarh Sahib",
        "value": "Fatehgarh Sahib"
    },
    {
        "name": "Fatehpur",
        "value": "Fatehpur"
    },
    {
        "name": "Fazilka",
        "value": "Fazilka"
    },
    {
        "name": "Firozabad",
        "value": "Firozabad"
    },
    {
        "name": "Firozpur",
        "value": "Firozpur"
    },
    {
        "name": "Gadag",
        "value": "Gadag"
    },
    {
        "name": "Gadchiroli",
        "value": "Gadchiroli"
    },
    {
        "name": "Gajapati",
        "value": "Gajapati"
    },
    {
        "name": "Gandhi Nagar",
        "value": "Gandhi Nagar"
    },
    {
        "name": "Ganganagar",
        "value": "Ganganagar"
    },
    {
        "name": "Ganjam",
        "value": "Ganjam"
    },
    {
        "name": "Garhwa",
        "value": "Garhwa"
    },
    {
        "name": "Gariaband",
        "value": "Gariaband"
    },
    {
        "name": "Gautam Buddha Nagar",
        "value": "Gautam Buddha Nagar"
    },
    {
        "name": "Gaya",
        "value": "Gaya"
    },
    {
        "name": "Ghaziabad",
        "value": "Ghaziabad"
    },
    {
        "name": "Ghazipur",
        "value": "Ghazipur"
    },
    {
        "name": "Giridh",
        "value": "Giridh"
    },
    {
        "name": "Goalpara",
        "value": "Goalpara"
    },
    {
        "name": "Godda",
        "value": "Godda"
    },
    {
        "name": "Golaghat",
        "value": "Golaghat"
    },
    {
        "name": "Gonda",
        "value": "Gonda"
    },
    {
        "name": "Gondia",
        "value": "Gondia"
    },
    {
        "name": "Gopalganj",
        "value": "Gopalganj"
    },
    {
        "name": "Gorakhpur",
        "value": "Gorakhpur"
    },
    {
        "name": "Gulbarga",
        "value": "Gulbarga"
    },
    {
        "name": "Gumla",
        "value": "Gumla"
    },
    {
        "name": "Guna",
        "value": "Guna"
    },
    {
        "name": "Guntur",
        "value": "Guntur"
    },
    {
        "name": "Gurdaspur",
        "value": "Gurdaspur"
    },
    {
        "name": "Gurugram",
        "value": "Gurugram"
    },
    {
        "name": "Gwalior",
        "value": "Gwalior"
    },
    {
        "name": "Hailakandi",
        "value": "Hailakandi"
    },
    {
        "name": "Hamirpur",
        "value": "Hamirpur"
    },
    {
        "name": "Hamirpur(HP)",
        "value": "Hamirpur(HP)"
    },
    {
        "name": "Hanumangarh",
        "value": "Hanumangarh"
    },
    {
        "name": "Harda",
        "value": "Harda"
    },
    {
        "name": "Hardoi",
        "value": "Hardoi"
    },
    {
        "name": "Haridwar",
        "value": "Haridwar"
    },
    {
        "name": "Hassan",
        "value": "Hassan"
    },
    {
        "name": "Hathras",
        "value": "Hathras"
    },
    {
        "name": "Haveri",
        "value": "Haveri"
    },
    {
        "name": "Hazaribag",
        "value": "Hazaribag"
    },
    {
        "name": "Hingoli",
        "value": "Hingoli"
    },
    {
        "name": "Hisar",
        "value": "Hisar"
    },
    {
        "name": "Hooghly",
        "value": "Hooghly"
    },
    {
        "name": "Hoshangabad",
        "value": "Hoshangabad"
    },
    {
        "name": "Hoshiarpur",
        "value": "Hoshiarpur"
    },
    {
        "name": "Howrah",
        "value": "Howrah"
    },
    {
        "name": "Hyderabad",
        "value": "Hyderabad"
    },
    {
        "name": "Idukki",
        "value": "Idukki"
    },
    {
        "name": "Imphal East",
        "value": "Imphal East"
    },
    {
        "name": "Imphal West",
        "value": "Imphal West"
    },
    {
        "name": "Indore",
        "value": "Indore"
    },
    {
        "name": "Jabalpur",
        "value": "Jabalpur"
    },
    {
        "name": "Jagatsinghapur",
        "value": "Jagatsinghapur"
    },
    {
        "name": "Jaintia Hills",
        "value": "Jaintia Hills"
    },
    {
        "name": "Jaipur",
        "value": "Jaipur"
    },
    {
        "name": "Jaisalmer",
        "value": "Jaisalmer"
    },
    {
        "name": "Jajapur",
        "value": "Jajapur"
    },
    {
        "name": "Jalandhar",
        "value": "Jalandhar"
    },
    {
        "name": "Jalaun",
        "value": "Jalaun"
    },
    {
        "name": "Jalgaon",
        "value": "Jalgaon"
    },
    {
        "name": "Jalna",
        "value": "Jalna"
    },
    {
        "name": "Jalor",
        "value": "Jalor"
    },
    {
        "name": "Jalpaiguri",
        "value": "Jalpaiguri"
    },
    {
        "name": "Jammu",
        "value": "Jammu"
    },
    {
        "name": "Jamnagar",
        "value": "Jamnagar"
    },
    {
        "name": "Jamtara",
        "value": "Jamtara"
    },
    {
        "name": "Jamui",
        "value": "Jamui"
    },
    {
        "name": "Janjgir-champa",
        "value": "Janjgir-champa"
    },
    {
        "name": "Jashpur",
        "value": "Jashpur"
    },
    {
        "name": "Jaunpur",
        "value": "Jaunpur"
    },
    {
        "name": "Jehanabad",
        "value": "Jehanabad"
    },
    {
        "name": "Jhabua",
        "value": "Jhabua"
    },
    {
        "name": "Jhajjar",
        "value": "Jhajjar"
    },
    {
        "name": "Jhalawar",
        "value": "Jhalawar"
    },
    {
        "name": "Jhansi",
        "value": "Jhansi"
    },
    {
        "name": "Jharsuguda",
        "value": "Jharsuguda"
    },
    {
        "name": "Jhujhunu",
        "value": "Jhujhunu"
    },
    {
        "name": "Jind",
        "value": "Jind"
    },
    {
        "name": "Jodhpur",
        "value": "Jodhpur"
    },
    {
        "name": "Jorhat",
        "value": "Jorhat"
    },
    {
        "name": "Junagadh",
        "value": "Junagadh"
    },
    {
        "name": "Jyotiba Phule Nagar",
        "value": "Jyotiba Phule Nagar"
    },
    {
        "name": "K.V.Rangareddy",
        "value": "K.V.Rangareddy"
    },
    {
        "name": "Kachchh",
        "value": "Kachchh"
    },
    {
        "name": "Kaimur (Bhabua)",
        "value": "Kaimur (Bhabua)"
    },
    {
        "name": "Kaithal",
        "value": "Kaithal"
    },
    {
        "name": "Kalahandi",
        "value": "Kalahandi"
    },
    {
        "name": "Kamrup",
        "value": "Kamrup"
    },
    {
        "name": "Kanchipuram",
        "value": "Kanchipuram"
    },
    {
        "name": "Kandhamal",
        "value": "Kandhamal"
    },
    {
        "name": "Kangra",
        "value": "Kangra"
    },
    {
        "name": "Kanker",
        "value": "Kanker"
    },
    {
        "name": "Kannauj",
        "value": "Kannauj"
    },
    {
        "name": "Kannur",
        "value": "Kannur"
    },
    {
        "name": "Kanpur Dehat",
        "value": "Kanpur Dehat"
    },
    {
        "name": "Kanpur Nagar",
        "value": "Kanpur Nagar"
    },
    {
        "name": "Kanyakumari",
        "value": "Kanyakumari"
    },
    {
        "name": "Kapurthala",
        "value": "Kapurthala"
    },
    {
        "name": "Karaikal",
        "value": "Karaikal"
    },
    {
        "name": "Karauli",
        "value": "Karauli"
    },
    {
        "name": "Karbi Anglong",
        "value": "Karbi Anglong"
    },
    {
        "name": "Kargil",
        "value": "Kargil"
    },
    {
        "name": "Karim Nagar",
        "value": "Karim Nagar"
    },
    {
        "name": "Karimganj",
        "value": "Karimganj"
    },
    {
        "name": "Karnal",
        "value": "Karnal"
    },
    {
        "name": "Karur",
        "value": "Karur"
    },
    {
        "name": "Kasargod",
        "value": "Kasargod"
    },
    {
        "name": "Kathua",
        "value": "Kathua"
    },
    {
        "name": "Katihar",
        "value": "Katihar"
    },
    {
        "name": "Katni",
        "value": "Katni"
    },
    {
        "name": "Kaushambi",
        "value": "Kaushambi"
    },
    {
        "name": "Kawardha",
        "value": "Kawardha"
    },
    {
        "name": "Kendrapara",
        "value": "Kendrapara"
    },
    {
        "name": "Kendujhar",
        "value": "Kendujhar"
    },
    {
        "name": "Khagaria",
        "value": "Khagaria"
    },
    {
        "name": "Khammam",
        "value": "Khammam"
    },
    {
        "name": "Khandwa",
        "value": "Khandwa"
    },
    {
        "name": "Khargone",
        "value": "Khargone"
    },
    {
        "name": "Kheda",
        "value": "Kheda"
    },
    {
        "name": "Kheri",
        "value": "Kheri"
    },
    {
        "name": "Khorda",
        "value": "Khorda"
    },
    {
        "name": "Khunti",
        "value": "Khunti"
    },
    {
        "name": "Kinnaur",
        "value": "Kinnaur"
    },
    {
        "name": "Kiphire",
        "value": "Kiphire"
    },
    {
        "name": "Kishanganj",
        "value": "Kishanganj"
    },
    {
        "name": "Kodagu",
        "value": "Kodagu"
    },
    {
        "name": "Koderma",
        "value": "Koderma"
    },
    {
        "name": "Kohima",
        "value": "Kohima"
    },
    {
        "name": "Kokrajhar",
        "value": "Kokrajhar"
    },
    {
        "name": "Kolar",
        "value": "Kolar"
    },
    {
        "name": "Kolasib",
        "value": "Kolasib"
    },
    {
        "name": "Kolhapur",
        "value": "Kolhapur"
    },
    {
        "name": "Kolkata",
        "value": "Kolkata"
    },
    {
        "name": "Kollam",
        "value": "Kollam"
    },
    {
        "name": "Koppal",
        "value": "Koppal"
    },
    {
        "name": "Koraput",
        "value": "Koraput"
    },
    {
        "name": "Korba",
        "value": "Korba"
    },
    {
        "name": "Koriya",
        "value": "Koriya"
    },
    {
        "name": "Kota",
        "value": "Kota"
    },
    {
        "name": "Kottayam",
        "value": "Kottayam"
    },
    {
        "name": "Kozhikode",
        "value": "Kozhikode"
    },
    {
        "name": "Krishna",
        "value": "Krishna"
    },
    {
        "name": "Krishnagiri",
        "value": "Krishnagiri"
    },
    {
        "name": "Kulgam",
        "value": "Kulgam"
    },
    {
        "name": "Kullu",
        "value": "Kullu"
    },
    {
        "name": "Kupwara",
        "value": "Kupwara"
    },
    {
        "name": "Kurnool",
        "value": "Kurnool"
    },
    {
        "name": "Kurukshetra",
        "value": "Kurukshetra"
    },
    {
        "name": "Kurung Kumey",
        "value": "Kurung Kumey"
    },
    {
        "name": "Kushinagar",
        "value": "Kushinagar"
    },
    {
        "name": "Lahul & Spiti",
        "value": "Lahul & Spiti"
    },
    {
        "name": "Lakhimpur",
        "value": "Lakhimpur"
    },
    {
        "name": "Lakhisarai",
        "value": "Lakhisarai"
    },
    {
        "name": "Lakshadweep",
        "value": "Lakshadweep"
    },
    {
        "name": "Lalitpur",
        "value": "Lalitpur"
    },
    {
        "name": "Latehar",
        "value": "Latehar"
    },
    {
        "name": "Latur",
        "value": "Latur"
    },
    {
        "name": "Lawngtlai",
        "value": "Lawngtlai"
    },
    {
        "name": "Leh",
        "value": "Leh"
    },
    {
        "name": "Lohardaga",
        "value": "Lohardaga"
    },
    {
        "name": "Lohit",
        "value": "Lohit"
    },
    {
        "name": "Longleng",
        "value": "Longleng"
    },
    {
        "name": "Lower Dibang Valley",
        "value": "Lower Dibang Valley"
    },
    {
        "name": "Lower Subansiri",
        "value": "Lower Subansiri"
    },
    {
        "name": "Lucknow",
        "value": "Lucknow"
    },
    {
        "name": "Ludhiana",
        "value": "Ludhiana"
    },
    {
        "name": "Lunglei",
        "value": "Lunglei"
    },
    {
        "name": "Madhepura",
        "value": "Madhepura"
    },
    {
        "name": "Madhubani",
        "value": "Madhubani"
    },
    {
        "name": "Madurai",
        "value": "Madurai"
    },
    {
        "name": "Mahabub Nagar",
        "value": "Mahabub Nagar"
    },
    {
        "name": "Maharajganj",
        "value": "Maharajganj"
    },
    {
        "name": "Mahasamund",
        "value": "Mahasamund"
    },
    {
        "name": "Mahe",
        "value": "Mahe"
    },
    {
        "name": "Mahendragarh",
        "value": "Mahendragarh"
    },
    {
        "name": "Mahesana",
        "value": "Mahesana"
    },
    {
        "name": "Mahoba",
        "value": "Mahoba"
    },
    {
        "name": "Mainpuri",
        "value": "Mainpuri"
    },
    {
        "name": "Malappuram",
        "value": "Malappuram"
    },
    {
        "name": "Malda",
        "value": "Malda"
    },
    {
        "name": "Malkangiri",
        "value": "Malkangiri"
    },
    {
        "name": "Mammit",
        "value": "Mammit"
    },
    {
        "name": "Mandi",
        "value": "Mandi"
    },
    {
        "name": "Mandla",
        "value": "Mandla"
    },
    {
        "name": "Mandsaur",
        "value": "Mandsaur"
    },
    {
        "name": "Mandya",
        "value": "Mandya"
    },
    {
        "name": "Mansa",
        "value": "Mansa"
    },
    {
        "name": "Marigaon",
        "value": "Marigaon"
    },
    {
        "name": "Mathura",
        "value": "Mathura"
    },
    {
        "name": "Mau",
        "value": "Mau"
    },
    {
        "name": "Mayurbhanj",
        "value": "Mayurbhanj"
    },
    {
        "name": "Medak",
        "value": "Medak"
    },
    {
        "name": "Medinipur",
        "value": "Medinipur"
    },
    {
        "name": "Meerut",
        "value": "Meerut"
    },
    {
        "name": "Mirzapur",
        "value": "Mirzapur"
    },
    {
        "name": "Moga",
        "value": "Moga"
    },
    {
        "name": "Mohali",
        "value": "Mohali"
    },
    {
        "name": "Mokokchung",
        "value": "Mokokchung"
    },
    {
        "name": "Mon",
        "value": "Mon"
    },
    {
        "name": "Moradabad",
        "value": "Moradabad"
    },
    {
        "name": "Morena",
        "value": "Morena"
    },
    {
        "name": "Muktsar",
        "value": "Muktsar"
    },
    {
        "name": "Mumbai",
        "value": "Mumbai"
    },
    {
        "name": "Munger",
        "value": "Munger"
    },
    {
        "name": "Murshidabad",
        "value": "Murshidabad"
    },
    {
        "name": "Muzaffarnagar",
        "value": "Muzaffarnagar"
    },
    {
        "name": "Muzaffarpur",
        "value": "Muzaffarpur"
    },
    {
        "name": "Mysore",
        "value": "Mysore"
    },
    {
        "name": "Nabarangapur",
        "value": "Nabarangapur"
    },
    {
        "name": "Nadia",
        "value": "Nadia"
    },
    {
        "name": "Nagaon",
        "value": "Nagaon"
    },
    {
        "name": "Nagapattinam",
        "value": "Nagapattinam"
    },
    {
        "name": "Nagaur",
        "value": "Nagaur"
    },
    {
        "name": "Nagpur",
        "value": "Nagpur"
    },
    {
        "name": "Nainital",
        "value": "Nainital"
    },
    {
        "name": "Nalanda",
        "value": "Nalanda"
    },
    {
        "name": "Nalbari",
        "value": "Nalbari"
    },
    {
        "name": "Nalgonda",
        "value": "Nalgonda"
    },
    {
        "name": "Namakkal",
        "value": "Namakkal"
    },
    {
        "name": "Nanded",
        "value": "Nanded"
    },
    {
        "name": "Nandurbar",
        "value": "Nandurbar"
    },
    {
        "name": "Narayanpur",
        "value": "Narayanpur"
    },
    {
        "name": "Narmada",
        "value": "Narmada"
    },
    {
        "name": "Narsinghpur",
        "value": "Narsinghpur"
    },
    {
        "name": "Nashik",
        "value": "Nashik"
    },
    {
        "name": "Navsari",
        "value": "Navsari"
    },
    {
        "name": "Nawada",
        "value": "Nawada"
    },
    {
        "name": "Nawanshahr",
        "value": "Nawanshahr"
    },
    {
        "name": "Nayagarh",
        "value": "Nayagarh"
    },
    {
        "name": "Neemuch",
        "value": "Neemuch"
    },
    {
        "name": "Nellore",
        "value": "Nellore"
    },
    {
        "name": "New Delhi",
        "value": "New Delhi"
    },
    {
        "name": "Nicobar",
        "value": "Nicobar"
    },
    {
        "name": "Nilgiris",
        "value": "Nilgiris"
    },
    {
        "name": "Nizamabad",
        "value": "Nizamabad"
    },
    {
        "name": "North 24 Parganas",
        "value": "North 24 Parganas"
    },
    {
        "name": "North And Middle Andaman",
        "value": "North And Middle Andaman"
    },
    {
        "name": "North Cachar Hills",
        "value": "North Cachar Hills"
    },
    {
        "name": "North Dinajpur",
        "value": "North Dinajpur"
    },
    {
        "name": "North Goa",
        "value": "North Goa"
    },
    {
        "name": "North Sikkim",
        "value": "North Sikkim"
    },
    {
        "name": "North Tripura",
        "value": "North Tripura"
    },
    {
        "name": "Nuapada",
        "value": "Nuapada"
    },
    {
        "name": "Osmanabad",
        "value": "Osmanabad"
    },
    {
        "name": "Pakur",
        "value": "Pakur"
    },
    {
        "name": "Palakkad",
        "value": "Palakkad"
    },
    {
        "name": "Palamau",
        "value": "Palamau"
    },
    {
        "name": "Pali",
        "value": "Pali"
    },
    {
        "name": "Panch Mahals",
        "value": "Panch Mahals"
    },
    {
        "name": "Panchkula",
        "value": "Panchkula"
    },
    {
        "name": "Panipat",
        "value": "Panipat"
    },
    {
        "name": "Panna",
        "value": "Panna"
    },
    {
        "name": "Papum Pare",
        "value": "Papum Pare"
    },
    {
        "name": "Parbhani",
        "value": "Parbhani"
    },
    {
        "name": "Patan",
        "value": "Patan"
    },
    {
        "name": "Pathanamthitta",
        "value": "Pathanamthitta"
    },
    {
        "name": "Pathankot",
        "value": "Pathankot"
    },
    {
        "name": "Patiala",
        "value": "Patiala"
    },
    {
        "name": "Patna",
        "value": "Patna"
    },
    {
        "name": "Pauri Garhwal",
        "value": "Pauri Garhwal"
    },
    {
        "name": "Perambalur",
        "value": "Perambalur"
    },
    {
        "name": "Peren",
        "value": "Peren"
    },
    {
        "name": "Phek",
        "value": "Phek"
    },
    {
        "name": "Pilibhit",
        "value": "Pilibhit"
    },
    {
        "name": "Pithoragarh",
        "value": "Pithoragarh"
    },
    {
        "name": "Pondicherry",
        "value": "Pondicherry"
    },
    {
        "name": "Poonch",
        "value": "Poonch"
    },
    {
        "name": "Porbandar",
        "value": "Porbandar"
    },
    {
        "name": "Prakasam",
        "value": "Prakasam"
    },
    {
        "name": "Pratapgarh",
        "value": "Pratapgarh"
    },
    {
        "name": "Pudukkottai",
        "value": "Pudukkottai"
    },
    {
        "name": "Pulwama",
        "value": "Pulwama"
    },
    {
        "name": "Pune",
        "value": "Pune"
    },
    {
        "name": "Puri",
        "value": "Puri"
    },
    {
        "name": "Purnia",
        "value": "Purnia"
    },
    {
        "name": "Puruliya",
        "value": "Puruliya"
    },
    {
        "name": "Raebareli",
        "value": "Raebareli"
    },
    {
        "name": "Raichur",
        "value": "Raichur"
    },
    {
        "name": "Raigarh",
        "value": "Raigarh"
    },
    {
        "name": "Raigarh(MH)",
        "value": "Raigarh(MH)"
    },
    {
        "name": "Raipur",
        "value": "Raipur"
    },
    {
        "name": "Raisen",
        "value": "Raisen"
    },
    {
        "name": "Rajauri",
        "value": "Rajauri"
    },
    {
        "name": "Rajgarh",
        "value": "Rajgarh"
    },
    {
        "name": "Rajkot",
        "value": "Rajkot"
    },
    {
        "name": "Rajnandgaon",
        "value": "Rajnandgaon"
    },
    {
        "name": "Rajsamand",
        "value": "Rajsamand"
    },
    {
        "name": "Ramanagar",
        "value": "Ramanagar"
    },
    {
        "name": "Ramanathapuram",
        "value": "Ramanathapuram"
    },
    {
        "name": "Ramgarh",
        "value": "Ramgarh"
    },
    {
        "name": "Rampur",
        "value": "Rampur"
    },
    {
        "name": "Ranchi",
        "value": "Ranchi"
    },
    {
        "name": "Ratlam",
        "value": "Ratlam"
    },
    {
        "name": "Ratnagiri",
        "value": "Ratnagiri"
    },
    {
        "name": "Rayagada",
        "value": "Rayagada"
    },
    {
        "name": "Reasi",
        "value": "Reasi"
    },
    {
        "name": "Rewa",
        "value": "Rewa"
    },
    {
        "name": "Rewari",
        "value": "Rewari"
    },
    {
        "name": "Ri Bhoi",
        "value": "Ri Bhoi"
    },
    {
        "name": "Rohtak",
        "value": "Rohtak"
    },
    {
        "name": "Rohtas",
        "value": "Rohtas"
    },
    {
        "name": "Ropar",
        "value": "Ropar"
    },
    {
        "name": "Rudraprayag",
        "value": "Rudraprayag"
    },
    {
        "name": "Rupnagar",
        "value": "Rupnagar"
    },
    {
        "name": "Sabarkantha",
        "value": "Sabarkantha"
    },
    {
        "name": "Sagar",
        "value": "Sagar"
    },
    {
        "name": "Saharanpur",
        "value": "Saharanpur"
    },
    {
        "name": "Saharsa",
        "value": "Saharsa"
    },
    {
        "name": "Sahibganj",
        "value": "Sahibganj"
    },
    {
        "name": "Saiha",
        "value": "Saiha"
    },
    {
        "name": "Salem",
        "value": "Salem"
    },
    {
        "name": "Samastipur",
        "value": "Samastipur"
    },
    {
        "name": "Sambalpur",
        "value": "Sambalpur"
    },
    {
        "name": "Sangli",
        "value": "Sangli"
    },
    {
        "name": "Sangrur",
        "value": "Sangrur"
    },
    {
        "name": "Sant Kabir Nagar",
        "value": "Sant Kabir Nagar"
    },
    {
        "name": "Sant Ravidas Nagar",
        "value": "Sant Ravidas Nagar"
    },
    {
        "name": "Saran",
        "value": "Saran"
    },
    {
        "name": "Satara",
        "value": "Satara"
    },
    {
        "name": "Satna",
        "value": "Satna"
    },
    {
        "name": "Sawai Madhopur",
        "value": "Sawai Madhopur"
    },
    {
        "name": "Sehore",
        "value": "Sehore"
    },
    {
        "name": "Senapati",
        "value": "Senapati"
    },
    {
        "name": "Seoni",
        "value": "Seoni"
    },
    {
        "name": "Seraikela-kharsawan",
        "value": "Seraikela-kharsawan"
    },
    {
        "name": "Serchhip",
        "value": "Serchhip"
    },
    {
        "name": "Shahdol",
        "value": "Shahdol"
    },
    {
        "name": "Shahjahanpur",
        "value": "Shahjahanpur"
    },
    {
        "name": "Shajapur",
        "value": "Shajapur"
    },
    {
        "name": "Sheikhpura",
        "value": "Sheikhpura"
    },
    {
        "name": "Sheohar",
        "value": "Sheohar"
    },
    {
        "name": "Sheopur",
        "value": "Sheopur"
    },
    {
        "name": "Shimla",
        "value": "Shimla"
    },
    {
        "name": "Shimoga",
        "value": "Shimoga"
    },
    {
        "name": "Shivpuri",
        "value": "Shivpuri"
    },
    {
        "name": "Shopian",
        "value": "Shopian"
    },
    {
        "name": "Shrawasti",
        "value": "Shrawasti"
    },
    {
        "name": "Sibsagar",
        "value": "Sibsagar"
    },
    {
        "name": "Siddharthnagar",
        "value": "Siddharthnagar"
    },
    {
        "name": "Sidhi",
        "value": "Sidhi"
    },
    {
        "name": "Sikar",
        "value": "Sikar"
    },
    {
        "name": "Simdega",
        "value": "Simdega"
    },
    {
        "name": "Sindhudurg",
        "value": "Sindhudurg"
    },
    {
        "name": "Singrauli",
        "value": "Singrauli"
    },
    {
        "name": "Sirmaur",
        "value": "Sirmaur"
    },
    {
        "name": "Sirohi",
        "value": "Sirohi"
    },
    {
        "name": "Sirsa",
        "value": "Sirsa"
    },
    {
        "name": "Sitamarhi",
        "value": "Sitamarhi"
    },
    {
        "name": "Sitapur",
        "value": "Sitapur"
    },
    {
        "name": "Sivaganga",
        "value": "Sivaganga"
    },
    {
        "name": "Siwan",
        "value": "Siwan"
    },
    {
        "name": "Solan",
        "value": "Solan"
    },
    {
        "name": "Solapur",
        "value": "Solapur"
    },
    {
        "name": "Sonapur",
        "value": "Sonapur"
    },
    {
        "name": "Sonbhadra",
        "value": "Sonbhadra"
    },
    {
        "name": "Sonipat",
        "value": "Sonipat"
    },
    {
        "name": "Sonitpur",
        "value": "Sonitpur"
    },
    {
        "name": "South 24 Parganas",
        "value": "South 24 Parganas"
    },
    {
        "name": "South Andaman",
        "value": "South Andaman"
    },
    {
        "name": "South Dinajpur",
        "value": "South Dinajpur"
    },
    {
        "name": "South Garo Hills",
        "value": "South Garo Hills"
    },
    {
        "name": "South Goa",
        "value": "South Goa"
    },
    {
        "name": "South Sikkim",
        "value": "South Sikkim"
    },
    {
        "name": "South Tripura",
        "value": "South Tripura"
    },
    {
        "name": "Srikakulam",
        "value": "Srikakulam"
    },
    {
        "name": "Srinagar",
        "value": "Srinagar"
    },
    {
        "name": "Sultanpur",
        "value": "Sultanpur"
    },
    {
        "name": "Sundergarh",
        "value": "Sundergarh"
    },
    {
        "name": "Supaul",
        "value": "Supaul"
    },
    {
        "name": "Surat",
        "value": "Surat"
    },
    {
        "name": "Surendra Nagar",
        "value": "Surendra Nagar"
    },
    {
        "name": "Surguja",
        "value": "Surguja"
    },
    {
        "name": "Tamenglong",
        "value": "Tamenglong"
    },
    {
        "name": "Tapi",
        "value": "Tapi"
    },
    {
        "name": "Tarn Taran",
        "value": "Tarn Taran"
    },
    {
        "name": "Tawang",
        "value": "Tawang"
    },
    {
        "name": "Tehri Garhwal",
        "value": "Tehri Garhwal"
    },
    {
        "name": "Thane",
        "value": "Thane"
    },
    {
        "name": "Thanjavur",
        "value": "Thanjavur"
    },
    {
        "name": "The Dangs",
        "value": "The Dangs"
    },
    {
        "name": "Theni",
        "value": "Theni"
    },
    {
        "name": "Thiruvananthapuram",
        "value": "Thiruvananthapuram"
    },
    {
        "name": "Thoubal",
        "value": "Thoubal"
    },
    {
        "name": "Thrissur",
        "value": "Thrissur"
    },
    {
        "name": "Tikamgarh",
        "value": "Tikamgarh"
    },
    {
        "name": "Tinsukia",
        "value": "Tinsukia"
    },
    {
        "name": "Tirap",
        "value": "Tirap"
    },
    {
        "name": "Tiruchirappalli",
        "value": "Tiruchirappalli"
    },
    {
        "name": "Tirunelveli",
        "value": "Tirunelveli"
    },
    {
        "name": "Tiruvallur",
        "value": "Tiruvallur"
    },
    {
        "name": "Tiruvannamalai",
        "value": "Tiruvannamalai"
    },
    {
        "name": "Tiruvarur",
        "value": "Tiruvarur"
    },
    {
        "name": "Tonk",
        "value": "Tonk"
    },
    {
        "name": "Tuensang",
        "value": "Tuensang"
    },
    {
        "name": "Tumkur",
        "value": "Tumkur"
    },
    {
        "name": "Tuticorin",
        "value": "Tuticorin"
    },
    {
        "name": "Udaipur",
        "value": "Udaipur"
    },
    {
        "name": "Udham Singh Nagar",
        "value": "Udham Singh Nagar"
    },
    {
        "name": "Udhampur",
        "value": "Udhampur"
    },
    {
        "name": "Udupi",
        "value": "Udupi"
    },
    {
        "name": "Ujjain",
        "value": "Ujjain"
    },
    {
        "name": "Ukhrul",
        "value": "Ukhrul"
    },
    {
        "name": "Umaria",
        "value": "Umaria"
    },
    {
        "name": "Una",
        "value": "Una"
    },
    {
        "name": "Unnao",
        "value": "Unnao"
    },
    {
        "name": "Upper Siang",
        "value": "Upper Siang"
    },
    {
        "name": "Upper Subansiri",
        "value": "Upper Subansiri"
    },
    {
        "name": "Uttara Kannada",
        "value": "Uttara Kannada"
    },
    {
        "name": "Uttarkashi",
        "value": "Uttarkashi"
    },
    {
        "name": "Vadodara",
        "value": "Vadodara"
    },
    {
        "name": "Vaishali",
        "value": "Vaishali"
    },
    {
        "name": "Valsad",
        "value": "Valsad"
    },
    {
        "name": "Varanasi",
        "value": "Varanasi"
    },
    {
        "name": "Vellore",
        "value": "Vellore"
    },
    {
        "name": "Vidisha",
        "value": "Vidisha"
    },
    {
        "name": "Villupuram",
        "value": "Villupuram"
    },
    {
        "name": "Virudhunagar",
        "value": "Virudhunagar"
    },
    {
        "name": "Visakhapatnam",
        "value": "Visakhapatnam"
    },
    {
        "name": "Vizianagaram",
        "value": "Vizianagaram"
    },
    {
        "name": "Warangal",
        "value": "Warangal"
    },
    {
        "name": "Wardha",
        "value": "Wardha"
    },
    {
        "name": "Washim",
        "value": "Washim"
    },
    {
        "name": "Wayanad",
        "value": "Wayanad"
    },
    {
        "name": "West Champaran",
        "value": "West Champaran"
    },
    {
        "name": "West Garo Hills",
        "value": "West Garo Hills"
    },
    {
        "name": "West Godavari",
        "value": "West Godavari"
    },
    {
        "name": "West Kameng",
        "value": "West Kameng"
    },
    {
        "name": "West Khasi Hills",
        "value": "West Khasi Hills"
    },
    {
        "name": "West Midnapore",
        "value": "West Midnapore"
    },
    {
        "name": "West Nimar",
        "value": "West Nimar"
    },
    {
        "name": "West Siang",
        "value": "West Siang"
    },
    {
        "name": "West Sikkim",
        "value": "West Sikkim"
    },
    {
        "name": "West Singhbhum",
        "value": "West Singhbhum"
    },
    {
        "name": "West Tripura",
        "value": "West Tripura"
    },
    {
        "name": "Wokha",
        "value": "Wokha"
    },
    {
        "name": "Yadgir",
        "value": "Yadgir"
    },
    {
        "name": "Yamuna Nagar",
        "value": "Yamuna Nagar"
    },
    {
        "name": "Yavatmal",
        "value": "Yavatmal"
    },
    {
        "name": "Zunhebotto",
        "value": "Zunhebotto"
    }
];

export { cleanData, clusteredData };
