import React, { useState, useEffect, useRef } from 'react';
import { pdfjs } from 'react-pdf';
import { createPDF } from 'utils/createPDF';
import { CloudDone } from '@mui/icons-material';
import { Avatar, Box, CircularProgress, Skeleton, Typography } from '@mui/material';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// Load pdfMake fonts
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ResumeBuilder = ({ updating, setUpdating, setResumePDF, profile, educationData, workExperienceData, certificateData, customSection, summaryData, financeTechologiesData }) => {
  const [pdfDoc, setPdfDoc] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const canvas1Ref = useRef(null);
  const canvas2Ref = useRef(null);
  const [currentCanvas, setCurrentCanvas] = useState(1);
  const [thumbnails, setThumbnails] = useState([]);
  const [isRendering, setIsRendering] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  // Effect to load the PDF when pdfBuffer changes
  useEffect(() => {
    if (debounceTimeout) clearTimeout(debounceTimeout);
    const newTimeout = setTimeout(() => {
      generatePdfBuffer();
    }, 500);

    setDebounceTimeout(newTimeout);
  }, [profile, educationData, workExperienceData, certificateData, customSection, summaryData, financeTechologiesData]);

  // Function to generate PDF buffer using pdfmake
  const generatePdfBuffer = async () => {
    try {
      const buffer = await createPDF({
          profile, 
          educationData, workExperienceData, certificateData, customSection,
          summaryData,
          financeTechologiesData
      });
      const blob = new Blob([buffer], { type: 'application/pdf' });
      setResumePDF(blob);
      await loadPdfDocument(buffer);
    } catch (e) {
      setUpdating(false);
    }
  };

  // Function to load PDF document
  const loadPdfDocument = async (pdfArray) => {
    const loadingTask = pdfjs.getDocument({ data: pdfArray });
    const loadedPdfDoc = await loadingTask.promise;
    setPdfDoc(loadedPdfDoc);
    setTotalPages(loadedPdfDoc.numPages);
    generateThumbnails(loadedPdfDoc);
    setUpdating(false);
  };

  // Function to render a single page onto the active canvas
  const renderPage = async (pageNum) => {
    if (!pdfDoc) return;

    const canvasToUse = currentCanvas === 1 ? canvas1Ref.current : canvas2Ref.current;
    const ctx = canvasToUse.getContext('2d');

    // Clear the canvas before rendering
    ctx.clearRect(0, 0, canvasToUse.width, canvasToUse.height);

    const page = await pdfDoc.getPage(pageNum);
    const viewport = page.getViewport({ scale: 1.5 });

    canvasToUse.width = viewport.width;
    canvasToUse.height = viewport.height;

    const renderContext = {
      canvasContext: ctx,
      viewport,
    };

    await page.render(renderContext).promise;

    setCurrentCanvas(currentCanvas === 1 ? 2 : 1);
    // Toggle canvas visibility
    // toggleCanvasVisibility();
  };

  // Function to toggle the visibility of canvases
//   const toggleCanvasVisibility = () => {
//     if (currentCanvas === 1) {
//         canvas1Ref.current.className = 'pdf-fade-in';
//         canvas2Ref.current.className = 'pdf-fade-out';
//     } else {
//         canvas2Ref.current.className = 'pdf-fade-in';
//         canvas1Ref.current.className = 'pdf-fade-out';
//     }
//   };

  // Function to generate thumbnails for each page
  const generateThumbnails = async (pdfDoc) => {
    const thumbnails = [];
    for (let i = 1; i <= pdfDoc.numPages; i++) {
      const page = await pdfDoc.getPage(i);
      const scale = 0.2; // Thumbnail scale factor
      const viewport = page.getViewport({ scale });

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: ctx,
        viewport,
      };

      await page.render(renderContext).promise;
      thumbnails.push({ pageNum: i, dataUrl: canvas.toDataURL() });
    }
    setThumbnails(thumbnails);
  };

  // Effect to load the PDF when pdfBuffer changes
  // useEffect(() => {
  //   if (pdfBuffer) {
  //     loadPdfDocument(pdfBuffer);
  //   }
  // }, [pdfBuffer]);

  // Effect to render the page when the current page changes
  useEffect(() => {
    if (pdfDoc) {
      renderPage(currentPage);
    }
  }, [currentPage, pdfDoc]);

  const handleChange = (event) => {
    const userData = {
      name: document.getElementById('name').value,
      email: document.getElementById('email').value,
      experience: document.getElementById('experience').value,
    };

    if (debounceTimeout) clearTimeout(debounceTimeout);

    const newTimeout = setTimeout(() => {
      generatePdfBuffer(userData);
    }, 500);

    setDebounceTimeout(newTimeout);
  };

  // Handle Thumbnail Click
  const handleThumbnailClick = (pageNum) => {
    setCurrentPage(pageNum);
    // setCurrentCanvas(currentCanvas === 1 ? 2 : 1);
  };

  // Handle Next Page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    //   setCurrentCanvas(currentCanvas === 1 ? 2 : 1);
    }
  };

  // Handle Previous Page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentCanvas(currentCanvas === 1 ? 2 : 1);
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="editor-view">
      <div className="pdf-view-wrapper">
        <div style={{ display: 'inline-block', position: 'relative' }}>
            <div className='pdf-container'>
              { !pdfDoc ? 
                <Box sx={{ display: "contents", position: "relative" }} >
                  <div style={{ margin: '30px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ margin: 1 }}>
                          <Skeleton variant="circular">
                            <Avatar />
                          </Skeleton>
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Typography><Skeleton width="40%"></Skeleton></Typography>
                        <Typography variant="body"><Skeleton width="50%"></Skeleton></Typography>
                      </Box>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Typography variant="h5"><Skeleton width="100%"></Skeleton></Typography>
                      <Typography variant="body"><Skeleton width="100%"></Skeleton></Typography>


                      <Typography variant="h5"><Skeleton width="100%"></Skeleton></Typography>
                      <Typography variant="body"><Skeleton width="100%"></Skeleton></Typography>

                      <Typography variant="h5"><Skeleton width="100%"></Skeleton></Typography>
                      <Typography variant="body"><Skeleton width="100%"></Skeleton></Typography>

                      <Typography variant="h5"><Skeleton width="100%"></Skeleton></Typography>
                      <Typography variant="body"><Skeleton width="100%"></Skeleton></Typography>
                    </Box>
                  </div>
                  <canvas sx={{ visibilty: 'hidden'}}
                    style={{
                      objectFit: 'contain',
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                  }} ></canvas>
                </Box>
              : <Box sx={{ display: "contents", position: "relative" }}>
                {/* Canvas 1 */}
                <canvas
                    className={ currentCanvas === 2 ? 'pdf-fade-in' : 'pdf-fade-out' }
                    ref={canvas1Ref}
                    style={{
                        objectFit: 'contain',
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                    }}
                    ></canvas>

                {/* Canvas 2 */}
                <canvas
                    className={ currentCanvas === 1 ? 'pdf-fade-in' : 'pdf-fade-out' }
                    ref={canvas2Ref}
                    style={{
                        objectFit: 'contain',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0
                    }}
                    ></canvas>
              </Box> }
            </div>
            <div className="pdf-nav-block">
                { pdfDoc ? <div style={{ position: 'absolute', left: '0px' }}>
                    <div className="rendering-status">
                        { (updating ? 
                            <Box display="flex" flexDirection="row" ><CircularProgress size="sm" sx={{ color: 'white', width: 15, height: 15, marginRight: '5px' }} /> Saving...</Box>
                            : <Box display="flex" flexDirection="row"><CloudDone className='status-icon' /> Saved</Box>) }
                    </div>
                </div> : null }
                { totalPages ? <div className="page-nav">
                    <div className={ `prev-icon ${ currentPage === 1 ? 'disabled' : '' }` } onClick={handlePrevPage}>
                        <svg
                            fill="#fff"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.32427537,7.23715414 L10.6757246,5.76284586 L16.6757246,11.2628459 C17.1080918,11.6591824 17.1080918,12.3408176 16.6757246,12.7371541 L10.6757246,18.2371541 L9.32427537,16.7628459 L14.5201072,12 L9.32427537,7.23715414 Z"></path>
                        </svg>
                    </div>
                    <div className="page-count">{ currentPage } / {totalPages}</div>
                    <div className={ `next-icon ${ currentPage === totalPages ? 'disabled' : '' }` } onClick={handleNextPage}>
                        <svg
                            fill="#fff"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.32427537,7.23715414 L10.6757246,5.76284586 L16.6757246,11.2628459 C17.1080918,11.6591824 17.1080918,12.3408176 16.6757246,12.7371541 L10.6757246,18.2371541 L9.32427537,16.7628459 L14.5201072,12 L9.32427537,7.23715414 Z"></path>
                        </svg>
                    </div>
                </div> : null }
            </div>
        </div>
      </div>
        
    </div>
  );
};

export default ResumeBuilder;
