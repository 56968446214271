import axios from 'axios';
import { BASE_URL } from '../client-config';
import NProgress from "nprogress";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
  }
});

axiosInstance.interceptors.response.use(
  (res) => {
    NProgress.done();
    return res;  
  },
  (error) => {
    NProgress.done();
    if (error?.code === "ERR_NETWORK") return Promise.reject({ ...error, message: "Unable to login. Please try again." });
    return error;
  }
);
export default axiosInstance;

export const endpoints = {
  auth: {
    login: 'api/auth',
    logout: 'api/auth/logout',
    generateOTP: '/generateotp',
    forgotPassword: 'api/auth/forgotPassword',
    verifyOTP: 'api/auth/verifyOTP',
    changeNewPassword: 'api/auth/changeNewPassword',
    loginlink: 'api/auth/loginlink',
    passwordReset: '/api/auth/password-reset',
  },
  interpretation: {
    requestInterpretation: '/ecg/request-for-interpretation',
    revokeInterpretationRequest: '/ecg/revoke-interpretation-request',
    saveDataInterpretation: '/ecg/save-interpretation-data',
  },
  data: {
    getEcgData: '/ecg/get-ecg-data/',
  },

  user: {
    root: '/api/user',
    getUserProfile: '/api/user/getUserProfile',
    updateBasicProfile: '/api/user/updateBasicProfile',
    updatePersonalDetails: '/api/user/update-personal-details',
    addLinkedInProfile: '/api/user/add-linkedin-profile',
    updateCandidateEducation: '/api/user/updateCandidateEducation',
    updateCandidateCertificate: '/api/user/update-candidate-certificate',
    getAllEducationDetails: '/api/user/getAllEducationDetails',
    getAllCertificationDetails: '/api/user/get-all-certification-details',
    getEducationDetails: '/api/user/get-education-details',
    getCertificationDetails: '/api/user/get-certification-details',
    getWorkHistoryData: '/api/user/get-work-history-data',
    getBasicProfileDetails: '/api/user/get-basic-profile-details',
    updateCandidateWorkHistory: '/api/user/updateCandidateWorkHistory',
    addCandidateExpertiesAndSkills: '/api/user/add-candidate-experties-and-skills',
    getExpertiseAndSkillsDetails: '/api/user/get-expertise-skills-details',
    deleteExpertiseAndSkills: '/api/user/delete-expertise-and-skills',
    updateStarDetails: '/api/user/update-star-in-expertise-skills',
    getKeyFocusArea: '/api/user/getKeyFocusArea',
    uploadProfilePic: '/api/user/uploadProfilePic',
    addCandidateResume: '/api/user/add-candidate-resume',
    setDefaultResume: '/api/user/set-default-resume',
    deleteResume: '/api/user/delete-candidate-resume',
    deleteCertificateHistory: '/api/user/delete-certificate-history',
    addJobPreferance: '/api/user/add-job-preference',
    getJobPreferanceDetails: '/api/user/get-job-preference-details',
    deleteEducationHistory: '/api/user/delete-education-history',
    deleteWorkHistory: '/api/user/delete-work-history',
    passwordReset: '/api/user/password-reset',
    getFunctionalDomain: '/api/user/get-function-domain',
    getDomainExpertise: '/api/user/get-domain-experties',
    getExpertiseSkills: '/api/user/get-experties-skills',
    addCustomSection: '/api/user/add-custom-section',
    getCustomSection: '/api/user/get-custom-section',
    deleteCustomSection: '/api/user/delete-custom-section',
    updateCandidateWorkExperience: '/api/user/update-candidate-work-experience',
    updateCandidateWorkExperienceGap: '/api/user/update-candidate-work-experience-gap',
    getCandidateUpdatedAt: '/api/user/get-candidate-updated-at',

    addSummary: '/api/user/add-summary',
    getSummary: '/api/user/get-summary',
    updateFinanceTechnologies: '/api/user/update-finance-technologies',

    setTemplate: '/api/user/set-template',
    getTemplate: '/api/user/get-template',

    createDuplicateResume: '/api/user/duplicate-resume',

    setResumesData: '/api/user/set-resume-data',
    getResumesData: '/api/user/get-resume-data',

    updateContact: '/api/user/update-contact',
    setContact: '/api/user/set-contact',
    updateSkills: '/api/user/update-skills',
    addSingleJobPreference: '/api/user/add-single-job-preference',
    updateUser: '/api/user/update-user',

    getSpecificInvoiceData: '/ecg/get-specific-invoice-data',
    getB2Busers: '/user/get-b2b-users',
    saveB2BProfile: '/user/save-b2b-profile',
    getB2BAssociatedUser: '/user/get-associated-users',
    addB2BAssociatedUser: '/user/add-associated-users',
    removeB2BAssociatedUser: '/user/remove-associated-users',
    getInternalUsers: '/user/get-users',
    saveInternalUsers: '/user/save-users',
    removeInternalUsers: '/user/remove-users',
    updateUserProfile: '/user/update-profile',
    getUserOrder: 'user/get-order',
    getInvoicePdf: 'user/invoice',
    saveInvoiceDetails: 'user/save-invoice-details',
    fetchInvoiceData: 'user/fetch-invoice-data',
    toCheckInvoiceNumber: 'user/check-invoice-number'
  },

  profile: {
    getSpecificInvoiceData: '/ecg/get-specific-invoice-data',
    createUser: '/api/registration/create-user',
    removeUser: '/api/registration/delete-user',
    resendOTP: '/api/registration/resend-otp',
  },

  company: {
    getCompanyDetails: '/api/company/company-details',
    getCompanyStaffList: '/api/company/company-staff-list',
    getCompanyList: '/api/company/company-list',
    createCompany: '/api/company/create-company',
    addStaff: '/api/company/add-staff',
    updateCompany: '/api/company/update-company',
    getCompanyStaffDetails: 'api/company/staff-details',
    updateCompanyStaff: 'api/company/update-staff-details'
  },
  job: {
    createJob: '/api/job/job-form',
    jobUpdated: '/api/job/job-updated',
    getJobList: '/api/job/get-job-list',
    getAllJobList: '/api/job/all-job-list',
    deleteJob: '/api/job/delete-job',
    jobDetails: '/api/job/job-details',
    getCompanyDetails: '/api/job/get-company-details',
    setHideJob: '/api/job/set-hide-job',
    getJobsForExternalUse: '/api/job/get-jobs-for-external-use',
  },

  services: {
    skills: '/api/services/skill',
    city: '/api/services/city',
    degree: '/api/services/degree',
    degrees: '/api/services/degrees',
    education: '',
    company: '/api/services/company',
    city: '/api/services/city',
    role: '/api/services/role',
    registeredCompany: '/api/services/registered-company',
    function: '/api/services/function',
    certificate: '/api/services/certificate',
    institute: '/api/services/institute',
    roles: '/api/services/roles',
    certificateIssueBy: '/api/services/certificate-issue-by',
    allSkills: '/api/services/all-skills',
    singleCompanyData: '/api/services/single-company-data',
  },

  application: {
    getApplicationApplyDetails: '/api/application/review-details',
    setApplication: '/api/application/update-application',
    saveJobApplication: '/api/application/save-job-application',
    getApplicationList: '/api/application/get-application-list',
  },

  admin: {
    getApplicantsList: '/api/admin/get-applicants-list',
    getCandidateInfo: '/api/admin/get-candidate-info',
    setApplicationStatus: '/api/admin/set-application-status',
    setFeedbackDetails: '/api/admin/set-feedback-details',
    addAdminAndCompanyUsers: '/api/newadmin/add-admin-and-company',
    getAllAdminsAndCompanies: '/api/newadmin/get-all-admins-and-companies',

    getCandidateList: '/api/admin/candidate-list',
    getCandidateApplications: '/api/admin/candidate-applications',
    deleteUser: '/api/newadmin/delete-user',
    deleteFeedback: '/api/admin/delete-feedback',
  },

  blog : {
    getBlogList : '/api/blog/get-blogs',
  },

  feedback: {
    getFeedbackList: 'api/feedback/get-feedback-list',
    getApplicationFeedback: 'api/feedback/get-application-feedback',
  },


};
export const fetcher = async (args) => {
  // const [url, config] = Array.isArray(args) ? args : [args];
  // const pathParams = config.pathparams ? `${config.pathparams}` : '';
  // const queryParams = config.params ? `?${new URLSearchParams(config.params).toString()}` : '';
  // const URL = `${url.url}${pathParams}${queryParams}`;
  // const res = await axiosInstance.get(URL, { headers: generateAuth(config) });

  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.get(url, { ...config });
  return res.data;
};

export const poster = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.post(url, { ...config });
  return res.data;
};

export const formPoster = async (args) => {
  const [url, formData, headers] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.post(url, formData, {
    ...(headers ? { headers } : {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  });
  return res.data;
};

export const patcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.patch(url, { ...config });
  return res.data;
};