import { useMemo } from 'react';
// utils
import { poster, endpoints, fetcher } from '../utils/axios';

export async function userLogin(payload) {
  const URL = endpoints.auth.login;
  return await poster([URL, payload])
}
export async function userLogout(payload) {
  const URL = endpoints.auth.logout;
  return await poster([URL, payload])
}
export async function passwordReset(payload) {
  const URL = endpoints.auth.passwordReset;
  return await poster([URL, payload])
};
export async function forgotPassword(payload) {
  const URL = endpoints.auth.forgotPassword;
  return await poster([URL, payload])
}
export async function forgotPasswordWithLink(payload) {
  const URL = endpoints.auth.forgotPasswordWithLink;
  return await poster([URL, payload])
}

export async function verifyOTP(payload) {
  const URL = endpoints.auth.verifyOTP;
  return await poster([URL, payload])
}

export async function loginlink(payload) {
  const URL = endpoints.auth.loginlink;
  return await fetcher([URL, { params: payload }])
}


export async function changeNewPassword(payload) {
  const URL = endpoints.auth.changeNewPassword;
  return await poster([URL, payload])
}

export async function userVerify(payload) {
  const URL = endpoints.auth.verifyOTP;
  return await poster([URL, payload])
}

